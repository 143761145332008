import React from 'react'

type Props = {
  color?: string
  fillColor?: string
  size?: string
}

export const CheckmarkCircleIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.fillColor || 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="12" cy="11.999" r="11.25" />
    <path d="M6,13.223,8.45,16.7a1.049,1.049,0,0,0,1.707.051L18,6.828" />
  </svg>
)
