import React from 'react'
import { intl } from '../../../lib'

type Props = {
  amount?: number
  currency?: string | null
}

export const Currency = ({ amount, currency }: Props) => (
  <span>
    {intl.formatNumber(amount || 0, { style: 'currency', currencyDisplay: 'symbol', currency: currency || 'AUD' })}
  </span>
)
