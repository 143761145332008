import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ArrowDownIcon = (props: Props) => (
  <svg
    height={props.size || '0.87rem'}
    viewBox="0 0 12.4 7.2"
    width={props.size || '0.87rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'black'
    }}
  >
    <path
      d="M6.9,6.9l5.2-5.2c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L6.2,4.8L1.7,0.3c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0
			c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l5.2,5.2C5.9,7.3,6.5,7.3,6.9,6.9C6.9,6.9,6.9,6.9,6.9,6.9z"
    />
  </svg>
)
