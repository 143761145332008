import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Card, Loading } from '../shared'
import { useAchievementsQuery } from '../../hooks'
import { theme, VIEWER_COUNTRY_STATE, countryStateMap } from '../../lib'
import { Achievement } from './common'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  max-width: var(--max-page-width);
  margin: 0 auto;
`

const StyledCard = styled(Card)`
  margin-bottom: var(--spacing-sm);
  padding: var(--spacing-xl) var(--spacing-lg);
`

export const AllAchievements = () => {
  const { data } = useAchievementsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const maybeRemoveDonationAchievements = (achievement: AchievementType) => {
    if (countryStateMap[VIEWER_COUNTRY_STATE].features.HAS_CHARITY) return true
    else return !achievement.achievementName.includes('_DONATION_')
  }

  const existingInTheme = (achievement: AchievementType) => {
    return Object.keys(theme.achievements).some(name => name.includes(achievement.achievementName))
  }

  return (
    <Container className="fadeInRight">
      {data.allAchievements
        .filter(existingInTheme)
        .filter(maybeRemoveDonationAchievements)
        .map(achievement => {
          return (
            <Link to={`../${achievement.achievementName}`} key={achievement.achievementName + achievement.currentLevel}>
              <StyledCard>
                <Achievement
                  achievement={{
                    ...achievement,
                    // small hack since currentLevel is index based and should really stop at 3 for the last level
                    currentLevel: achievement.currentLevel === 4 ? 3 : achievement.currentLevel
                  }}
                />
              </StyledCard>
            </Link>
          )
        })}
    </Container>
  )
}
