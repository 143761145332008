import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CloseIcon } from './icons'
import { IconButton } from './shared'

const colorMap = {
  success: {
    color: 'black',
    backgroundColor: 'var(--colors-green)'
  },
  warning: {
    color: 'black',
    backgroundColor: 'var(--colors-yellow)'
  },
  danger: {
    color: 'white',
    backgroundColor: 'var(--colors-red)'
  },
  default: {
    color: 'black',
    backgroundColor: 'white'
  }
}

const Container = styled.div<{ color: string; backgroundColor: string }>`
  position: fixed;
  top: var(--spacing-sm);
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-page-width);
  word-break: break-word;
  width: calc(100% - var(--spacing-sm) * 2);
  padding: 0 var(--spacing-lg);
  min-height: 3.5rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-weight: bold;
  box-shadow: var(--shadow-lg);
  border-radius: var(--radius-lg);
  z-index: 99;
  transform: translateX(-50%);

  > svg:first-child {
    stroke: ${props => props.color} !important;
    margin-right: var(--spacing-sm);
  }

  > span {
    margin: 0 var(--spacing-sm);
  }
`

let timeoutRef

export const AppMessages = () => {
  const [message, setMessage] = useState<AppMessageType>()
  const { color, backgroundColor } = colorMap[message?.type || 'default']

  useEffect(() => {
    window.addEventListener('pushAppMessage', (event: any) => setMessage(event.detail))
    window.addEventListener('removeAppMessage', () => setMessage(undefined))
  }, [])

  if (message && message.ttl) {
    window.clearTimeout(timeoutRef)
    window.setTimeout(() => {
      setMessage(undefined)
    }, message.ttl)
  }

  return message
    ? ReactDOM.createPortal(
        <Container color={color} backgroundColor={backgroundColor} className="fadeIn">
          {message.icon ? message.icon : ''}

          <small>{message.formattedMessage}</small>

          <IconButton onClick={() => setMessage(undefined)}>
            <CloseIcon size="1rem" color={color} />
          </IconButton>
        </Container>,
        document.getElementById('root')!
      )
    : null
}

export const pushMessage = (message: AppMessageType) => {
  window.dispatchEvent(new CustomEvent('pushAppMessage', { detail: message }))
}

export const removeMessage = () => {
  // @ts-ignore
  window.dispatchEvent(new CustomEvent('removeAppMessage'))
}
