import React, { useState } from 'react'
import styled from 'styled-components'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../hooks'
import { Button, Drawer, LinkButton, Loading } from '../shared'
import { intl } from '../../lib'
import { payoutLogoAndTranslationMap } from './payoutLogoAndTranslationMap'
import {
  Common_Yes,
  Common_EditPayout,
  PayoutSettings_QuickSelect_Confirm_Donation_Description,
  PayoutSettings_QuickSelect_Confirm_Donation_Title,
  PayoutSettings_QuickSelect_Title,
  PayoutSettings_Selected,
  Common_No
} from '../../translations/messages'

const ButtonContainer = styled.div`
  display: flex;
  margin-top: var(--spacing-lg);

  button:first-child {
    margin-right: var(--spacing-sm);
  }

  button:last-child {
    margin-left: var(--spacing-sm);
  }
`

const StyledButton = styled(Button)`
  margin-top: var(--spacing-md);
  font-weight: normal;
`

const Radio = styled.div<{ selected: boolean }>`
  position: relative;
  border: 2px solid black;
  border-radius: var(--radius-lg);
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  :after {
    display: ${props => (props.selected ? 'initial' : 'none')};
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: var(--radius-lg);
    background-color: black;
  }
`

export const PayoutQuickSelect = () => {
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const updateQuery = usePayoutMethodMutation()
  const [showConfirmDonationPayout, setConfirmDonationPayout] = useState<boolean>(false)

  const applicableMethods = payoutMethods?.filter(
    ({ type, savedConfiguration }) => savedConfiguration !== null || type === 'E_VOUCHER'
  )

  return (
    <>
      <Drawer visible={showConfirmDonationPayout}>
        <h1 style={{ marginBottom: 'var(--spacing-md)' }}>
          {intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Title)}
        </h1>
        <p>{intl.formatMessage(PayoutSettings_QuickSelect_Confirm_Donation_Description)}</p>

        <ButtonContainer>
          <Button onClick={() => setConfirmDonationPayout(false)}>{intl.formatMessage(Common_No)}</Button>

          <Button
            background="colored"
            onClick={() => {
              setConfirmDonationPayout(false)
              updateQuery.mutate({ type: 'DONATION', method: 'PUT' })
            }}
          >
            {intl.formatMessage(Common_Yes)}
          </Button>
        </ButtonContainer>
      </Drawer>

      <div>
        <b style={{ marginLeft: 'var(--spacing-md)' }}>{intl.formatMessage(PayoutSettings_QuickSelect_Title)}</b>

        {applicableMethods?.map(({ type, active, savedConfiguration, logoUrl }: PayoutMethodType) => {
          const { logo, name } = payoutLogoAndTranslationMap[type]
          const isLoading = updateQuery.isPending && updateQuery.variables.type === type

          return (
            <StyledButton
              type="button"
              onClick={() =>
                type === 'DONATION' ? setConfirmDonationPayout(true) : updateQuery.mutate({ type, method: 'PUT' })
              }
              aria-disabled={active}
              disabled={active}
              key={type}
            >
              {logoUrl ? <img src={logoUrl} alt="charity logo" height="28px" /> : logo}

              <span>
                {name}
                {savedConfiguration && (
                  <p>
                    <small>
                      {savedConfiguration?.email ||
                        savedConfiguration?.maskedAccountNumber ||
                        savedConfiguration?.campaignName}
                    </small>
                  </p>
                )}
              </span>

              {isLoading ? <Loading /> : <Radio selected={active} />}

              {active && <span className="visually-hidden">{intl.formatMessage(PayoutSettings_Selected)}</span>}
            </StyledButton>
          )
        })}

        <LinkButton to="../payoutsettings" border style={{ marginTop: '4rem' }}>
          {intl.formatMessage(Common_EditPayout)}
        </LinkButton>
      </div>
    </>
  )
}
