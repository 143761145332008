import React from 'react'
import { ActivitiesPage } from './ActivitiesPage'
import { BackButton, Loading, Button } from '../shared'
import { useActivitiesQuery } from '../../hooks'
import { Common_More } from '../../translations/messages'
import { intl } from '../../lib'

export const ActivitiesPageContainer = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useActivitiesQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const activities = data.pages.map(page => page.activities).flat()

  return (
    <>
      <BackButton to="/" />
      <ActivitiesPage activities={activities} />

      {hasNextPage && (
        <Button background="colored" onClick={fetchNextPage}>
          {isFetchingNextPage ? <Loading /> : intl.formatMessage(Common_More)}
        </Button>
      )}
    </>
  )
}
