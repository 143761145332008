import React from 'react'
import { CharityDonationCard } from './common'
import styled from 'styled-components'
import {
  MyDonationsPage_NotDonatedYet,
  MyDonationsPage_PreviousDonations,
  MyDonationsPage_YouHaveDonated,
  Common_More
} from '../../translations/messages'
import { theme, intl } from '../../lib'
import { Currency, FeedbackMessage, Loading, Button } from '../shared'
import { BrowseCampaignsLoader } from './common/BrowseCampaignsLoader'
import { useDonationsSumQuery, useDonationsQuery } from '../../hooks'

const Container = styled.div`
  padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  display: flex;
  flex-direction: column;
  max-width: var(--max-page-width);
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
`

const List = styled.ul`
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`

const ListHeader = styled.small`
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  font-weight: bold;
`

const DonationsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-md);

  img {
    margin-right: var(--spacing-md);
    width: 9rem;
    height: 9rem;
  }
`

const DonationSum = styled.div`
  text-align: center;
`

const SmallBoldText = styled.small`
  font-weight: bold;
`

export const MyDonationsPage = () => {
  const { data: totalDonationSum } = useDonationsSumQuery()
  const { data: donations, fetchNextPage, hasNextPage, isFetchingNextPage } = useDonationsQuery()

  if (!donations) {
    return (
      <Container>
        <BrowseCampaignsLoader count={3} />
      </Container>
    )
  }

  const compoundedDonations = donations.pages.map(page => page.donations).flat()

  return (
    <Container>
      <>
        <DonationsContainer>
          <img src={theme.images.donationHeart} alt="A heart with hands holding a dollar bill" />

          <DonationSum>
            <SmallBoldText>{intl.formatMessage(MyDonationsPage_YouHaveDonated)}</SmallBoldText>
            <h1>
              <Currency amount={totalDonationSum || 0} currency={'AUD'} />
            </h1>
          </DonationSum>
        </DonationsContainer>
      </>

      {compoundedDonations.length > 0 ? (
        <>
          <ListHeader>{intl.formatMessage(MyDonationsPage_PreviousDonations)}</ListHeader>

          <List>
            <>
              {compoundedDonations.map((donation, i) => (
                <ListItem key={`don-${i}`}>
                  <CharityDonationCard donation={donation} />
                </ListItem>
              ))}

              {hasNextPage && (
                <Button style={{ margin: 'var(--spacing-lg) 0' }} onClick={fetchNextPage} border>
                  {isFetchingNextPage ? <Loading /> : intl.formatMessage(Common_More)}
                </Button>
              )}
            </>
          </List>
        </>
      ) : (
        <FeedbackMessage className="centerAbsolute fadeIn">
          {intl.formatMessage(MyDonationsPage_NotDonatedYet)}
        </FeedbackMessage>
      )}
    </Container>
  )
}
