import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const RecycleBottleIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24.8 24.5"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'black'
    }}
  >
    <g transform="translate(0.75 0.75)">
      <g transform="translate(-239.751 -638.764)">
        <path
          d="M249.1,642.8c-0.4,0-0.8-0.3-0.8-0.8v-1.6c0-0.4-0.3-0.8-0.8-0.8h-1.5c-0.4,0-0.8,0.3-0.8,0.8v1.6
					c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8v-1.6c0-1.2,1-2.3,2.3-2.3h1.5c1.2,0,2.3,1,2.3,2.3v1.6
					C249.8,642.4,249.5,642.8,249.1,642.8z"
        />
        <path
          d="M248.3,662.1h-6.7c-1.2,0-2.3-1-2.3-2.3v-2.7c0-0.4,0.2-0.8,0.4-1.1l0.7-0.7c0,0,0,0,0,0l-0.8-0.7
					c-0.3-0.3-0.4-0.7-0.4-1.1v-2.4c0-0.4,0.2-0.8,0.4-1.1l0.7-0.7c0,0,0,0,0,0l-0.8-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-3.6,2.9-6.4,6.4-6.4h2.1c3,0,5.7,2.1,6.3,5.1c0.1,0.4-0.2,0.8-0.6,0.9c-0.4,0.1-0.8-0.2-0.9-0.6c-0.5-2.3-2.5-3.9-4.8-3.9
					h-2.1c-2.7,0-4.9,2.2-4.9,4.9l0.7,0.8c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1c0,0,0,0-0.1,0.1l-0.7,0.7l0,2.4l0.7,0.8
					c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.4,1c0,0,0,0-0.1,0.1l-0.7,0.7l0,2.7c0,0.4,0.3,0.8,0.8,0.8h6.7c0.4,0,0.8,0.3,0.8,0.8
					S248.7,662.1,248.3,662.1z"
        />
        <path
          d="M249.8,650.1h-8.6c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h8.6c0.4,0,0.8,0.3,0.8,0.8
					S250.2,650.1,249.8,650.1z"
        />
        <path
          d="M247.6,656.1h-6.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h6.3c0.4,0,0.8,0.3,0.8,0.8
					S248,656.1,247.6,656.1z"
        />
        <path
          d="M250.6,661.4c-0.4,0-0.8-0.3-0.8-0.8v-3.8c0-0.4,0.3-0.8,0.8-0.8h3.8c0.4,0,0.8,0.3,0.8,0.8
					s-0.3,0.8-0.8,0.8h-3v3C251.3,661.1,251,661.4,250.6,661.4z"
        />
        <path
          d="M256.7,662.1c-0.7,0-1.4-0.1-2-0.3c-2.1-0.7-3.7-2.6-4.2-4.8c-0.1-0.4,0.2-0.8,0.6-0.9
					c0.4-0.1,0.8,0.2,0.9,0.6c0.3,1.7,1.5,3.1,3.2,3.6c2.5,0.9,5.3-0.5,6.1-3c0.1-0.4,0.6-0.6,1-0.5c0.4,0.1,0.6,0.6,0.5,1
					C261.8,660.5,259.3,662.1,256.7,662.1z"
        />
        <path
          d="M262.6,654.6h-3.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h3v-3c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v3.8
					C263.3,654.3,263,654.6,262.6,654.6z"
        />
        <path
          d="M261.9,654.6c-0.4,0-0.7-0.2-0.7-0.6c-0.3-1.7-1.5-3.1-3.2-3.6c-2.5-0.9-5.3,0.5-6.1,3
					c-0.1,0.4-0.6,0.6-1,0.5c-0.4-0.1-0.6-0.6-0.5-1c1.1-3.3,4.7-5.1,8-3.9c2.1,0.7,3.7,2.6,4.2,4.8c0.1,0.4-0.2,0.8-0.6,0.9
					C262,654.6,261.9,654.6,261.9,654.6z"
        />
      </g>
    </g>
  </svg>
)
