import React from 'react'
import styled from 'styled-components'
import { InfoIcon } from '../../icons'

const Container = styled.div<{ content: string }>`
  position: relative;

  :hover:after {
    background-color: black;
    border-radius: var(--radius-sm);
    padding: var(--spacing-md);
    box-shadow: var(--shadow-lg);
    content: '${props => props.content}';
    color: white;
    font-size: var(--font-size-sm);
    position: absolute;
    bottom: 25px;
    right: 25px;
    min-width: 200px;
    z-index: 100;
  }
`

type Props = {
  content: string
}

export const InfoTooltip = ({ content }: Props) => (
  <Container content={content} onClick={evt => evt.stopPropagation()}>
    <InfoIcon />
  </Container>
)
