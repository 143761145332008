import React, { useState } from 'react'
import { EmailIcon, ErrorIcon, LogoutIcon } from '../icons'
import { AnchorButton, IconButton, Loading } from '../shared'
import { FIREBASE, removeItemFromWebStorage, intl } from '../../lib'
import styled from 'styled-components'
import { ScheduledDeletePage_ContactInfo, ScheduledDeletePage_Warning } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: rgb(35, 31, 32);
  text-align: center;
  padding: var(--spacing-xl);
  background-color: rgb(255, 205, 0);
  height: ${window.innerHeight}px;

  p:not(last-of-type) {
    margin-bottom: var(--spacing-md);
  }
`

const Header = styled.div`
  position: absolute;
  top: var(--spacing-lg);
  right: var(--spacing-lg);
`

const Body = styled.div`
  > div:not(last-of-type) {
    margin-bottom: var(--spacing-xl);
  }
`

const Warning = styled.div`
  font-weight: bold;
`

const ButtonInnerContainer = styled.span`
  > svg {
    margin-right: var(--spacing-xs);
  }
`

export const ScheduledDeletePage = ({ scheduledDeleteAt }: { scheduledDeleteAt: string }) => {
  const [loadingLogout, setLoadingLogout] = useState(false)
  const milliSecondsUntilDelete = Date.parse(scheduledDeleteAt) - Date.now()
  const daysUntilDelete = Math.floor(milliSecondsUntilDelete / (1000 * 60 * 60 * 24))

  return (
    <Container>
      <Header>
        <IconButton
          onClick={() => {
            setLoadingLogout(true)
            removeItemFromWebStorage('lastAuthTimestamp')
            FIREBASE.auth.signOut()
          }}
        >
          {loadingLogout ? <Loading /> : <LogoutIcon size="2rem" />}
        </IconButton>
      </Header>
      <Body>
        <Warning>
          <ErrorIcon size="4rem" />
          <p>{intl.formatMessage(ScheduledDeletePage_Warning, { daysUntilDelete })}</p>
        </Warning>
        <p>{intl.formatMessage(ScheduledDeletePage_ContactInfo)}</p>
        <AnchorButton href="mailto:support.tomraproducts@tomra.com">
          <ButtonInnerContainer>
            <EmailIcon />
            <span>support.tomraproducts@tomra.com</span>
          </ButtonInnerContainer>
        </AnchorButton>
      </Body>
    </Container>
  )
}
