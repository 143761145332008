import { doc, setDoc, onSnapshot } from 'firebase/firestore'
import type { Unsubscribe } from 'firebase/firestore'
import { FIREBASE } from '../lib'

export const insertIntoFirestore = async (value: { [key: string]: any }) => {
  if (!FIREBASE.auth.currentUser?.uid) return

  const userRef = doc(FIREBASE.db, 'users', FIREBASE.auth.currentUser?.uid)
  // eslint-disable-next-line no-useless-catch
  try {
    // TODO: remove when migrated away from KC auth
    if (!FIREBASE.auth.currentUser) await new Promise(resolve => setTimeout(resolve, 5000))
    await setDoc(userRef, value, { merge: true })
  } catch (error: any) {
    throw error
  }
}

export const subscribeToFirestoreUpdates = (
  onData: (data: any) => void,
  onMissingOrError?: (error?: any) => void
): Unsubscribe => {
  let unsubscribe: Unsubscribe = () => {}

  if (FIREBASE.auth.currentUser?.uid) {
    const userRef = doc(FIREBASE.db, 'users', FIREBASE.auth.currentUser?.uid)

    try {
      unsubscribe = onSnapshot(
        userRef,
        userDocSnapshot => (userDocSnapshot.exists() ? onData(userDocSnapshot.data()) : onMissingOrError?.()),
        error => onMissingOrError?.(error)
      )
    } catch (error) {
      onMissingOrError?.(error)
    }
  }

  return unsubscribe
}
