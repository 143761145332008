import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const CalendarIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <rect x="0.75" y="3.75" width="22.5" height="19.5" rx="1.5" ry="1.5" />
    <line x1="0.75" y1="9.75" x2="23.25" y2="9.75" />
    <line x1="6.75" y1="6" x2="6.75" y2="0.75" />
    <line x1="17.25" y1="6" x2="17.25" y2="0.75" />
    <line x1="5.625" y1="13.5" x2="5.625" y2="13.5" />
    <path d="M5.625,13.5A.375.375,0,1,0,6,13.875a.375.375,0,0,0-.375-.375" />
    <line x1="5.625" y1="18.75" x2="5.625" y2="18.75" />
    <path d="M5.625,18.75A.375.375,0,1,0,6,19.125a.375.375,0,0,0-.375-.375" />
    <line x1="12" y1="13.5" x2="12" y2="13.5" />
    <path d="M12,13.5a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,13.5" />
    <line x1="12" y1="18.75" x2="12" y2="18.75" />
    <path d="M12,18.75a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,18.75" />
    <line x1="18.375" y1="13.5" x2="18.375" y2="13.5" />
    <path d="M18.375,13.5a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375" />
    <line x1="18.375" y1="18.75" x2="18.375" y2="18.75" />
    <path d="M18.375,18.75a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375" />
  </svg>
)
