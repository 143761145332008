import React, { useEffect } from 'react'
import { getQueryParameterByName } from '../../lib'
import { redirect } from './redirect'
import { Loading } from '../shared'

export const PayPalBrokerPage = () => {
  useEffect(() => {
    const setupPayPal = () => {
      const code = getQueryParameterByName('code') || ''
      const error_description = getQueryParameterByName('error_description') || ''
      code ? redirect({ type: 'PAYPAL', recipient: code, errorMessage: error_description }) : redirect()
    }

    setupPayPal()
  }, [])

  return (
    <div className="centerAbsolute">
      <Loading />
    </div>
  )
}
