import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const VoucherIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M3.62,6.75h6.87" />
    <path d="M3.62,11.25h4.3" />
    <path d="M3.62,15.75h4.3" />
    <path d="M15.65,6.75h1.72" />
    <path d="M12.66,16.56a2.68,2.68,0,0,0,2.13.88c1.3,0,2.36-.69,2.36-1.55s-1.06-1.54-2.36-1.54-2.36-.7-2.36-1.55,1.05-1.55,2.36-1.55a2.69,2.69,0,0,1,2.13.88" />
    <path d="M14.79,17.44v1" />
    <path d="M14.79,10.22v1" />
    <path d="M.75,3.43A2.59,2.59,0,0,1,3.25.75h15a2.59,2.59,0,0,1,2.5,2.68V19.5a2.59,2.59,0,0,1-2.5,2.68h-15A2.59,2.59,0,0,1,.75,19.5Z" />
  </svg>
)
