import React, { CSSProperties, FunctionComponent } from 'react'
import ContentLoader from 'react-content-loader'
import { Card } from '../../shared/common'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  margin-bottom: var(--spacing-sm);
`

const CampaignContentLoader = () => {
  return (
    <StyledCard>
      <ContentLoader
        speed={2}
        width={330}
        height={110}
        viewBox="0 0 330 110"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="55" cy="55" r="40" />
        <rect x="235" y="12" rx="2" ry="2" width="80" height="8" />
        <rect x="115" y="34" rx="2" ry="2" width="180" height="18" />
        <rect x="115" y="60" rx="2" ry="2" width="110" height="10" />
        <rect x="115" y="78" rx="2" ry="2" width="75" height="10" />
      </ContentLoader>
    </StyledCard>
  )
}

type Props = {
  style?: Partial<CSSProperties>
  count?: number
}

export const BrowseCampaignsLoader: FunctionComponent<Props> = ({ style, count = 3 }) => {
  return (
    <div style={style} data-testid="browseCampaignsLoader">
      {Array(count).fill(<CampaignContentLoader />)}
    </div>
  )
}
