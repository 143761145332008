import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const MenuIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <line x1="2.25" y1="18.003" x2="21.75" y2="18.003" />
    <line x1="2.25" y1="12.003" x2="21.75" y2="12.003" />
    <line x1="2.25" y1="6.003" x2="21.75" y2="6.003" />
  </svg>
)
