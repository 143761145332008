import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import { Button, TextField, Loading } from '../shared'
import { LocationFilter } from './LocationFilter'
import { LocationsContext } from './LocationsContext'
import { pushMessage, removeMessage } from '../AppMessages'
import { findCoordinatesForKeyword } from '../../services'
import {
  LocationSearch_TextFieldLabel,
  LocationSearch_SubmitSearchButtonText,
  LocationSearch_Title,
  LocationSearch_ErrorMessage,
  LocationSearch_NumberOfResults,
  LocationSearch_NoResultsDisclaimer,
  LocationList_Subtitle,
  LocationSearch_NoLocationsInViewport
} from '../../translations/messages'
import { intl } from '../../lib'

const Container = styled.form`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

const ErrorMessage = styled.p`
  color: var(--colors-red);
  font-weight: bold;
`

const NoResults = styled.div`
  margin-top: -1rem;
`

type Props = {
  closeDrawer: () => void
}

export const LocationSearch = ({ closeDrawer }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'noResults'>('initial')
  const { mapInstance, getLocationsInViewport, mapSdk } = useContext(LocationsContext)

  const performSearch = async (evt: React.FormEvent) => {
    evt.preventDefault()
    removeMessage()

    if (!searchValue) {
      closeDrawer()
    }

    setRequestStatus('loading')

    try {
      const { result, status } = await findCoordinatesForKeyword(
        mapSdk!,
        searchValue,
        mapInstance?.getBounds() as google.maps.LatLngBounds
      )

      if (!result || result.length === 0) {
        return setRequestStatus('noResults')
      } else if (status !== 'OK') {
        if (status === 'ZERO_RESULTS') {
          return setRequestStatus('noResults')
        } else {
          throw new Error(status)
        }
      }

      const { location, viewport } = result[0].geometry
      mapInstance?.setCenter(location)
      mapInstance?.fitBounds(new google.maps.LatLngBounds(viewport.getSouthWest(), viewport.getNorthEast()))

      const numberOfLocations = getLocationsInViewport().length

      if (numberOfLocations !== 0) {
        pushMessage({
          type: 'success',
          formattedMessage: intl.formatMessage(LocationList_Subtitle, { numberOfLocations: String(numberOfLocations) }),
          ttl: 5000
        })
      } else {
        pushMessage({
          type: 'warning',
          formattedMessage: intl.formatMessage(LocationSearch_NoLocationsInViewport),
          ttl: 5000
        })
      }

      setRequestStatus('success')
      closeDrawer()
    } catch (error: any) {
      setRequestStatus('failed')
      logError(new Error('Failed to perform geocoding search for location'), error)
    }
  }

  return (
    <Container onSubmit={performSearch}>
      <h1>{intl.formatMessage(LocationSearch_Title)}</h1>

      <TextField
        onChange={value => {
          if (value !== searchValue) setRequestStatus('initial')
          setSearchValue(value)
        }}
        autoComplete="off"
        value={searchValue}
        valid
        label={intl.formatMessage(LocationSearch_TextFieldLabel)}
        type="text"
      />

      {requestStatus === 'failed' && <ErrorMessage>{intl.formatMessage(LocationSearch_ErrorMessage)}</ErrorMessage>}

      {requestStatus === 'noResults' && (
        <NoResults>
          <ErrorMessage>
            {intl.formatMessage(LocationSearch_NumberOfResults, { numberOfResults: 0, searchValue })}
          </ErrorMessage>

          <small>{intl.formatMessage(LocationSearch_NoResultsDisclaimer)}</small>
        </NoResults>
      )}

      <LocationFilter />

      <Button background="colored" aria-label="Perform search">
        {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(LocationSearch_SubmitSearchButtonText)}
      </Button>
    </Container>
  )
}
