import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

const Container = styled.div`
  min-height: 100vh;
  background-color: var(--colors-secondary);
  padding: var(--spacing-xl);
`

const InnerContainer = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
`

export const LoginLayout = () => (
  <Container>
    <InnerContainer>
      <Outlet />
    </InnerContainer>
  </Container>
)
