import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const PiggyBankIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M23.25,9.5a3,3,0,0,1-3,3" />
    <path d="M8.25,8a6.763,6.763,0,0,1,6,0" />
    <path d="M6.375,11a.375.375,0,1,0,.375.375A.375.375,0,0,0,6.375,11" />
    <path d="M7.9,4.883C6.726,2.756,3.75,2.75,3.75,2.75l.626,4.384A8.058,8.058,0,0,0,2.6,10.25H.75v6H3.24a8.467,8.467,0,0,0,2.01,2.372V21.5a.75.75,0,0,0,.75.75H7.5a.75.75,0,0,0,.75-.75V20.259a9.512,9.512,0,0,0,6,.011V21.5a.75.75,0,0,0,.75.75h1.5a.75.75,0,0,0,.75-.75V18.637a7.941,7.941,0,0,0,3-6.137C20.25,6.006,13.2,3.111,7.9,4.883Z" />
  </svg>
)
