import styled from 'styled-components'

export const Logo = styled.img`
  margin-right: var(--spacing-md);
  border-radius: 50%;
  border: 2px solid var(--colors-tertiary);
  width: 5rem;
  height: 5rem;
  object-fit: cover;
`
