import { fetch as _fetch, fetchData as _fetchData, fetchRawResponse as _fetchRaw } from '@tomra/client-side-http-client'
import { logError } from '@tomra/datadog-browser-logging'
import { APP_NAME, FIREBASE } from './environment'
import { removeItemFromWebStorage } from './webStorage'

const headers = { 'Tomra-App-Context': APP_NAME }

const authentication = {
  fetchNewToken: async () => {
    try {
      const token = await FIREBASE.auth.currentUser?.getIdToken(true)
      return token || Promise.reject('Unauthorized')
    } catch (error: any) {
      logError(new Error('Failed to fetch new token'), error)
      removeItemFromWebStorage('lastAuthTimestamp')
      FIREBASE.auth.signOut()
      return Promise.reject('Unauthorized')
    }
  },
  getToken: async () => {
    try {
      const token = await FIREBASE.auth.currentUser?.getIdToken()
      return token || ''
    } catch (error) {
      return ''
    }
  }
}

export const enrichedFetch = (url: string, options?: RequestInit) =>
  _fetch(url, { ...options, headers: { ...options?.headers, ...headers } }, undefined)
export const enrichedFetchData = (url: string, options?: RequestInit) =>
  _fetchData(url, { ...options, headers: { ...options?.headers, ...headers } }, undefined)
export const enrichedFetchRaw = (url: string, options?: RequestInit) =>
  _fetchRaw(url, { ...options, headers: { ...options?.headers, ...headers } }, undefined)
export const enrichedAuthenticatedFetch = (url: string, options?: RequestInit) =>
  _fetch(url, { ...options, headers: { ...options?.headers, ...headers } }, authentication)
export const enrichedAuthenticatedFetchData = (url: string, options?: RequestInit) =>
  _fetchData(url, { ...options, headers: { ...options?.headers, ...headers } }, authentication)
export const enrichedAuthenticatedFetchRaw = (url: string, options?: RequestInit) =>
  _fetchRaw(url, { ...options, headers: { ...options?.headers, ...headers } }, authentication)
