import React from 'react'
import { theme, intl } from '../../lib'
import { VoucherIcon, HeartIcon, SavingBankIcon } from '../icons'
import {
  PayoutSettings_BankTransfer_Description,
  Common_BankTransfer,
  Common_PayPal,
  PayoutSettings_PayPal_Description,
  Common_Donation,
  PayoutSettings_Donation_Description,
  Common_DigitalVoucher,
  PayoutSettings_Voucher_Description,
  PayoutSettings_Voucher_Disclaimer
} from '../../translations/messages'

export const payoutLogoAndTranslationMap = {
  PAYPAL: {
    logo: <img src={theme.images.paypalLogo} alt="paypal" height="20px" />,
    name: intl.formatMessage(Common_PayPal),
    description: intl.formatMessage(PayoutSettings_PayPal_Description)
  },
  E_VOUCHER: {
    logo: <VoucherIcon />,
    name: intl.formatMessage(Common_DigitalVoucher),
    description: (
      <React.Fragment>
        {`${intl.formatMessage(PayoutSettings_Voucher_Description)} ${intl.formatMessage(PayoutSettings_Voucher_Disclaimer)}`}
      </React.Fragment>
    )
  },
  DONATION: {
    logo: <HeartIcon />,
    name: intl.formatMessage(Common_Donation),
    description: intl.formatMessage(PayoutSettings_Donation_Description)
  },
  BANK_TRANSFER: {
    logo: <SavingBankIcon />,
    name: intl.formatMessage(Common_BankTransfer),
    description: intl.formatMessage(PayoutSettings_BankTransfer_Description)
  }
}
