import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Button = styled.button<{
  children: React.ReactNode
  background?: 'white' | 'colored' | 'transparent' | 'danger'
  border?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => (React.Children.count(props.children) > 1 ? 'space-between' : 'center')};
  color: ${({ background }) => (background === 'danger' ? 'white' : 'black')};
  background-color: ${({ background }) =>
    background === 'colored'
      ? 'var(--colors-button)'
      : background === 'transparent'
        ? 'transparent'
        : background === 'danger'
          ? 'red'
          : 'white'};
  border: ${props => (props.border ? '2px solid black' : 'none')};
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  width: 100%;
  transition: 200ms;
  padding: 0 var(--spacing-md);
  min-height: 3.5rem;
  opacity: ${props => props.disabled && 0.6};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;

  > svg {
    min-width: 22px;
  }

  > *:first-child {
    min-width: 15px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const IconButton = styled(Button)`
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  border: none;
  min-height: initial;
  width: initial;
  border-radius: initial;
`

export const ListButton = styled(Button)<{ borderBottom?: boolean; label?: string; labelColor?: string }>`
  justify-content: initial;
  box-shadow: initial;
  background-color: transparent;
  font-weight: normal;
  border-top: 1px solid var(--colors-grey);
  border-bottom: ${props => (props.borderBottom ? `1px solid var(--colors-grey)` : 'none')};
  border-radius: initial;
  width: 100%;

  :after {
    display: ${props => (props.label ? 'initial' : 'none')};
    background-color: ${props => (props.labelColor ? props.labelColor : 'var(--colors-green)')};
    border-radius: var(--radius-lg);
    padding: var(--spacing-xs) var(--spacing-sm);
    box-shadow: var(--shadow-lg);
    content: '${props => props.label}';
    line-height: 1;
    color: white;
    font-size: var(--font-size-sm);
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 1;
  }

  > div {
    flex: 1;
    text-align: left;

    > span {
      margin: 0 var(--spacing-xl);
    }
  }
`

export const LinkButton = styled(Link)<{ background?: 'white' | 'colored' | 'transparent'; border?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => (React.Children.count(props.children) > 1 ? 'space-between' : 'center')};
  color: black;
  background-color: ${({ background }) =>
    background === 'colored' ? 'var(--colors-button)' : background === 'transparent' ? 'transparent' : 'white'};
  border: ${props => (props.border ? '2px solid black' : 'none')};
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  width: 100%;
  transition: 200ms;
  padding: 0 var(--spacing-md);
  min-height: 3.5rem;
  font-weight: bold;

  > svg {
    min-width: 22px;
  }

  > *:first-child {
    min-width: 15px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const AnchorButton = styled.a<{ background?: 'white' | 'colored' | 'transparent'; border?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => (React.Children.count(props.children) > 1 ? 'space-between' : 'center')};
  color: black;
  background-color: ${({ background }) =>
    background === 'colored' ? 'var(--colors-button)' : background === 'transparent' ? 'transparent' : 'white'};
  border: ${props => (props.border ? '2px solid black' : 'none')};
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  width: 100%;
  transition: 200ms;
  padding: 0 var(--spacing-md);
  min-height: 3.5rem;
  font-weight: bold;

  > svg {
    min-width: 22px;
  }

  > *:first-child {
    min-width: 15px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export const ListItem = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  align-items: center;
  color: black;
  background-color: transparent;
  border-top: 1px solid var(--colors-grey);
  border-bottom: ${props => (props.borderBottom ? `1px solid var(--colors-grey)` : 'none')};
  width: 100%;
  padding: 0 var(--spacing-md);
  min-height: 3.5rem;

  > div {
    flex: 1;
    text-align: left;

    > span {
      margin: 0 var(--spacing-xl);
    }
  }
`

export const ButtonLinkStyle = styled.button`
  margin: 0 var(--spacing-xs);
  border: none;
  font-weight: bold;
  text-decoration: underline;
  background: transparent;
`
