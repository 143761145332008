import { intl } from '../../lib'
import {
  Common_GeneralError,
  Auth_MyTomraInNsw,
  Auth_EmailConditionsNotMet,
  Auth_EmailNotSameAsLink,
  Auth_MissingEmail,
  Auth_ExpiredLoginLink,
  Auth_InvalidLoginLink,
  Auth_UserDisabled,
  Auth_WrongPassword
} from '../../translations/messages'

export const feedbackMessages = {
  failed: intl.formatMessage(Common_GeneralError),
  'missing-email': intl.formatMessage(Auth_MissingEmail),
  'mytomra-in-nsw': intl.formatMessage(Auth_MyTomraInNsw),
  'auth/argument-error': intl.formatMessage(Auth_EmailNotSameAsLink),
  'auth/invalid-email': intl.formatMessage(Auth_EmailConditionsNotMet),
  'auth/expired-action-code': intl.formatMessage(Auth_ExpiredLoginLink),
  'auth/invalid-action-code': intl.formatMessage(Auth_InvalidLoginLink),
  'auth/user-disabled': intl.formatMessage(Auth_UserDisabled),
  'auth/wrong-password': intl.formatMessage(Auth_WrongPassword)
}
