import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const UserIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="12" cy="9.75" r="5.25" />
    <path d="M18.913,20.876a9.746,9.746,0,0,0-13.826,0" />
    <circle cx="12" cy="12" r="11.25" />
  </svg>
)
