import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const BarcodeIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 52.15 40"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'black'
    }}
  >
    <rect width="10.03" height="40" />
    <rect x="37.1" width="9.03" height="40" />
    <rect x="14.04" width="5.01" height="40" />
    <rect x="27.08" width="7.02" height="40" />
    <rect x="22.06" width="2.01" height="40" />
    <rect x="49.14" width="3.01" height="40" />
  </svg>
)
