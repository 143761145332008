import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { BackButton, ButtonLinkStyle } from '../shared'
import { EnvelopeIcon } from '../icons'
import { openBrowserWindow } from '../../services'
import { intl, countryStateMap, VIEWER_COUNTRY_STATE } from '../../lib'
import {
  Auth_EmailLinkSentAt,
  Auth_OpenEmailAndTapToSignIn,
  Auth_DidntReceiveLink,
  Auth_CheckSpamFolder,
  Auth_CheckSpellingOfEmail,
  Auth_ContactSupport,
  Auth_WaitTwoMinutes,
  Auth_RegisterDisclaimer,
  Auth_LoginDisclaimer,
  Common_CheckEmail
} from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 85vh;
`

const HelpContainer = styled.div`
  margin-left: var(--spacing-md);

  li {
    margin-top: var(--spacing-xs);
  }

  > ul > li:first-child {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    color: black;
    font-weight: bold;
    vertical-align: baseline;
  }
`

export const MailSentPage = () => {
  const location = useLocation()
  const { contactInfo } = countryStateMap[VIEWER_COUNTRY_STATE]

  return (
    <>
      <BackButton to="/login" />

      <Container className="animated fadeInRight">
        <EnvelopeIcon className="animated pulse centerMargin" size="5rem" />

        <h1>{intl.formatMessage(Common_CheckEmail)}</h1>

        <p>{intl.formatMessage(Auth_EmailLinkSentAt)}</p>
        <h2>{location.state?.email}</h2>

        <p>{intl.formatMessage(Auth_OpenEmailAndTapToSignIn)}</p>

        <p>{intl.formatMessage(Auth_DidntReceiveLink)}</p>

        <HelpContainer>
          <ul>
            <li>
              {intl.formatMessage(location.state?.isRegisterFlow ? Auth_RegisterDisclaimer : Auth_LoginDisclaimer)}
            </li>
            <li>{intl.formatMessage(Auth_WaitTwoMinutes)}</li>
            <li>{intl.formatMessage(Auth_CheckSpamFolder)}</li>
            <li>{intl.formatMessage(Auth_CheckSpellingOfEmail)}</li>
            <li>
              {intl.formatMessage(Auth_ContactSupport)}:
              <ButtonLinkStyle
                style={{ marginLeft: 'var(--spacing-xs)' }}
                onClick={() => openBrowserWindow(contactInfo.loginHelpUrl)}
              >
                {contactInfo.loginHelpUrl}
              </ButtonLinkStyle>
            </li>
          </ul>
        </HelpContainer>
      </Container>
    </>
  )
}
