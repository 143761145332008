import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '../icons'
import { Common_Back } from '../../translations/messages'
import { intl } from '../../lib'

const Container = styled.a<{ backgroundColor?: string; color: string }>`
  display: flex;
  align-items: center;
  color: ${props => props.color};
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
  z-index: 1;
  cursor: pointer;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'initial')};
  padding-left: ${props => (props.backgroundColor ? 'var(--spacing-md)' : 0)};
  padding-right: ${props => (props.backgroundColor ? 'var(--spacing-md)' : 0)};
  min-height: ${props => (props.backgroundColor ? '52px' : 'initial')};
  border-radius: ${props => (props.backgroundColor ? 'var(--radius-lg)' : 'initial')};

  > h2 {
    line-height: 1;
    margin-left: var(--spacing-sm);
  }
`

type Props = {
  to?: string
  color?: string
  backgroundColor?: string
}

export const BackButton = ({ to, color, backgroundColor }: Props) => {
  const navigate = useNavigate()

  return (
    <Container
      role="button"
      onClick={() => (to ? navigate(to) : navigate(-1))}
      color={color || 'black'}
      backgroundColor={backgroundColor}
    >
      <ArrowLeftIcon color={color} />
      <h2>{intl.formatMessage(Common_Back)}</h2>
    </Container>
  )
}
