import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LanguagePicker } from './LanguagePicker'
import { setAndSaveAppUrl } from '../../services'
import { LinkButton, CountryPickerButton } from '../shared'
import { theme, intl, STAGE } from '../../lib'
import { UserIcon, ArrowRightIcon, LockIcon } from '../icons'
import { WelcomePage_Locations, WelcomePage_Subtitle, Auth_Register, Auth_SignIn } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
`

const ButtonContainer = styled.div`
  > :first-child {
    margin-bottom: var(--spacing-md);
  }
`

const Logo = styled.img`
  max-width: 10rem;
  max-height: 6rem;
  margin: 4rem auto 0 auto;
`

const BrandingImage = styled.img`
  height: calc(50px + 20vh);
  margin: 0 auto;
`

const LanguagePickerContainer = styled.div`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
`

const CountryPickerContainer = styled.div`
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
`

const LocationsLink = styled(Link)`
  display: block;
  text-align: center;
  font-weight: bold;
  color: black;
  text-decoration: underline;
  text-align: center;
`

export const WelcomePage = () => {
  const [easterEggClicks, setEasterEggClicks] = useState(1)

  const onBrandingImageClick = () => {
    setEasterEggClicks(easterEggClicks + 1)

    if (easterEggClicks % 15 === 0) {
      const isTest = STAGE === 'test'

      if (window.confirm(`Switch to ${isTest ? 'prod' : 'test'} environment?`)) {
        setAndSaveAppUrl(isTest ? window.location.host.replace('test.', '') : `test.${window.location.host}`)
      }
    }
  }

  return (
    <Container className="fadeIn">
      {theme.showCountryPicker && (
        <CountryPickerContainer>
          <CountryPickerButton />
        </CountryPickerContainer>
      )}

      <LanguagePickerContainer>
        <LanguagePicker />
      </LanguagePickerContainer>

      <Logo src={theme.images.welcomePageLogo} alt="Tomra logo" />

      <BrandingImage onClick={onBrandingImageClick} src={theme.images.welcomePageBrandingElement} alt="welcome" />

      <h2>{intl.formatMessage(WelcomePage_Subtitle)}</h2>

      <ButtonContainer>
        <LinkButton to="/login/register">
          <UserIcon />
          {intl.formatMessage(Auth_Register)}
          <ArrowRightIcon />
        </LinkButton>

        <LinkButton to="/login/signin">
          <LockIcon />
          {intl.formatMessage(Auth_SignIn)}
          <ArrowRightIcon />
        </LinkButton>
      </ButtonContainer>

      <LocationsLink to="/locations">{intl.formatMessage(WelcomePage_Locations)}</LocationsLink>
    </Container>
  )
}
