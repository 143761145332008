import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { logError, logInfo } from '@tomra/datadog-browser-logging'
import { feedbackMessages } from './feedbackMessages'
import { TermsCheckbox } from './TermsCheckbox'
import { Button, TextField, Loading, Alert, Checkbox, InfoTooltip, BackButton } from '../shared'
import { PersonQuestionIcon, UserIcon, ArrowRightIcon } from '../icons'
import { theme, intl, setItemInWebStorage, emailRegex } from '../../lib'
import { sendSignInLink } from '../../services'
import {
  Auth_Register,
  Auth_FirstNameDisclaimer,
  Auth_RegisterDescription,
  Common_Email,
  Common_SubscribeToNewsletter,
  Common_Continue,
  Auth_Name
} from '../../translations/messages'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 90vh;

  > * {
    margin-top: var(--spacing-md);
  }
`

const InfoTooltipContainer = styled.div`
  position: absolute;
  top: 25%;
  right: 0;
`

const NewsLetterContainer = styled.div`
  display: flex;

  > label {
    margin-left: var(--spacing-md);
    cursor: pointer;
  }
`

export const RegisterPage = () => {
  const navigate = useNavigate()
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [hasAcceptedNewsletter, setHasAcceptedNewsletter] = useState(false)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const sendLoginEmail = async (evt: React.FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      await sendSignInLink(emailValue, `${window.location.origin}/login/email-link`, 'REGISTER')
      await setItemInWebStorage('emailForSignIn', emailValue)
      await setItemInWebStorage('nameForSignIn', nameValue)
      await setItemInWebStorage('newsletterForSignIn', hasAcceptedNewsletter)
      navigate('/login/login-link-sent', {
        state: { email: emailValue, isRegisterFlow: true }
      })
    } catch (error: any) {
      if (error.status === 412) {
        // User has set up with password login from manager
        navigate('/login/password', { state: { email: emailValue } })
      } else if (error.status === 409) {
        // Force user over from MT to R&E
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['mytomra-in-nsw'])
        logError(new Error('Attempted R&E login in MT'), error)
      } else if (error.status === 400) {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['auth/invalid-email'])
        logInfo(`Attempted login with invalid email. Error: ${error.message}`)
      } else {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
        logError(new Error('Failed to send login link'), error)
      }
    }
  }

  return (
    <>
      <BackButton to="/login" />

      <Form className="animated fadeInRight" onSubmit={sendLoginEmail}>
        <PersonQuestionIcon className="centerMargin" size="5rem" />

        <h1>{intl.formatMessage(Auth_Register)}</h1>

        <p>{intl.formatMessage(Auth_RegisterDescription, { appName: theme.name })}</p>

        <div>
          <TextField
            onChange={value => setNameValue(value)}
            value={nameValue}
            label={intl.formatMessage(Auth_Name)}
            ariaLabel={intl.formatMessage(Auth_Name)}
            autoComplete="given-name"
            required
          >
            <InfoTooltipContainer>
              <InfoTooltip content={intl.formatMessage(Auth_FirstNameDisclaimer, { appName: theme.name })} />
            </InfoTooltipContainer>
          </TextField>

          <TextField
            onChange={(value, valid) => {
              setEmailValue(value.toLowerCase())
              setEmailValid(valid)
            }}
            value={emailValue}
            valid={emailValid}
            label={intl.formatMessage(Common_Email)}
            ariaLabel={intl.formatMessage(Common_Email)}
            autoComplete="email"
            type="email"
            pattern={emailRegex}
            required
          />
        </div>

        <div>
          <NewsLetterContainer>
            <Checkbox
              id="newsletterCheckbox"
              aria-label="Subscribe to newsletter"
              onChange={evt => setHasAcceptedNewsletter(evt.target.checked)}
              checked={hasAcceptedNewsletter}
            />

            <label htmlFor="newsletterCheckbox">{intl.formatMessage(Common_SubscribeToNewsletter)}</label>
          </NewsLetterContainer>

          <TermsCheckbox
            checked={hasAcceptedTerms}
            onChange={() => {
              setHasAcceptedTerms(!hasAcceptedTerms)
              window.localStorage.setItem('hasAcceptedTerms', String(!hasAcceptedTerms))
            }}
          />
        </div>

        {feedbackMessage && (
          <Alert backgroundColor="var(--colors-red)" color="white" className="bounceIn">
            {feedbackMessage}
          </Alert>
        )}

        <Button
          type="submit"
          disabled={!emailValue || !emailValid || !nameValue || requestStatus === 'loading' || !hasAcceptedTerms}
        >
          <UserIcon />
          {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Continue)}
          <ArrowRightIcon />
        </Button>
      </Form>
    </>
  )
}
