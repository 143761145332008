import { getAuth, connectAuthEmulator, browserSessionPersistence } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { resolveCountryStateEnv } from './resolveCountryStateEnv'

const { VIEWER_COUNTRY_STATE, ENV } = resolveCountryStateEnv()

const { APP_NAME, APP_VERSION, STAGE, WEB_PUSH_PUBLIC_KEY, DATADOG_CONFIG, CONFIG_BY_STAGE } = window.__ENVIRONMENT__
const { GOOGLE_MAPS_API_KEY, PAYPAL_CLIENT_ID, PAYPAL_AUTH_ENDPOINT, TENANT_ID, FIREBASE_CONFIG, CONFIG_BY_ENV } =
  CONFIG_BY_STAGE[STAGE]
const { API_HOST, ACHIEVEMENTS_API_HOST } = CONFIG_BY_ENV[ENV]

// @ts-ignore
const isE2E = window.Cypress

const app = initializeApp(FIREBASE_CONFIG!)
const db = getFirestore(app, APP_NAME.toLowerCase())
const remoteConfig = getRemoteConfig(app)
const auth = getAuth(app)
auth.tenantId = TENANT_ID

if (isE2E) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
  auth.setPersistence(browserSessionPersistence)
  auth.tenantId = null
}

const FIREBASE = {
  app,
  db,
  auth,
  remoteConfig
}

export {
  APP_NAME,
  APP_VERSION,
  GOOGLE_MAPS_API_KEY,
  ENV,
  STAGE,
  WEB_PUSH_PUBLIC_KEY,
  VIEWER_COUNTRY_STATE,
  API_HOST,
  ACHIEVEMENTS_API_HOST,
  PAYPAL_CLIENT_ID,
  PAYPAL_AUTH_ENDPOINT,
  FIREBASE,
  FIREBASE_CONFIG,
  DATADOG_CONFIG
}
