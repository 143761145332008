import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { signInWithEmailLink, isSignInWithEmailLink, updateProfile } from 'firebase/auth'
import { logError } from '@tomra/datadog-browser-logging'
import { feedbackMessages } from './feedbackMessages'
import { Button, Loading } from '../shared'
import { ErrorIcon } from '../icons'
import {
  FIREBASE,
  intl,
  theme,
  getQueryParameterByName,
  getItemFromWebStorage,
  removeItemFromWebStorage
} from '../../lib'
import { sendSignInLinkTimestamp, updateMarketingsubscription, updateUser } from '../../services'
import { Auth_LoggingYouIn, Auth_InvalidLoginLink, Common_Back } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 85vh;
`

const Logo = styled.img`
  width: 100%;
  max-width: 10rem;
  max-height: 6rem;
  margin-top: var(--spacing-xl);
`

export const EmailMagicLinkPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [feedbackMessage, setFeedbackMessage] = useState(
    !isSignInWithEmailLink(FIREBASE.auth, window.location.href) ? intl.formatMessage(Auth_InvalidLoginLink) : ''
  )

  useEffect(() => {
    const performEmailLinkSignIn = async () => {
      try {
        const email = await getItemFromWebStorage('emailForSignIn')
        const name = await getItemFromWebStorage('nameForSignIn')
        const acceptedNewsletter = await getItemFromWebStorage('newsletterForSignIn')
        const timestamp = getQueryParameterByName('timestamp')

        if (!email) {
          navigate('/login/confirm-email' + window.location.search, { replace: true })
          return
        }

        if (timestamp) {
          sendSignInLinkTimestamp(timestamp).catch(error =>
            logError(new Error('Failed to send sign in link timestamp'), error)
          )
        }

        const { user } = await signInWithEmailLink(FIREBASE.auth, email, window.location.href)

        if (name) {
          await updateUser({ firstName: name })
            .run()
            .catch(error => logError(new Error('Failed to set name on login'), error))

          // updateUser() above also does this, but FIREBASE.currentUser wont update until next page load, so we force it here
          await updateProfile(user, { displayName: name }).catch(error =>
            logError(new Error('Failed to set name on login'), error)
          )
        }

        if (acceptedNewsletter) {
          updateMarketingsubscription('SUBSCRIBED')
            .run()
            .catch(error => logError(new Error('Failed to set newsletter subscription on login'), error))
        }

        removeItemFromWebStorage('nameForSignIn')
        removeItemFromWebStorage('newsletterForSignIn')

        // Just to clear state from url
        navigate('/', { replace: true })
      } catch (error: any) {
        // Reset saved email if it's invalid
        if (['auth/argument-error', 'auth/invalid-email'].includes(error.code)) {
          removeItemFromWebStorage('emailForSignIn')
        }

        const email = await getItemFromWebStorage('emailForSignIn')
        setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
        logError(new Error('Failed to log in with email link'), error, { email })
      }
    }

    performEmailLinkSignIn()
  }, [navigate, location.state])

  if (feedbackMessage) {
    return (
      <Container>
        <ErrorIcon className="centerMargin" size="5rem" />
        <p>{feedbackMessage}</p>
        <Button onClick={() => navigate('/login', { replace: true })}>{intl.formatMessage(Common_Back)}</Button>
      </Container>
    )
  }

  return (
    <Container>
      <Logo src={theme.images.welcomePageLogo} alt="Recycling logo" />
      <Loading />
      <p>{intl.formatMessage(Auth_LoggingYouIn)}</p>
    </Container>
  )
}
