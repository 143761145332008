import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { intl } from '../../../lib'
import styled from 'styled-components'
import { Card } from '../../shared/common'
import { Logo } from '../common'
import { Common_By, BrowseCharitiesPage_Donations } from '../../../translations/messages'
import { benefitToTranslationMap } from './charityTranslationMap'

const CampaignCard = styled(Card)`
  padding: var(--spacing-sm) var(--spacing-md) 0;
  display: grid;
  grid-template-areas:
    'image category'
    'image content';
  grid-template-columns: auto 1fr;
  align-items: center;
`

const CardBody = styled.div`
  grid-area: content;
  padding-bottom: var(--spacing-md);
`

const Title = styled.h2`
  font-weight: bold;
`

const Benefit = styled.small`
  grid-area: category;
  font-weight: bold;
  text-align: right;
`

type Props = {
  campaign: CharityCampaignType
}

export const CharityCampaignCard: FunctionComponent<Props> = ({ campaign }) => {
  const { id, charityLogoUrl, charityBenefit, name, charityDisplayName, numberOfDonations } = campaign

  return (
    <Link to={id}>
      <CampaignCard style={{ color: 'initial' }}>
        <Benefit>{intl.formatMessage(benefitToTranslationMap[charityBenefit])}</Benefit>
        <Logo style={{ gridArea: 'image' }} className="fadeIn" src={charityLogoUrl} alt={`${name} logo`} />
        <CardBody>
          <Title>{name}</Title>
          {intl.formatMessage(Common_By)} {charityDisplayName}
          <div>{intl.formatMessage(BrowseCharitiesPage_Donations, { numberOfDonations })}</div>
        </CardBody>
      </CampaignCard>
    </Link>
  )
}
