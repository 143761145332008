import React, { useEffect, useState } from 'react'
import { initDatadog, logInfo, datadogLogs, datadogRum } from '@tomra/datadog-browser-logging'
import { AppMessages, pushMessage, removeMessage } from './components/AppMessages'
import { NativeAppUpdatedDrawer } from './components/NativeAppUpdatedDrawer'
import { InstallAppDrawer } from './components/InstallAppDrawer'
import { NotificationPromptDrawer } from './components/NotificationPromptDrawer'
import { ConnectionDownIcon } from './components/icons'
import { Loading } from './components/shared'
import { App_ConnectionDown } from './translations/messages'
import { AuthenticatedRouter, UnAuthenticatedRouter } from './routes'
import {
  acceptTermsAndConditions,
  listenForAppUpdates,
  usesiOSNativeWrapper,
  isOnPwa,
  getNativeAppVersion
} from './services'
import {
  intl,
  setItemInWebStorage,
  getItemFromWebStorage,
  removeItemFromWebStorage,
  FIREBASE,
  DATADOG_CONFIG,
  ENV,
  STAGE,
  APP_VERSION
} from './lib'

const setConnectionOnline = () => {
  removeMessage()
}

const setConnectionOffline = () => {
  pushMessage({
    formattedMessage: intl.formatMessage(App_ConnectionDown),
    icon: <ConnectionDownIcon color="black" size="1.67rem" />,
    type: 'danger'
  })
}

export const App = () => {
  const [authState, setAuthState] = useState<'yes' | 'no' | 'missing-name' | 'toBeDecided'>(
    FIREBASE.auth.currentUser ? 'yes' : 'toBeDecided'
  )

  useEffect(() => {
    if (authState === 'yes') {
      acceptTermsAndConditions()
      datadogRum.setUser({ id: FIREBASE.auth.currentUser?.uid })
      datadogLogs.setUser({ id: FIREBASE.auth.currentUser?.uid })
    }
  }, [authState])

  useEffect(() => {
    const removeAuthListener = FIREBASE.auth.onAuthStateChanged(user => {
      if (user) {
        setItemInWebStorage('lastAuthTimestamp', new Date().toISOString())
        setAuthState('yes')
      } else {
        setAuthState('no')

        getItemFromWebStorage('lastAuthTimestamp').then(timestamp => {
          if (timestamp) {
            logInfo(`Unexpected logout. User had an active session from ${timestamp}`)
            removeItemFromWebStorage('lastAuthTimestamp')
          }
        })
      }
    })

    initDatadog({
      ...DATADOG_CONFIG,
      version: APP_VERSION,
      env: `${ENV}-${STAGE}`,
      errorMsgIgnoreRegexList: [/(XHR error|Fetch error).*\/index\.html/gi],
      sessionReplaySampleRate: 100,
      contextProperties: {
        usesiOSNativeWrapper: usesiOSNativeWrapper(),
        isOnPwa: isOnPwa(),
        lastAuthTimestampUponDDinit: localStorage.getItem('lastAuthTimestamp'),
        nativeAppVersion: getNativeAppVersion()
      }
    })

    listenForAppUpdates()

    window.addEventListener('online', setConnectionOnline)
    window.addEventListener('offline', setConnectionOffline)

    return () => {
      removeAuthListener()

      window.removeEventListener('online', setConnectionOnline)
      window.removeEventListener('offline', setConnectionOffline)
    }
  }, [])

  return (
    <>
      <AppMessages />
      <NativeAppUpdatedDrawer />
      <InstallAppDrawer />
      <NotificationPromptDrawer />

      {authState === 'toBeDecided' ? (
        <Loading className="centerAbsolute" />
      ) : authState === 'yes' ? (
        <AuthenticatedRouter />
      ) : (
        <UnAuthenticatedRouter />
      )}
    </>
  )
}
