export const countryStateMap = {
  'AU-NSW': {
    name: 'Australia - New South Wales',
    capital: 'Sydney',
    coordinates: { lat: -33.83, lng: 151 },
    includeExternalLocations: true,
    contactInfo: {
      email: 'customerservice@tcnsw.com.au',
      phone: '1800 290 691',
      facebookUrl: 'https://www.facebook.com/ReturnandEarn',
      loginHelpUrl: 'https://mytomra.com.au/email-verify'
    },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: true,
      HAS_BARCODE: true,
      HAS_CHARITY: true
    },
    env: 'au',
    selectable: {
      prod: false,
      test: true
    }
  },
  'AU-QLD': {
    name: 'Australia - Queensland',
    capital: 'Brisbane',
    coordinates: { lat: -27.47, lng: 153.02 },
    includeExternalLocations: false,
    contactInfo: {
      email: 'help.tca@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/myTOMRAAustralia',
      loginHelpUrl: 'https://mytomra.com.au/email-verify'
    },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'au',
    selectable: {
      prod: false,
      test: true
    }
  },
  'AU-NT': {
    name: 'Australia - Northern Territory',
    capital: 'Darwin',
    coordinates: { lat: -12.46, lng: 130.84 },
    includeExternalLocations: false,
    contactInfo: {
      email: 'help.tca@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/myTOMRAAustralia',
      loginHelpUrl: 'https://mytomra.com.au/email-verify'
    },
    features: {
      HAS_BAGDROP: true,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'au',
    selectable: {
      prod: true,
      test: true
    }
  },
  'AU-VIC': {
    name: 'Australia - Victoria',
    capital: 'Melbourne',
    coordinates: { lat: -37.81, lng: 145 },
    includeExternalLocations: true,
    contactInfo: {
      email: 'help@tomracleanaway.com.au',
      phone: '1800 237 842',
      facebookUrl: 'https://www.facebook.com/TOMRACleanaway',
      loginHelpUrl: 'https://mytomra.com.au/email-verify'
    },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: true,
      HAS_BARCODE: true,
      HAS_CHARITY: true
    },
    env: 'au',
    selectable: {
      prod: false,
      test: true
    }
  },
  'CA-QC': {
    name: 'Canada - Quebec',
    capital: 'Ottawa',
    coordinates: { lat: 45.4215296, lng: -75.69719309999999 },
    includeExternalLocations: false,
    contactInfo: { email: '', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: false,
      HAS_CHARITY: false
    },
    env: 'us',
    selectable: {
      prod: true,
      test: true
    }
  },
  DE: {
    name: 'Germany',
    capital: 'Berlin',
    coordinates: { lat: 52.52000659999999, lng: 13.404954 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: true,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  'GB-ENG': {
    name: 'England',
    capital: 'London',
    coordinates: { lat: 51.5073509, lng: -0.1277583 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: false,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  'GB-SCT': {
    name: 'Scotland',
    capital: 'Edinburgh',
    coordinates: { lat: 55.95, lng: -3.19 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: true,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  PT: {
    name: 'Portugal',
    capital: 'Lisbon',
    coordinates: { lat: 38.7222524, lng: -9.1393366 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: true,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  RO: {
    name: 'Romania',
    capital: 'Bucharest',
    coordinates: { lat: 44.4267674, lng: 26.1025384 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: false,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  NO: {
    name: 'Norway',
    capital: 'Oslo',
    coordinates: { lat: 59.9138688, lng: 10.7522454 },
    includeExternalLocations: false,
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '', loginHelpUrl: '' },
    features: {
      HAS_BAGDROP: false,
      HAS_QR_CODE: true,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'eu',
    selectable: {
      prod: true,
      test: true
    }
  },
  'US-NY': {
    name: 'United States - New York',
    capital: 'New York',
    coordinates: { lat: 40.713, lng: -74.006 },
    includeExternalLocations: false,
    contactInfo: {
      email: 'mytomrasupport.am@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/RetrievalCenters',
      loginHelpUrl: ''
    },
    features: {
      HAS_BAGDROP: true,
      HAS_QR_CODE: true,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'us',
    selectable: {
      prod: true,
      test: true
    }
  },
  'US-CT': {
    name: 'United States - Connecticut',
    capital: 'Hartford',
    coordinates: { lat: 41.76, lng: -72.68 },
    includeExternalLocations: false,
    contactInfo: {
      email: 'mytomrasupport.am@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/RetrievalCenters',
      loginHelpUrl: ''
    },
    features: {
      HAS_BAGDROP: true,
      HAS_QR_CODE: false,
      HAS_CONTAINER_SCANNER: false,
      HAS_BARCODE: true,
      HAS_CHARITY: false
    },
    env: 'us',
    selectable: {
      prod: true,
      test: true
    }
  }
}
