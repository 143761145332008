import React, { useState, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import GoogleMap from 'google-map-react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import { BackButton, FeedbackMessage, Button, Loading, Drawer } from '../shared'
import { SearchIcon, UserLocationIcon, LocationListIcon } from '../icons'
import { pushMessage } from '../AppMessages'
import { LocationSearch, LocationList } from './'
import { findUserLocation } from '../../services'
import { countryStateMap, intl, VIEWER_COUNTRY_STATE, GOOGLE_MAPS_API_KEY } from '../../lib'
import { LocationsProvider, LocationsContext } from './LocationsContext'
import {
  Location_GoToYourLocation,
  LocationList_Title,
  LocationMarkers_FailedToLoadKml,
  LocationSearch_Title,
  LocationsPage_MapLoadingFailed
} from '../../translations/messages'

const fallbackUserLocation = { lat: -33.83, lng: 151 } // Sydney

const MapContainer = styled.div`
  position: relative;
  height: 100vh;
`

const ActionButtonsContainer = styled.div`
  position: absolute;
  bottom: var(--spacing-lg);
  right: var(--spacing-lg);
`

const RoundButton = styled(Button)`
  padding: var(--spacing-md);
`

const LocationsPageContainer = () => {
  const [mapLoadingStatus, setMapLoadingStatus] = useState<RequestStatusType>('loading')
  const [showSearchDrawer, setShowSearchDrawer] = useState(false)
  const [showLocationListDrawer, setShowLocationListDrawer] = useState(false)
  const [userLocation, setUserLocation] = useState<CoordinatesType>()
  const [userLocationMarker, setUserLocationMarker] = useState<google.maps.Marker>()
  const [allowGeolocationInterval, setAllowGeolocationInterval] = useState(false)
  const { mapInstance, initMapMarkers } = useContext(LocationsContext)
  const mapCenter = countryStateMap[VIEWER_COUNTRY_STATE].coordinates

  const findAndSetUserLocation = (map?: google.maps.Map, maps?: typeof google.maps) => {
    findUserLocation()
      .then(result => {
        setUserLocation(result)
        setAllowGeolocationInterval(true)

        if (map && maps) {
          map.setCenter(result)

          setUserLocationMarker(
            new maps.Marker({
              map: map,
              position: result,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#4286f5',
                fillOpacity: 1,
                strokeColor: 'white',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 13
              }
            })
          )
        }
      })
      .catch(() => {
        setAllowGeolocationInterval(false)
      })
  }

  const onMapLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    const didLoadSuccessfully = map !== null && maps !== null

    if (didLoadSuccessfully) {
      setMapLoadingStatus('success')

      findAndSetUserLocation(map, maps)

      initMapMarkers(map, maps).catch(error => {
        pushMessage({
          formattedMessage: intl.formatMessage(LocationMarkers_FailedToLoadKml),
          type: 'danger'
        })

        logError(new Error('Failed to load/set map locations'), error)
      })
    } else {
      setMapLoadingStatus('failed')
    }
  }

  useEffect(() => {
    let intervalRef

    if (allowGeolocationInterval) {
      intervalRef = setInterval(() => {
        findUserLocation()
          .then(result => {
            userLocationMarker?.setPosition(result)
            setUserLocation(result)
          })
          .catch(() => {
            clearInterval(intervalRef)
            setAllowGeolocationInterval(false)
          })
      }, 5000)
    }

    return () => clearInterval(intervalRef)
  }, [allowGeolocationInterval, userLocationMarker])

  return (
    <>
      <BackButton to="/" backgroundColor="white" />

      <MapContainer data-testid="locationsMap">
        <GoogleMap
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultZoom={11}
          center={mapCenter || fallbackUserLocation}
          options={mapsSdk => ({
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            zoomControlOptions: {
              position: mapsSdk.ControlPosition.RIGHT_CENTER
            },
            keyboardShortcuts: false
          })}
          onGoogleApiLoaded={onMapLoaded}
          yesIWantToUseGoogleMapApiInternals={true}
        />

        {mapLoadingStatus === 'loading' ? (
          <div data-testid="loading">
            <Loading className="centerAbsolute" />
          </div>
        ) : mapLoadingStatus === 'failed' ? (
          <FeedbackMessage className="centerAbsolute fadeIn">
            {intl.formatMessage(LocationsPage_MapLoadingFailed)}
          </FeedbackMessage>
        ) : (
          <>
            <ActionButtonsContainer>
              <RoundButton
                onClick={() => setShowSearchDrawer(true)}
                aria-label={intl.formatMessage(LocationSearch_Title)}
              >
                <SearchIcon size="2rem" />
              </RoundButton>

              <RoundButton
                onClick={() => setShowLocationListDrawer(true)}
                aria-label={intl.formatMessage(LocationList_Title)}
                style={{ margin: 'var(--spacing-md) 0' }}
              >
                <LocationListIcon size="2rem" />
              </RoundButton>

              <RoundButton
                onClick={() => mapInstance?.setCenter(userLocation!)}
                disabled={!userLocation}
                aria-disabled={!userLocation}
                aria-label={intl.formatMessage(Location_GoToYourLocation)}
              >
                <UserLocationIcon size="2rem" />
              </RoundButton>
            </ActionButtonsContainer>

            <Drawer
              autoFocusOnFirstInteractiveElement={false}
              onClose={() => setShowSearchDrawer(false)}
              visible={showSearchDrawer}
            >
              <LocationSearch closeDrawer={() => setShowSearchDrawer(false)} />
            </Drawer>

            <Drawer onClose={() => setShowLocationListDrawer(false)} visible={showLocationListDrawer}>
              <LocationList />
            </Drawer>

            <Outlet />
          </>
        )}
      </MapContainer>
    </>
  )
}

export const LocationsPage = () => (
  <LocationsProvider>
    <LocationsPageContainer />
  </LocationsProvider>
)
