import styled from 'styled-components'

export const Alert = styled.div<{ color: string; backgroundColor: string }>`
  border: 2px solid black;
  padding: var(--spacing-md);
  text-align: center;
  border-radius: var(--radius-sm);
  font-size: var(--font-size-sm);
  color: ${props => props.color || 'black'};
  background-color: ${props => props.backgroundColor || 'white'};
`
