import React from 'react'
import styled from 'styled-components'
import { openBrowserWindow } from '../../services'
import { Button, BackButton } from '../shared'
import { EmailIcon, ArrowRightIcon, FacebookIcon, PhoneIcon } from '../icons'
import { countryStateMap, VIEWER_COUNTRY_STATE, theme, intl } from '../../lib'
import {
  Common_ContactUs,
  ContactUs_Description,
  ContactUs_FindUsOnFacebook,
  ContactUs_NotAvailable
} from '../../translations/messages'

const Container = styled.div`
  height: 100vh;
  padding: var(--spacing-lg);
`

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: var(--max-page-width);

  > div {
    margin-top: var(--spacing-lg);

    h1 {
      margin-bottom: var(--spacing-md);
    }
  }

  button {
    margin-top: var(--spacing-lg);
  }
`

const EmailButton = styled(Button)`
  > span {
    display: flex;
    flex-direction: column;
  }
`

export const ContactUs = () => {
  const { name, contactInfo } = countryStateMap[VIEWER_COUNTRY_STATE]
  const { email, phone, facebookUrl } = contactInfo
  const hasContactInfo = email || phone || facebookUrl

  return (
    <Container>
      <BackButton to="/" />

      <InnerContainer className="zoomIn">
        <div>
          <h1>{intl.formatMessage(Common_ContactUs)}</h1>

          <span>
            {hasContactInfo
              ? intl.formatMessage(ContactUs_Description)
              : intl.formatMessage(ContactUs_NotAvailable, { appName: theme.name, countryStateName: name })}
          </span>
        </div>

        {email && (
          <a href={`mailto:${email}`}>
            <EmailButton>
              <EmailIcon />
              {email}
              <ArrowRightIcon />
            </EmailButton>
          </a>
        )}

        {phone && (
          <a href={`tel:${phone}`}>
            <EmailButton>
              <PhoneIcon />
              {phone}
              <ArrowRightIcon />
            </EmailButton>
          </a>
        )}

        {facebookUrl && (
          <Button onClick={() => openBrowserWindow(facebookUrl)}>
            <FacebookIcon />
            <span>{intl.formatMessage(ContactUs_FindUsOnFacebook)}</span>
            <ArrowRightIcon />
          </Button>
        )}
      </InnerContainer>
    </Container>
  )
}
