import React, { FormEvent, FunctionComponent, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { Button, Drawer, TextField, Loading } from '../../shared/common'
import {
  Auth_EmailConditionsNotMet,
  Common_Send,
  Common_Email,
  TaxReceiptDrawer_Description,
  TaxReceiptDrawer_EmailNotSent,
  TaxReceiptDrawer_EmailSent,
  TaxReceiptDrawer_From,
  TaxReceiptDrawer_FromDateMustBeBeforeToDate,
  TaxReceiptDrawer_NoValidDonations,
  TaxReceiptDrawer_Title,
  TaxReceiptDrawer_To,
  TaxReceiptDrawer_Today,
  TaxReceiptDrawer_ToMustBeInPast
} from '../../../translations/messages'
import styled from 'styled-components'
import { FIREBASE, intl } from '../../../lib'
import { DatePickerField } from './DatePickerField'
import { sendTaxReceipts } from '../../../services'

const Title = styled.h1`
  margin-bottom: var(--spacing-xl);
`

const Form = styled.form`
  margin-top: var(--spacing-xl);
`

const FeedbackMessage = styled.b<{ color?: string }>`
  text-align: center;
  display: block;
  margin-top: var(--spacing-md);
  color: ${props => (props.color ? props.color : 'var(--colors-red)')};
`

const dateIsToday = (date: string) => {
  try {
    const dateToCheck = new Date(date)
    const today = new Date()
    return dateToCheck.toISOString().substr(0, 10) === today.toISOString().substr(0, 10)
  } catch (e) {
    return false
  }
}

const startOfYear = new Date(`${new Date().getFullYear()}-01-01`).toISOString().substr(0, 10)
const today = new Date().toISOString().substr(0, 10)

type Props = {
  show: boolean
  onClose: () => void
}

export const TaxReceiptDrawer: FunctionComponent<Props> = ({ show, onClose }) => {
  const email = FIREBASE.auth.currentUser?.email || ''
  const [requestStatus, setRequestStatus] = useState<RequestStatusType | 'noValidDonations'>('initial')
  const [receiptEmail, setReceiptEmail] = useState<string>(email)
  const [receiptEmailValid, setReceiptEmailValid] = useState<boolean>(true)
  const [fromDate, setFromDate] = useState<string>(startOfYear)
  const [toDate, setToDate] = useState<string>(today)
  const startBeforeEndDate = new Date(fromDate).valueOf() <= new Date(toDate).valueOf()
  const endBeforeOrEqualToToday = new Date(toDate).valueOf() <= new Date().valueOf()
  const datesAreValid = startBeforeEndDate && endBeforeOrEqualToToday
  const dateErrorMessage = !startBeforeEndDate
    ? intl.formatMessage(TaxReceiptDrawer_FromDateMustBeBeforeToDate)
    : !endBeforeOrEqualToToday
      ? intl.formatMessage(TaxReceiptDrawer_ToMustBeInPast)
      : ''
  const fromDateIsToday = dateIsToday(fromDate)
  const toDateIsToday = dateIsToday(toDate)
  const sendButtonDisabled = !receiptEmailValid || !datesAreValid || requestStatus === 'loading'

  const triggerReceipts = (event: FormEvent) => {
    event.preventDefault()
    setRequestStatus('loading')

    sendTaxReceipts(fromDate, toDate, receiptEmail)
      .then(() => {
        setRequestStatus('success')
        setTimeout(() => onClose, 3000)
      })
      .catch(error => {
        setRequestStatus(error.status === 412 ? 'noValidDonations' : 'failed')
        logError(new Error('Could not send tax receipts.'), error)
      })
  }

  return (
    <Drawer visible={show} onClose={onClose}>
      <Title>{intl.formatMessage(TaxReceiptDrawer_Title)}</Title>
      <div>{intl.formatMessage(TaxReceiptDrawer_Description)}</div>

      <Form onSubmit={triggerReceipts}>
        <DatePickerField
          label={intl.formatMessage(TaxReceiptDrawer_From)}
          value={fromDate}
          valid={datesAreValid}
          maxDate={new Date().toISOString().substring(0, 10)}
          onChange={value => setFromDate(value)}
          required={true}
          replaceLabel={fromDateIsToday}
          replacementLabel={intl.formatMessage(TaxReceiptDrawer_Today)}
          errorMsg={!startBeforeEndDate ? intl.formatMessage(TaxReceiptDrawer_FromDateMustBeBeforeToDate) : ''}
        />

        <DatePickerField
          label={intl.formatMessage(TaxReceiptDrawer_To)}
          value={toDate}
          valid={datesAreValid}
          maxDate={new Date().toISOString().substring(0, 10)}
          onChange={value => setToDate(value)}
          required={true}
          replaceLabel={toDateIsToday}
          replacementLabel={intl.formatMessage(TaxReceiptDrawer_Today)}
          errorMsg={dateErrorMessage}
        />

        <TextField
          label={intl.formatMessage(Common_Email)}
          ariaLabel="Email"
          value={receiptEmail}
          valid={receiptEmailValid}
          onChange={(value, valid) => {
            setReceiptEmailValid(valid)
            setReceiptEmail(value)
          }}
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
          errorMsg={intl.formatMessage(Auth_EmailConditionsNotMet)}
        />

        {requestStatus === 'success' && (
          <FeedbackMessage color="var(--colors-green)">
            {intl.formatMessage(TaxReceiptDrawer_EmailSent)}
          </FeedbackMessage>
        )}

        {requestStatus === 'failed' && (
          <FeedbackMessage>{intl.formatMessage(TaxReceiptDrawer_EmailNotSent)}</FeedbackMessage>
        )}

        {requestStatus === 'noValidDonations' && (
          <FeedbackMessage>{intl.formatMessage(TaxReceiptDrawer_NoValidDonations)}</FeedbackMessage>
        )}

        <Button style={{ marginTop: 'var(--spacing-xl)' }} disabled={sendButtonDisabled} background="colored">
          {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Send)}
        </Button>
      </Form>
    </Drawer>
  )
}
