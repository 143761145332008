import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import { useLocationDetailsQuery } from '../../../hooks'
import { LocationStatus } from '../../locations/LocationStatus'
import { theme } from '../../../lib'
import { ArrowRightIcon } from '../../icons'
import { Card } from '../../shared/common'

const everyTwentySeconds = 20 * 1000

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  cursor: pointer;
  color: initial;
  border-bottom: 1px solid var(--colors-grey);
`

const NameAndHours = styled.small`
  margin: 0 var(--spacing-md);
`

const LoadingPlaceholder = () => {
  return (
    <ContentLoader
      height="8rem"
      width="100%"
      viewBox="0 0 21rem 3.5rem"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="2rem" cy="30%" r="1.2rem" />
      <rect x="30%" y="20%" rx="3" ry="3" width="12rem" height="1.4rem" />
      <rect x="0" y="4rem" rx="3" ry="3" width="100%" height="0.1rem" />
      <rect x="10%" y="70%" rx="3" ry="3" width="80%" height="1.4rem" />
    </ContentLoader>
  )
}

type Props = {
  favoriteLocationId: string
}

export const MyLocation = ({ favoriteLocationId }: Props) => {
  const { data: location } = useLocationDetailsQuery(favoriteLocationId, everyTwentySeconds)

  return (
    <Link to={`locations/${favoriteLocationId}`}>
      <Card className="fadeIn">
        {!location ? (
          <LoadingPlaceholder />
        ) : (
          <React.Fragment>
            <Container>
              <img src={theme.images.mapFavorite} alt="favorite location" height="30" />

              <NameAndHours>
                <b>{location.name}</b>
                <p>{location.readableOpeningHours}</p>
              </NameAndHours>

              <ArrowRightIcon />
            </Container>

            {location.status && <LocationStatus locationStatuses={location.status} />}
          </React.Fragment>
        )}
      </Card>
    </Link>
  )
}
