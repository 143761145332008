import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js/actual/array/entries'
import 'normalize.css'
import './styles/content.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { App } from './App'
import { queryClient } from './hooks/query-hooks'
import { RemoteConfigProvider } from './components/RemoteConfigContext'
import { ErrorBoundary } from 'react-error-boundary'
import { GeneralErrorBoundary, onGeneralErrorBoundary } from './components/ErrorPage'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

root.render(
  <ErrorBoundary fallbackRender={GeneralErrorBoundary} onError={onGeneralErrorBoundary}>
    <RemoteConfigProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RemoteConfigProvider>
  </ErrorBoundary>
)
