import { enrichedAuthenticatedFetchData, API_HOST } from '../lib'

export const validateAccount = (bsb: string, accountNumber: string, accountHolder: string) => {
  return enrichedAuthenticatedFetchData(`${API_HOST}/splitpayments/v1/account/validate`, {
    method: 'POST',
    body: JSON.stringify({
      accountHolder,
      bsb,
      accountNumber
    })
  }).run()
}

export const createZeptoContact = (bsb: string, accountNumber: string, accountHolder: string): Promise<string> => {
  return enrichedAuthenticatedFetchData(`${API_HOST}/splitpayments/v1/account`, {
    method: 'POST',
    body: JSON.stringify({
      accountHolder,
      bsb,
      accountNumber
    })
  })
    .run()
    .then(({ contact_id }) => contact_id)
}
