import React, { CSSProperties } from 'react'

type Props = {
  color?: string
  size?: string
  className?: string
  style?: CSSProperties
}

export const CameraBorderIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 48 48"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    style={{
      fill: props.color || 'black',
      ...props.style
    }}
  >
    <path d="M11.3 39.4q-1.15 0-1.925-.775Q8.6 37.85 8.6 36.7v-6.6h1.1v6.6q0 .6.5 1.1.5.5 1.1.5h6.6v1.1Zm18.8 0v-1.1h6.6q.6 0 1.1-.5.5-.5.5-1.1v-6.6h1.1v6.6q0 1.15-.775 1.925-.775.775-1.925.775ZM8.6 17.9v-6.6q0-1.15.775-1.925Q10.15 8.6 11.3 8.6h6.6v1.1h-6.6q-.6 0-1.1.5-.5.5-.5 1.1v6.6Zm29.7 0v-6.6q0-.6-.5-1.1-.5-.5-1.1-.5h-6.6V8.6h6.6q1.15 0 1.925.775.775.775.775 1.925v6.6Z" />
  </svg>
)
