import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Transition } from '../Transition'
import { IconButton } from './Button'
import { CloseIcon } from '../../icons'
import { intl } from '../../../lib'
import { Common_Close } from '../../../translations/messages'

const noOp = () => {}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 98;
`

const Content = styled.div<{ ignoreMaxContentWidth: boolean }>`
  ${props => (props.ignoreMaxContentWidth ? '' : `max-width: var(--max-page-width);`)}
  margin: 0 auto;
`

Overlay.displayName = 'Drawer'

type Props = {
  visible: boolean
  children: ReactNode
  onClose?: () => void
  shouldCloseOnOverlayClick?: boolean
  style?: React.CSSProperties
  closeButtonColor?: string
  ignoreMaxContentWidth?: boolean
  side?: 'bottom' | 'right'
  autoFocusOnFirstInteractiveElement?: boolean
}

export const Drawer: FunctionComponent<Props> = ({
  visible,
  onClose,
  shouldCloseOnOverlayClick = true,
  children,
  style,
  closeButtonColor,
  ignoreMaxContentWidth,
  side = 'bottom',
  autoFocusOnFirstInteractiveElement = true
}) => {
  const drawerContainerClass = side === 'bottom' ? 'bottom-drawer-container' : 'side-drawer-container'
  const enterAnimation = side === 'bottom' ? 'slide-in-bottom' : 'slide-in-left'
  const dialogRef = useRef<HTMLDivElement>(null)
  const [dialogTriggerElement, setDialogTriggerElement] = useState<HTMLButtonElement | HTMLAnchorElement | null>()

  useEffect(() => {
    const activeElement = document.activeElement as HTMLButtonElement | HTMLAnchorElement
    setDialogTriggerElement(visible ? activeElement : null)
  }, [visible])

  useEffect(() => {
    if (visible && autoFocusOnFirstInteractiveElement) {
      const focusableElements = dialogRef.current?.querySelectorAll(
        'button, [href], input, textarea, [tabindex]:not([tabindex="-1"])'
      )

      if (focusableElements && focusableElements.length > 0) {
        const firstFocusableElement = focusableElements[0] as HTMLButtonElement | HTMLAnchorElement
        firstFocusableElement.focus()
        firstFocusableElement.scrollIntoView && firstFocusableElement.scrollIntoView({ behavior: 'smooth' }) // scrollIntoView isnt defined in jsdom
      }
    } else {
      dialogTriggerElement?.focus()
    }
  }, [dialogTriggerElement, autoFocusOnFirstInteractiveElement, visible])

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (onClose && event.key === 'Escape') {
        event.stopPropagation()
        onClose()
      }
    }

    if (visible) {
      window.addEventListener('keydown', handleEscapeKey)
    } else {
      window.removeEventListener('keydown', handleEscapeKey)
    }

    return () => window.removeEventListener('keydown', handleEscapeKey)
  }, [onClose, visible])

  return ReactDOM.createPortal(
    <Transition visible={visible} type="fade">
      <Overlay onClick={shouldCloseOnOverlayClick ? onClose : noOp}>
        <Transition visible={visible} type={enterAnimation}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div
            id="drawer"
            onClick={evt => evt.stopPropagation()}
            className={drawerContainerClass}
            ref={dialogRef}
            style={style}
            role="dialog"
          >
            <Content ignoreMaxContentWidth={ignoreMaxContentWidth}>{children}</Content>

            {onClose && (
              <IconButton onClick={onClose} aria-label={intl.formatMessage(Common_Close)}>
                <CloseIcon color={closeButtonColor || 'black'} size="1.33rem" />
              </IconButton>
            )}
          </div>
        </Transition>
      </Overlay>
    </Transition>,
    document.getElementById('root') as HTMLDivElement
  )
}
