import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import { registerQrCode } from '../../../services'
import { IconButton, CameraScanner, Drawer, Loading } from '../../shared'
import { intl, theme, STAGE } from '../../../lib'
import { QrCodeIcon } from '../../icons'
import {
  Common_SessionExpired,
  Common_TechnicalIssues,
  Header_QrCode,
  NativeTranslations_RegisterQRCode_Description,
  NativeTranslations_RegisterQRCode_InvalidCode,
  NativeTranslations_RegisterQRCode_LoginFailed,
  NativeTranslations_RegisterQRCode_LoginNotPossible,
  NativeTranslations_RegisterQRCode_Success
} from '../../../translations/messages'

const resultMessages = {
  '400': intl.formatMessage(NativeTranslations_RegisterQRCode_InvalidCode),
  '401': intl.formatMessage(Common_SessionExpired),
  '520': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginFailed),
  '503': intl.formatMessage(NativeTranslations_RegisterQRCode_LoginNotPossible),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 3px solid black;
  border-radius: 100px;
  min-width: 100px;
  min-height: 100px;
  color: black;

  > small {
    margin-top: var(--spacing-xs);
  }
`

const FeedbackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  text-align: center;

  > h2 {
    margin: 0 var(--spacing-xl);
  }

  > img {
    margin-bottom: var(--spacing-lg);
  }
`

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const QRCodeButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await registerQrCode(value)
        setRequestStatus('success')
      } catch (error: any) {
        logError(new Error('Failed to register QR code'), error)
        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <FeedbackContainer className="fadeIn">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2>{intl.formatMessage(NativeTranslations_RegisterQRCode_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2>{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </FeedbackContainer>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_QR_CODE ? (
    <>
      <StyledIconButton onClick={toggleCamera}>
        <QrCodeIcon color="black" size="2.5rem" />
        <small>{intl.formatMessage(Header_QrCode)}</small>
      </StyledIconButton>

      <Drawer
        visible={showCamera}
        style={{ padding: 0 }}
        onClose={toggleCamera}
        ignoreMaxContentWidth
        autoFocusOnFirstInteractiveElement={false}
      >
        <>
          {requestStatus !== 'initial' && renderFeedback()}

          <CameraScanner
            description={intl.formatMessage(NativeTranslations_RegisterQRCode_Description)}
            onRead={onRead}
          />
        </>
      </Drawer>
    </>
  ) : null
}
