import React from 'react'
import styled from 'styled-components'
import ActivitiesSection from './ActivitiesSection'
import { RefundStatistics } from './RefundStatistics'
import { FindLocation } from '../home/favoriteLocation/FindLocation'
import { intl } from '../../lib'
import { ActivitiesPage_NoActivities, Common_Refunds } from '../../translations/messages'
import { SendDonationTaxReceipts } from './taxReceiptDrawer/SendDonationTaxReceipts'

const Container = styled.div`
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  -webkit-overflow-scrolling: touch;
`

const Title = styled.h1`
  margin-left: var(--spacing-sm);
`

const NoActivities = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-xl);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: var(--max-page-width);
  height: 100%;
  width: 100%;
  text-align: center;
`

const Header = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
`

type Props = {
  activities: RefundType[]
  now?: Date
}

type MonthWithActivitiesMap = {
  [monthName: string]: RefundType[]
}

function groupActivitiesByMonth(activities: RefundType[], now: Date): MonthWithActivitiesMap {
  const initialEmptyMap = {}
  const currentYear = now.getFullYear()

  return activities.reduce((mapByMonthAndYear, currentActivity) => {
    const activityDate = new Date(currentActivity.timestamp)
    const year = activityDate.getFullYear()
    const month = intl.formatDate(currentActivity.timestamp, { month: 'long' })
    const monthAndYear = currentYear === activityDate.getFullYear() ? month : `${month} ${year}`
    const existingActivitiesForMonth = mapByMonthAndYear[monthAndYear] || []
    const newActivities = [...existingActivitiesForMonth, currentActivity]

    // create a new map object containing the old contents in addition
    // to the new list of activites for this particular month,
    // mostly because mutating data is the root of all evil
    return { ...mapByMonthAndYear, [monthAndYear]: newActivities }
  }, initialEmptyMap)
}

function createSectionsByMonth(activities: RefundType[], now: Date): JSX.Element[] {
  const activitiesByMonth = groupActivitiesByMonth(activities, now)
  const allMonthNames = Object.keys(activitiesByMonth)
  return allMonthNames.map(monthName => (
    <ActivitiesSection title={monthName} activities={activitiesByMonth[monthName]} key={monthName} />
  ))
}

export const ActivitiesPage = ({ activities, now = new Date() }: Props) => {
  if (activities.length === 0) {
    return (
      <NoActivities>
        <div />

        <h1>{intl.formatMessage(ActivitiesPage_NoActivities)}</h1>

        <FindLocation />
      </NoActivities>
    )
  }

  return (
    <Container className="fadeIn">
      <Header>
        <Title>{intl.formatMessage(Common_Refunds)}</Title>
        <SendDonationTaxReceipts />
      </Header>

      <RefundStatistics />

      {createSectionsByMonth(activities, now)}
    </Container>
  )
}
