import React from 'react'
import { intl } from '../../../lib'
import { LinkButton } from '../../shared'
import { LocationIcon, ArrowRightIcon } from '../../icons'
import { FindLocation_ButtonText } from '../../../translations/messages'

export const FindLocation = () => (
  <div>
    <LinkButton to="/locations">
      <LocationIcon />
      {intl.formatMessage(FindLocation_ButtonText)}
      <ArrowRightIcon />
    </LinkButton>
  </div>
)
