import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Drawer } from './shared'
import {
  getNativeNotificationToken,
  initAPNsPush,
  initLegacyPush,
  initWebPush,
  usesiOSNativeWrapper
} from '../services'
import { theme, setItemInWebStorage, getItemFromWebStorage, intl, FIREBASE } from '../lib'
import {
  NotificationPromptDrawer_Title,
  NotificationPromptDrawer_Description,
  Common_Yes,
  Common_No
} from '../translations/messages'

const isE2E = navigator.userAgent.match(/Datadog/gi) || window.Cypress

const Container = styled.div`
  > p {
    margin: var(--spacing-lg) 0;
  }

  > button {
    width: 100%;
    margin-top: var(--spacing-md);
  }
`

export const NotificationPromptDrawer = () => {
  const [visible, setVisible] = useState(false)

  const acceptWebPush = () => {
    initWebPush()
    setVisible(false)
  }

  const declineWebPush = () => {
    setItemInWebStorage('HAS_DECLINED_WEB_PUSH', true)
    setVisible(false)
  }

  useEffect(() => {
    const init = async () => {
      if (usesiOSNativeWrapper()) {
        return initAPNsPush()
      }

      if (getNativeNotificationToken()) {
        return initLegacyPush()
      }

      if (window.Notification) {
        const hasDeclinedDrawerOption = await getItemFromWebStorage('HAS_DECLINED_WEB_PUSH')
        const hasDeclinedBrowserPrompt = Notification?.permission === 'denied'
        const hasAcceptedBrowserPrompt = Notification?.permission === 'granted'

        if (hasDeclinedBrowserPrompt || hasDeclinedDrawerOption) return
        if (hasAcceptedBrowserPrompt) initWebPush()
        else setVisible(true)
      }
    }

    !isE2E && FIREBASE.auth.currentUser && init()
  }, [])

  return (
    <Drawer onClose={() => setVisible(false)} visible={visible}>
      <Container>
        <h1>{intl.formatMessage(NotificationPromptDrawer_Title)}</h1>

        <p>{intl.formatMessage(NotificationPromptDrawer_Description, { appName: theme.name })}</p>

        <Button background="colored" onClick={acceptWebPush}>
          {intl.formatMessage(Common_Yes)}
        </Button>

        <Button onClick={declineWebPush}>{intl.formatMessage(Common_No)}</Button>
      </Container>
    </Drawer>
  )
}
