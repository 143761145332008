import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider, Navigate, Outlet, useLocation } from 'react-router-dom'
import { ContactUs } from './components/contact/ContactUs'
import { LocationsPage, LocationDetails } from './components/locations'
import { PayoutBrokerPage } from './components/brokers/PayoutBrokerPage'
import { ActivitiesPageContainer } from './components/activities/ActivitiesPageContainer'
import { HomePage } from './components/home/HomePage'
import { PayoutSettingsPage } from './components/payout/PayoutSettingsPage'
import { VoucherPage } from './components/vouchers/VoucherPage'
import { AchievementsPage, AllAchievements, AchievementDetailsPage, MyAchievements } from './components/achievements'
import { AccountPage } from './components/account/AccountPage'
import { CharitiesPage, CampaignInfo, MyDonationsPage, BrowseCharitiesPage } from './components/charities'
import { DeleteAccount } from './components/delete/DeleteAccount'
import { Loading } from './components/shared'
import { RouterErrorBoundary } from './components/ErrorPage'
import { theme } from './lib'
import {
  WelcomePage,
  MailSentPage,
  PasswordLoginPage,
  ConfirmEmailPage,
  EmailMagicLinkPage,
  RegisterPage,
  SignInPage,
  LoginLayout
} from './components/login'
import { notifyPathChange } from './services'

const NavigateListener = () => {
  const location = useLocation()

  useEffect(() => {
    document.title = `${theme.name} - ${location.pathname}`
    notifyPathChange(location.pathname)
  }, [location.pathname])

  return <Outlet />
}

const loginRoutes = [
  {
    path: 'login',
    element: <LoginLayout />,
    children: [
      {
        index: true,
        element: <WelcomePage />
      },
      {
        path: 'login-link-sent',
        element: <MailSentPage />
      },
      {
        path: 'confirm-email',
        element: <ConfirmEmailPage />
      },
      {
        path: 'email-link',
        element: <EmailMagicLinkPage />
      },
      {
        path: 'password',
        element: <PasswordLoginPage />
      },
      {
        path: 'register',
        element: <RegisterPage />
      },
      {
        path: 'signin',
        element: <SignInPage />
      }
    ]
  }
]

const unauthenticatedRoutes = [
  {
    path: 'contact',
    element: <ContactUs />
  },
  {
    path: 'broker',
    children: [
      {
        path: '*',
        element: <PayoutBrokerPage />
      }
    ]
  },
  {
    path: 'locations',
    element: <LocationsPage />,
    children: [
      {
        path: ':openedLocationId',
        element: <LocationDetails />
      }
    ]
  }
]

const authRoutes = [
  {
    path: 'activity',
    element: <ActivitiesPageContainer />
  },
  {
    path: 'payoutsettings',
    element: <PayoutSettingsPage />
  },
  {
    path: 'vouchers',
    element: <VoucherPage />
  },
  {
    path: 'account',
    element: <AccountPage />
  },
  {
    path: 'delete',
    element: <DeleteAccount />
  },
  {
    path: 'charities',
    element: <CharitiesPage />,
    children: [
      {
        path: ':campaignId',
        element: <CampaignInfo />
      },
      {
        path: 'donations',
        element: <MyDonationsPage />
      },
      {
        index: true,
        element: <BrowseCharitiesPage />
      }
    ]
  },
  {
    path: 'achievements',
    element: <AchievementsPage />,
    id: 'achievements-root',
    children: [
      {
        path: 'all',
        element: <AllAchievements />
      },
      {
        path: ':achievementName',
        element: <AchievementDetailsPage />
      },
      {
        index: true,
        element: <MyAchievements />
      }
    ]
  }
]

export const UnAuthenticatedRouter = () => (
  <RouterProvider
    router={createBrowserRouter([
      {
        element: <NavigateListener />,
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            path: '*',
            element: <Navigate to={`/login${window.location.search}`} replace />
          },
          ...loginRoutes,
          ...unauthenticatedRoutes
        ]
      }
    ])}
    fallbackElement={<Loading className="centerAbsolute" />}
  />
)

export const AuthenticatedRouter = () => (
  <RouterProvider
    router={createBrowserRouter([
      {
        element: <NavigateListener />,
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            path: '*',
            element: <HomePage />,
            id: 'home-root'
          },
          ...unauthenticatedRoutes,
          ...authRoutes
        ]
      }
    ])}
    fallbackElement={<Loading className="centerAbsolute" />}
  />
)
