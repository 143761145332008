import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Drawer, Loading } from '../../shared/common'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../../hooks'
import { intl } from '../../../lib'
import {
  Common_CharityCampaign_PayoutDisclaimer,
  SetupCampaignPayout_SetAsPayoutConfirm,
  SetupCampaignPayout_PayoutIsActive,
  SetupCampaignPayout_SetAsPayout,
  Common_Cancel,
  Common_EditPayout
} from '../../../translations/messages'

const DrawerContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: var(--spacing-xs);
  }
  button:first-child {
    margin-left: var(--spacing-xs);
  }
`

interface Props {
  campaignName: string
  charityDisplayName: string
}

export const SetupCampaignPayout = ({ campaignName, charityDisplayName }: Props) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const updateQuery = usePayoutMethodMutation()
  const [showDrawer, setShowDrawer] = useState(false)
  const isActivePayout = payoutMethods?.some(method => method.active && method.recipient === campaignName)

  useEffect(() => {
    if (updateQuery.isSuccess) {
      setShowDrawer(false)
    }
  }, [updateQuery.isSuccess])

  return (
    <>
      {payoutMethods && (
        <div className="fadeIn">
          {isActivePayout ? (
            <>
              <small>
                <b style={{ color: 'var(--colors-green)' }}>
                  {intl.formatMessage(SetupCampaignPayout_PayoutIsActive, { charityName: charityDisplayName })}
                </b>
              </small>

              <Button onClick={() => navigate('/payoutsettings')} style={{ marginTop: 'var(--spacing-lg)' }}>
                {intl.formatMessage(Common_EditPayout)}
              </Button>
            </>
          ) : (
            <Button onClick={() => setShowDrawer(true)} background="colored">
              {intl.formatMessage(SetupCampaignPayout_SetAsPayout)}
            </Button>
          )}
        </div>
      )}

      <Drawer onClose={() => setShowDrawer(false)} visible={showDrawer}>
        <DrawerContainer>
          <h1>{intl.formatMessage(Common_EditPayout)}</h1>

          <p>{intl.formatMessage(Common_CharityCampaign_PayoutDisclaimer)}</p>

          <p>{intl.formatMessage(SetupCampaignPayout_SetAsPayoutConfirm)}</p>

          <ButtonContainer>
            <Button onClick={() => setShowDrawer(false)} type="button" border>
              {intl.formatMessage(Common_Cancel)}
            </Button>

            <Button
              background="colored"
              aria-disabled={updateQuery.isPending}
              disabled={updateQuery.isPending}
              onClick={() => updateQuery.mutate({ type: 'DONATION', method: 'POST', recipient: campaignId })}
            >
              {updateQuery.isPending ? <Loading /> : intl.formatMessage(SetupCampaignPayout_SetAsPayout)}
            </Button>
          </ButtonContainer>
        </DrawerContainer>
      </Drawer>
    </>
  )
}
