import React from 'react'
import { usePayoutMethodMutation } from '../../../hooks'
import { intl } from '../../../lib'
import { Button, Loading } from '../../shared'
import {
  PayoutSettings_Voucher_Description,
  PayoutSettings_Voucher_Disclaimer,
  Common_EditPayout,
  Common_Enable
} from '../../../translations/messages'

export const Voucher = () => {
  const updateQuery = usePayoutMethodMutation()

  return (
    <>
      <h1>{intl.formatMessage(Common_EditPayout)}</h1>

      <span>{intl.formatMessage(PayoutSettings_Voucher_Description)}</span>

      <span>{intl.formatMessage(PayoutSettings_Voucher_Disclaimer)}</span>

      <Button
        background="colored"
        disabled={updateQuery.isPending}
        onClick={() => updateQuery.mutate({ type: 'E_VOUCHER', method: 'PUT' })}
      >
        {updateQuery.isPending ? <Loading /> : intl.formatMessage(Common_Enable)}
      </Button>
    </>
  )
}
