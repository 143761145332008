import React from 'react'
import { Card, Button } from '../../shared'
import styled from 'styled-components'
import { getNativeDeviceInfo } from '../../../services'

const StyledCard = styled(Card)`
  padding: var(--spacing-md);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--colors-yellow);

  > *:not(:first-child) {
    margin-top: var(--spacing-md);
  }
`

export const NswInMytomraBanner = () => {
  const getAppUrl = () => {
    const OS = getNativeDeviceInfo()?.OS
    return OS === 'IOS'
      ? 'https://itunes.apple.com/au/app/id1574701034'
      : 'http://play.google.com/store/apps/details?id=com.tomra.digital.returnandearn'
  }

  return (
    <StyledCard>
      <h2>Please note!</h2>
      <p>The myTOMRA app can not be used in New South Wales anymore. Please download Return and Earn here:</p>
      <Button onClick={() => window.open(getAppUrl())}>Download</Button>
    </StyledCard>
  )
}
