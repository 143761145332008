import {
  BrowseCharitiesPage_Alphabetical,
  BrowseCharitiesPage_Latest,
  BrowseCharitiesPage_MostMoneyRaised,
  BrowseCharitiesPage_MostPopular,
  BrowseCharitiesPage_Oldest,
  BrowseCharitiesPage_Trending,
  Common_AnimalWelfare,
  Common_ArtsAndCulture,
  Common_CommunityDevelopment,
  Common_DisabilityServices,
  Common_DomesticAndFamily,
  Common_DroughtAndBushfireRelief,
  Common_Education,
  Common_Environment,
  Common_Health,
  Common_HumanAndCivilRights,
  Common_HumanServices,
  Common_InternationalAid,
  Common_Religion,
  Common_ResearchAndPublicPolicy,
  Common_Sports,
  Common_VeteransServices
} from '../../../translations/messages'

export const benefitToTranslationMap = {
  ANIMAL_WELFARE: Common_AnimalWelfare,
  ARTS_AND_CULTURE: Common_ArtsAndCulture,
  COMMUNITY_DEVELOPMENT: Common_CommunityDevelopment,
  DISABILITY_SERVICES: Common_DisabilityServices,
  DOMESTIC_AND_FAMILY: Common_DomesticAndFamily,
  DROUGHT_AND_BUSHFIRE_RELIEF: Common_DroughtAndBushfireRelief,
  EDUCATION: Common_Education,
  ENVIRONMENT: Common_Environment,
  HEALTH: Common_Health,
  HUMAN_AND_CIVIL_RIGHTS: Common_HumanAndCivilRights,
  HUMAN_SERVICES: Common_HumanServices,
  INTERNATIONAL_AID: Common_InternationalAid,
  RELIGION: Common_Religion,
  RESEARCH_AND_PUBLIC_POLICY: Common_ResearchAndPublicPolicy,
  SPORTS: Common_Sports,
  VETERANS_SERVICES: Common_VeteransServices
}

export const orderToTranslationMap = {
  LATEST: BrowseCharitiesPage_Latest,
  OLDEST: BrowseCharitiesPage_Oldest,
  TRENDING: BrowseCharitiesPage_Trending,
  MOST_POPULAR: BrowseCharitiesPage_MostPopular,
  ALPHABETICAL: BrowseCharitiesPage_Alphabetical,
  MOST_MONEY_RAISED: BrowseCharitiesPage_MostMoneyRaised
}
