import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const BagIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 15.27 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'black'
    }}
  >
    <path d="M2.49,24A2.51,2.51,0,0,1,0,21.49V17.54a7.91,7.91,0,0,1,4-6.88.92.92,0,0,0,.34-.34A2,2,0,0,1,6,9.35h3.3a1.91,1.91,0,0,1,1.65,1,1,1,0,0,0,.34.33h0a7.93,7.93,0,0,1,4,6.89v3.95A2.48,2.48,0,0,1,12.83,24ZM9.28,10.85H6a.47.47,0,0,0-.37.22,2.33,2.33,0,0,1-.9.89A6.45,6.45,0,0,0,1.5,17.54v3.95a1,1,0,0,0,1,1H12.81a1,1,0,0,0,1-1v-4A6.41,6.41,0,0,0,10.54,12a2.48,2.48,0,0,1-.9-.89A.41.41,0,0,0,9.28,10.85Z" />
    <path d="M10.12,7.64H5.4l-.21-.19A8.43,8.43,0,0,1,2.38,1.87,1.73,1.73,0,0,1,3.29.25c1-.58,2.36-.14,4,1.3A1.64,1.64,0,0,0,8.33,2h0a9.36,9.36,0,0,0,1.41-.1,1.69,1.69,0,0,1,1.71.31c.82.82.47,2.4-1.13,5.11ZM6,6.14H9.25c1.1-1.94,1.15-2.69,1.13-2.89h-.13l-.05,0-.14,0a10.85,10.85,0,0,1-1.71.13h0a3.12,3.12,0,0,1-2.08-.78C5.06,1.59,4.32,1.39,4,1.56a.25.25,0,0,0-.13.23A6.85,6.85,0,0,0,6,6.14Z" />
  </svg>
)
