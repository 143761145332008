import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const WarningIcon = (props: Props) => (
  <svg
    height={props.size || '0.87rem'}
    viewBox="0 0 24 24"
    width={props.size || '0.87rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M12,18.75a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,18.75h0" />
    <line x1="12" y1="15.75" x2="12" y2="8.25" />
    <path d="M13.621,1.76a1.806,1.806,0,0,0-3.242,0L.906,21.058A1.521,1.521,0,0,0,2.272,23.25H21.728a1.521,1.521,0,0,0,1.366-2.192Z" />
  </svg>
)
