import { API_HOST, APP_NAME, enrichedAuthenticatedFetchData } from '../lib'

export function fetchCharityCampaign(campaignId, signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/charities/browse/v1/campaign/${campaignId}`, { signal }).run()
}

export function fetchTotalDonations(signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(`${API_HOST}/charities/v1/user-data`, { signal })
    .run()
    .then(({ totalDonationSum }) => totalDonationSum)
}

export function fetchCharityUserData(pageEnd: number | null, signal?: AbortSignal) {
  return enrichedAuthenticatedFetchData(
    `${API_HOST}/charities/v1/user-data${pageEnd ? `/donations?pageStart=${pageEnd}` : ''}`,
    {
      signal
    }
  ).run()
}

export function searchCharityCampaigns(
  text: string,
  benefit: CharityBenefitType | '',
  order: CharitySearchOrderType | '',
  pageEnd: number | null,
  signal?: AbortSignal
) {
  let url = `${API_HOST}/charities/browse/v1/search?text=${text}`

  if (order) {
    url += `&order=${order}`
  }

  if (benefit) {
    url += `&benefit=${benefit}`
  }

  if (pageEnd) {
    url += `&pageStart=${pageEnd}`
  }

  return enrichedAuthenticatedFetchData(url, { signal }).run()
}

export function sendTaxReceipts(from: string, to: string, email: string) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Australia/Sydney'

  // Make sure time is set to start and end of day UTC
  const fromDate = new Date(`${from}T00:00:00`).toISOString()
  const toDate = new Date(`${to}T23:59:59`).toISOString()

  return enrichedAuthenticatedFetchData(
    `${API_HOST}/charities/v1/user-data/tax-receipt?from=${fromDate}&to=${toDate}&email=${email}&timezone=${timezone}&nativeAppName=${APP_NAME}`
  ).run()
}
