import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAchievementsQuery } from '../../hooks'
import { Achievement } from './common'
import { FeedbackMessage, Loading } from '../shared'
import { intl } from '../../lib'
import { AchievementDetailsPage_InvalidAchievement, AchievementDetailsPage_Levels } from '../../translations/messages'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const LatestAchievementContainer = styled.div`
  padding: var(--spacing-xl);
  background-color: var(--colors-secondary-light);

  > div {
    margin: 0 auto;
    max-width: var(--max-page-width);
  }
`

const AchievementLevelsContainer = styled.div`
  padding: var(--spacing-xl);
  margin: 0 auto;
  max-width: var(--max-page-width);

  > *:not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }
`

export const AchievementDetailsPage = () => {
  const { achievementName } = useParams()
  const { data } = useAchievementsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const achievement = data.allAchievements.find(achievement => achievement.achievementName === achievementName)

  if (!achievement) {
    return (
      <FeedbackMessage className="centerAbsolute fadeIn">
        {intl.formatMessage(AchievementDetailsPage_InvalidAchievement)}
      </FeedbackMessage>
    )
  }

  // The last finished achievement is always current level minus one
  // except when current level is 0 (i.e achievement not started)
  const lastFinishedAchievementLevel = achievement.currentLevel > 0 ? achievement.currentLevel - 1 : 0

  return (
    <Container className="fadeIn">
      <LatestAchievementContainer>
        <Achievement achievement={{ ...achievement, currentLevel: lastFinishedAchievementLevel }} large />
      </LatestAchievementContainer>

      <AchievementLevelsContainer>
        <p>
          <b>{intl.formatMessage(AchievementDetailsPage_Levels)}</b>
        </p>

        {achievement.levels.map((level, index) => {
          const currentLevel = index
          const progress = index > achievement.currentLevel ? 0 : achievement.progress
          const showUnknownBadge = progress === 0 && currentLevel !== 0

          return (
            <div key={level}>
              <Achievement
                showUnknownBadge={showUnknownBadge}
                achievement={{
                  ...achievement,
                  currentLevel,
                  progress
                }}
              />
            </div>
          )
        })}
      </AchievementLevelsContainer>
    </Container>
  )
}
