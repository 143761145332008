import styled from 'styled-components'

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  width: 1.4rem;
  height: 1.4rem;
  min-width: 1.4rem;
  min-height: 1.4rem;
  border-radius: 0.125rem;
  border: 2px solid black;
  background-color: transparent;
  cursor: pointer;
  appearance: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:checked:after {
    content: '';
    position: absolute;
    width: 0.6rem;
    height: 0.4rem;
    background-color: transparent;
    top: 0.3rem;
    left: 0.25rem;
    border: 2px solid black;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
`
