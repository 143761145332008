import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const EmailSearchIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="15.75" cy="15.75" r="5.25" />
    <line x1="23.25" y1="23.25" x2="19.462" y2="19.462" />
    <path d="M7.5,15.75H2.25a1.5,1.5,0,0,1-1.5-1.5v-12A1.5,1.5,0,0,1,2.25.75h18a1.5,1.5,0,0,1,1.5,1.5v7.5" />
    <path d="M21.411,1.3,13.267,7.564a3.308,3.308,0,0,1-4.034,0L1.089,1.3" />
  </svg>
)
