import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { updateProfile } from 'firebase/auth'
import { pushMessage } from '../AppMessages'
import { updateUser } from '../../services'
import { intl, theme, FIREBASE } from '../../lib'
import { LanguagePicker } from '../login/LanguagePicker'
import { BackButton, Button, CountryStatePicker, Drawer, IconButton, InfoTooltip, Loading, TextField } from '../shared'
import { EarthIcon, TrashcanIcon, LockIcon } from '../icons'
import { NewsLetterSetting } from './NewsLetterSetting'
import { ChangePassword } from './ChangePassword'
import {
  AccountPage_ChangeLocation,
  Auth_EmailConditionsNotMet,
  Auth_FirstNameDisclaimer,
  Auth_Name,
  Common_DeleteAccount,
  Common_EditProfile,
  Common_Update,
  Common_Email,
  Common_UpdateFailed,
  Common_ChangePassword,
  UpdateEmail_CheckEmailUpdate
} from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  max-width: var(--max-page-width);
  margin: 0 auto;

  > h1 {
    margin: var(--spacing-xl) 0;
  }
`

const TextFieldsContainer = styled.form`
  margin: var(--spacing-xl) 0;
  width: 100%;
`

const SubSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  > div:first-child {
    border-top: 1px solid black;
  }

  > div {
    border-bottom: 1px solid black;
  }
`

const SubSetting = styled.div`
  padding: var(--spacing-md) 0;
`

const SaveButton = styled(Button)`
  margin-top: var(--spacing-sm);
`

const StyledIconButton = styled(IconButton)`
  > svg {
    margin-right: var(--spacing-md);
  }
`

const InfoTooltipContainer = styled.div`
  position: absolute;
  top: 25%;
  right: 0;
`

export const AccountPage = () => {
  const currentDisplayName = FIREBASE.auth.currentUser?.displayName || ''
  const currentEmail = FIREBASE.auth.currentUser?.email || ''
  const [newDisplayName, setNewDisplayName] = useState(currentDisplayName)
  const [newEmail, setNewEmail] = useState(currentEmail)
  const [newEmailValid, setNewEmailValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showSelectLocationDrawer, setShowSelectLocationDrawer] = useState(false)
  const [showPasswordDrawer, setShowPasswordDrawer] = useState(false)
  const [hasPasswordLoginEnabled, setHasPasswordLoginEnabled] = useState(false)
  const emailHasChanged = newEmail !== currentEmail && newEmailValid
  const displayNameHasChanged = currentDisplayName !== newDisplayName

  useEffect(() => {
    FIREBASE.auth.currentUser?.getIdTokenResult(true).then(token => {
      setHasPasswordLoginEnabled(token.claims.passwordLoginEnabled)
      setNewEmail(FIREBASE.auth.currentUser?.email || '')
    })
  }, [])

  const _performProfileUpdateRequest = async (evt: any) => {
    evt.preventDefault()

    const payload: {
      firstName: string
      email?: string
    } = {
      firstName: newDisplayName,
      email: newEmail
    }

    // Don't want to include email in payload if not changed, as that will trigger a verify email
    if (newEmail === currentEmail) {
      delete payload.email
    }

    try {
      setIsLoading(true)
      await updateUser(payload).run()

      // updateUser() above also does this, but FIREBASE.currentUser wont update until next page load, so we do it here
      await updateProfile(FIREBASE.auth.currentUser!, { displayName: payload.firstName })

      if (newEmail !== currentEmail) {
        pushMessage({
          formattedMessage: intl.formatMessage(UpdateEmail_CheckEmailUpdate),
          type: 'success',
          ttl: 5000
        })
      }
    } catch (error: any) {
      pushMessage({
        formattedMessage: intl.formatMessage(Common_UpdateFailed),
        type: 'danger',
        ttl: 5000
      })

      logError(new Error('Failed to update user'), error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container className="fadeIn">
      <BackButton to="/" />

      <Drawer onClose={() => setShowSelectLocationDrawer(!showSelectLocationDrawer)} visible={showSelectLocationDrawer}>
        <CountryStatePicker />
      </Drawer>

      <Drawer onClose={() => setShowPasswordDrawer(!showPasswordDrawer)} visible={showPasswordDrawer}>
        <ChangePassword email={newEmail} closeDrawer={() => setShowPasswordDrawer(!showPasswordDrawer)} />
      </Drawer>

      <h1>{intl.formatMessage(Common_EditProfile)}</h1>

      <TextFieldsContainer onSubmit={_performProfileUpdateRequest}>
        <TextField
          onChange={value => setNewDisplayName(value)}
          value={newDisplayName}
          valid
          label={intl.formatMessage(Auth_Name)}
          ariaLabel={intl.formatMessage(Auth_Name)}
        >
          <InfoTooltipContainer>
            <InfoTooltip content={intl.formatMessage(Auth_FirstNameDisclaimer, { appName: theme.name })} />
          </InfoTooltipContainer>
        </TextField>

        <TextField
          onChange={(value, valid) => {
            setNewEmail(value)
            setNewEmailValid(valid)
          }}
          value={newEmail}
          valid={newEmailValid}
          label={intl.formatMessage(Common_Email)}
          errorMsg={intl.formatMessage(Auth_EmailConditionsNotMet)}
          type="email"
          pattern="^[^\s@]+@[^\s@]+\.[^\s@.,]+$"
          ignoreWhitespace
        />

        <SaveButton type="submit" disabled={!(displayNameHasChanged || emailHasChanged) || isLoading}>
          {isLoading ? (
            <span data-testid="loading">
              <Loading />
            </span>
          ) : (
            intl.formatMessage(Common_Update)
          )}
        </SaveButton>
      </TextFieldsContainer>

      <SubSettingsContainer>
        {hasPasswordLoginEnabled && (
          <SubSetting>
            <StyledIconButton onClick={() => setShowPasswordDrawer(true)}>
              <LockIcon />
              {intl.formatMessage(Common_ChangePassword)}
            </StyledIconButton>
          </SubSetting>
        )}

        {theme.showCountryPicker && (
          <SubSetting>
            <StyledIconButton onClick={() => setShowSelectLocationDrawer(true)}>
              <EarthIcon />
              {intl.formatMessage(AccountPage_ChangeLocation)}
            </StyledIconButton>
          </SubSetting>
        )}

        <SubSetting>
          <LanguagePicker reverseIconAndLabelOrder />
        </SubSetting>

        <SubSetting className="animated fadeIn medium">
          <NewsLetterSetting />
        </SubSetting>

        <SubSetting>
          <Link to="/delete">
            <StyledIconButton>
              <TrashcanIcon />
              {intl.formatMessage(Common_DeleteAccount)}
            </StyledIconButton>
          </Link>
        </SubSetting>
      </SubSettingsContainer>
    </Container>
  )
}
