import React from 'react'
import { usePayoutMethodMutation } from '../../../../hooks'
import { intl } from '../../../../lib'
import { Button, Loading } from '../../../shared'
import {
  PayoutSettings_DeleteBankTransferDescription,
  PayoutSettings_BankTransfer_Disclaimer_Three,
  PayoutSettings_DeletePayout,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
}

export const Existing = ({ savedConfiguration, active }: Props) => {
  const updateQuery = usePayoutMethodMutation()

  return (
    <>
      {!active ? (
        <>
          <h1>{intl.formatMessage(Common_EditPayout)}</h1>

          <p>{intl.formatMessage(PayoutSettings_BankTransfer_Disclaimer_Three)}</p>

          <Button
            background="colored"
            aria-disabled={updateQuery.isPending}
            disabled={updateQuery.isPending}
            onClick={() => updateQuery.mutate({ type: 'BANK_TRANSFER', method: 'PUT' })}
          >
            {updateQuery.isPending ? (
              <Loading />
            ) : (
              <span>
                {intl.formatMessage(Common_UseSavedAccount)}
                <p>
                  <small>({savedConfiguration?.maskedAccountNumber})</small>
                </p>
              </span>
            )}
          </Button>
        </>
      ) : (
        <>
          <h1>{intl.formatMessage(PayoutSettings_DeletePayout)}</h1>

          <p>{intl.formatMessage(PayoutSettings_DeleteBankTransferDescription)}</p>
        </>
      )}

      <Button
        aria-disabled={updateQuery.isPending}
        disabled={updateQuery.isPending}
        onClick={() => updateQuery.mutate({ type: 'BANK_TRANSFER', method: 'DELETE' })}
        name="payoutType"
        value="BANK_TRANSFER"
        border
      >
        {updateQuery.isPending ? <Loading /> : intl.formatMessage(Common_DeleteSaved)}
      </Button>
    </>
  )
}
