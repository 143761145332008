import React from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import { Link, useLocation, Outlet } from 'react-router-dom'
import { Tab, TabContainer, BackButton } from '../shared'
import { Common_Charities, Common_BrowseCharities, CharitiesPage_MyDonations } from '../../translations/messages'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--colors-tertiary);
`

const Header = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-lg);

  > h1 {
    margin-top: 45px;
    margin-bottom: var(--spacing-lg);
  }

  > div {
    justify-content: space-around;
  }
`

export const CharitiesPage = () => {
  const { pathname } = useLocation()

  return (
    <>
      <BackButton to={pathname.match(/charities\/.{15,}/gi) ? '.' : '..'} />

      {!!pathname.match(/charities$|charities\/donations$/gi) && (
        <HeaderContainer>
          <Header className="fadeIn">
            <h1>{intl.formatMessage(Common_Charities)}</h1>

            <TabContainer>
              <Link to="donations">
                <Tab active={window.location.pathname === '/charities/donations'}>
                  {intl.formatMessage(CharitiesPage_MyDonations)}
                </Tab>
              </Link>
              <Link to=".">
                <Tab active={window.location.pathname === '/charities'}>
                  {intl.formatMessage(Common_BrowseCharities)}
                </Tab>
              </Link>
            </TabContainer>
          </Header>
        </HeaderContainer>
      )}

      <Outlet />
    </>
  )
}
