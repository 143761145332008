import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const LocationIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 19.82 23.81"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: props.color || 'black'
    }}
  >
    <path d="M9.91,23.81,3,16.91a9.79,9.79,0,0,1-3-7,9.91,9.91,0,0,1,19.82,0h0a9.83,9.83,0,0,1-3,7Zm0-22.31A8.42,8.42,0,0,0,1.5,9.91,8.28,8.28,0,0,0,4,15.84l5.9,5.85,5.9-5.84a8.32,8.32,0,0,0,2.51-5.94h0A8.42,8.42,0,0,0,9.91,1.5Z" />
    <circle cx="9.91" cy="10" r="3.93" />
  </svg>
)
