import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, Outlet } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { useProfileQuery, useBadgesQuery, usePayoutMethodMutation } from '../../hooks/query-hooks'
import { Header } from './header/Header'
import { ContainerScannerButton } from './ContainerScannerButton'
import { FindLocation } from './favoriteLocation/FindLocation'
import { MyLocation } from './favoriteLocation/MyLocation'
import { AustraliaCampaignCard } from './temporary/AustraliaCampaignCard'
import { NswInMytomraBanner } from './temporary/NswInMytomraBanner'
import { ListButton, Loading } from '../shared'
import { hasWebCameraScannerSupport } from '../../services'
import { pushMessage } from '../AppMessages'
import { VoucherIcon, RecycleBottleIcon, StarIcon, ArrowRightIcon, HeartIcon } from '../icons'
import {
  getItemFromWebStorage,
  countryStateMap,
  intl,
  getQueryParameterByName,
  VIEWER_COUNTRY_STATE,
  APP_NAME
} from '../../lib'
import {
  Common_Achievements,
  Common_Refunds,
  Common_Vouchers,
  Common_Charities,
  Common_UpdatePayoutFailure,
  HomePage_Other
} from '../../translations/messages'
import { ScheduledDeletePage } from './ScheduledDeletePage'

const Container = styled.div`
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    max-width: var(--max-page-width);
    margin-bottom: var(--spacing-lg);
  }
`

const SmallHeader = styled.small`
  display: block;
  margin-left: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
`

export const HomePage = () => {
  const navigate = useNavigate()
  const { mutate } = usePayoutMethodMutation()
  const { data: profile } = useProfileQuery()
  const { data: badges } = useBadgesQuery()
  const [favoriteLocationId, setFavoriteLocationId] = useState('')
  const { features } = countryStateMap[VIEWER_COUNTRY_STATE]

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  useEffect(() => {
    try {
      const encodedpayload = getQueryParameterByName('encodedpayload')

      if (!encodedpayload) {
        return
      }

      const { type, recipient, errorMessage } = JSON.parse(atob(encodedpayload))

      if (errorMessage) {
        throw new Error(errorMessage)
      }

      mutate({ type, recipient, method: 'POST' })
      window.history.replaceState({}, document.title, window.location.pathname)
    } catch (error: any) {
      pushMessage({ formattedMessage: intl.formatMessage(Common_UpdatePayoutFailure), type: 'danger', ttl: 5000 })
      logError(new Error('Failed to update payout'), error)
    }
  }, [mutate])

  if (!(profile && badges)) {
    return <Loading className="centerAbsolute" />
  }

  if (profile.scheduledDeleteAt) {
    return <ScheduledDeletePage scheduledDeleteAt={profile.scheduledDeleteAt} />
  }

  return (
    <>
      <Header barcode={profile.barcode} />

      <Container>
        {VIEWER_COUNTRY_STATE.includes('AU-') && APP_NAME === 'MYTOMRA' && <NswInMytomraBanner />}

        {favoriteLocationId ? <MyLocation favoriteLocationId={favoriteLocationId} /> : <FindLocation />}

        <AustraliaCampaignCard />

        <div>
          <SmallHeader>
            <b>{intl.formatMessage(HomePage_Other)}</b>
          </SmallHeader>

          {features.HAS_CHARITY && (
            <ListButton onClick={() => navigate('charities')}>
              <div>
                <HeartIcon />
                <span>{intl.formatMessage(Common_Charities)}</span>
              </div>
              <ArrowRightIcon />
            </ListButton>
          )}

          <ListButton
            label={badges.numberOfUnredeemedVouchers || ''}
            labelColor="var(--colors-green)"
            onClick={() => navigate('vouchers')}
          >
            <div>
              <VoucherIcon />
              <span>{intl.formatMessage(Common_Vouchers)}</span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          <ListButton
            onClick={() => navigate('activity')}
            label={badges.hasPayoutError ? '1' : ''}
            labelColor="var(--colors-red)"
          >
            <div>
              <RecycleBottleIcon />
              <span>{intl.formatMessage(Common_Refunds)}</span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          <ListButton onClick={() => navigate('achievements')}>
            <div>
              <StarIcon />
              <span>{intl.formatMessage(Common_Achievements)}</span>
            </div>
            <ArrowRightIcon />
          </ListButton>

          {hasWebCameraScannerSupport() && <ContainerScannerButton countryStateFeatures={features} />}
        </div>
      </Container>

      <Outlet />
    </>
  )
}
