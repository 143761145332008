import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.span`
  display: inline-block;
  width: 2.25rem;
  height: 2.25rem;
  background-color: transparent;
  border-radius: 50%;
  border: 0.3rem solid var(--colors-grey);
  border-top-color: grey;
  animation: ${rotate} 1s cubic-bezier(0.68, 0.31, 0.28, 0.63) 0s infinite;
`

export const Loading = (props: any) => (
  <span {...props}>
    <Spinner />
  </span>
)
