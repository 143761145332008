import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { Button, TextField } from '../shared'
import { EmailSearchIcon } from '../icons'
import { intl, emailRegex, setItemInWebStorage } from '../../lib'
import { Auth_EnterEmailToComplete, Auth_AlmostThere, Common_Email, Common_Continue } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 85vh;

  > form {
    width: 100%;

    > button {
      margin-top: var(--spacing-lg);
    }
  }
`

export const ConfirmEmailPage = () => {
  const navigate = useNavigate()
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)

  const saveAndContinue = async (evt?: React.FormEvent<HTMLFormElement>) => {
    try {
      evt?.preventDefault()
      await setItemInWebStorage('emailForSignIn', emailValue)
      navigate('/login/email-link' + window.location.search, { replace: true })
    } catch (error: any) {
      logError(new Error('Failed to save email and continue'), error)
    }
  }

  return (
    <Container>
      <EmailSearchIcon size="5rem" />

      <h1>{intl.formatMessage(Auth_AlmostThere)}</h1>

      <p>{intl.formatMessage(Auth_EnterEmailToComplete)}</p>

      <form onSubmit={saveAndContinue}>
        <TextField
          onChange={(value, valid) => {
            setEmailValue(value.toLowerCase())
            setEmailValid(valid)
          }}
          value={emailValue}
          valid={emailValid}
          label={intl.formatMessage(Common_Email)}
          ariaLabel={intl.formatMessage(Common_Email)}
          autoComplete="email"
          type="email"
          pattern={emailRegex}
          required
        />

        <Button type="submit" disabled={!emailValid} aria-disabled={emailValid}>
          {intl.formatMessage(Common_Continue)}
        </Button>
      </form>
    </Container>
  )
}
