import { logError } from '@tomra/datadog-browser-logging'
import { insertIntoFirestore } from './firestore-service'
import { getNativeDeviceInfo, getNativeNotificationToken } from './react-native-service'
import {
  API_HOST,
  APP_NAME,
  WEB_PUSH_PUBLIC_KEY,
  enrichedAuthenticatedFetch,
  enrichedAuthenticatedFetchData
} from '../lib'

let webPushSubscription

const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const initLegacyPush = async () => {
  const OS = getNativeDeviceInfo()?.OS
  const notificationToken = getNativeNotificationToken()

  try {
    if (OS) {
      await enrichedAuthenticatedFetch(
        `${API_HOST}/user-notifications/v2.0/mytomra/device/${notificationToken}/register-${OS.toLowerCase()}-device?nativeAppName=${APP_NAME}`,
        { method: 'POST' }
      ).run()
    }
  } catch (error: any) {
    logError(new Error('Failed to register notification device'), error)
  }
}

export const initAPNsPush = async () => {
  try {
    const notificationToken = getNativeNotificationToken()
    if (notificationToken) await insertIntoFirestore({ devices: [notificationToken] })
  } catch (error: any) {
    logError(new Error('Failed to register notification device'), error)
  }
}

export const initWebPush = async (isRetryAttempt: boolean = false) => {
  try {
    const registration = await navigator.serviceWorker.register('/web-push-sw.js')

    await navigator.serviceWorker.ready

    await Notification.requestPermission().then(permission => {
      if (permission === 'granted') return true
      else throw new Error('Notification permission denied')
    })

    webPushSubscription = await registration.pushManager.getSubscription()

    if (!webPushSubscription) {
      webPushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
      })
    }

    if (webPushSubscription) {
      await insertIntoFirestore({ devices: [btoa(JSON.stringify(webPushSubscription))] })
    }
  } catch (error: any) {
    if (isRetryAttempt) logError(new Error('Failed to set up web push'), error)
    else setTimeout(() => initWebPush(true), 2000)
  }
}

export const unregisterNotifications = async () => {
  const legacyNotificationToken = getNativeNotificationToken()

  try {
    if (legacyNotificationToken) {
      await enrichedAuthenticatedFetchData(
        `${API_HOST}/user-notifications/v2.0/mytomra/device/${legacyNotificationToken}/unregister-device`,
        {
          method: 'DELETE'
        }
      ).run()
    } else {
      if (webPushSubscription) {
        await insertIntoFirestore({ devices: [] })
      }
    }
  } catch (error: any) {
    logError(new Error('Failed to unregister notification device'), error)
  }
}
