import React from 'react'
import { usesiOSNativeWrapper } from '../../services'
import { theme, intl } from '../../lib'
import {
  Camera_Help_Generic,
  Camera_Help_Android,
  Camera_Help_IOS,
  Camera_Permission_Instructions_Generic,
  Camera_Permission_Instructions_Android,
  Camera_Permission_Instructions_IOS
} from '../../translations/messages'

export const CameraPermissionHelp = () => {
  if (usesiOSNativeWrapper()) {
    return (
      <p>
        <span>{intl.formatMessage(Camera_Help_IOS)}</span>
        <b>{intl.formatMessage(Camera_Permission_Instructions_IOS, { appName: theme.name })}</b>
      </p>
    )
  } else if (window.chrome) {
    return (
      <p>
        <span>{intl.formatMessage(Camera_Help_Android)}</span>
        <b>{intl.formatMessage(Camera_Permission_Instructions_Android, { appDomain: theme.domain })}</b>
      </p>
    )
  } else {
    return (
      <p>
        <span>{intl.formatMessage(Camera_Help_Generic)}</span>
        <b>{intl.formatMessage(Camera_Permission_Instructions_Generic, { appDomain: theme.domain })}</b>
      </p>
    )
  }
}
