import React, { useState } from 'react'
import { Existing } from './Existing'
import { Disclaimer } from './Disclaimer'
import { Setup } from './Setup'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
}

export const BankTransfer = ({ savedConfiguration, active }: Props) => {
  const [stage, setStage] = useState<'existing' | 'setup' | 'disclaimer'>(
    savedConfiguration ? 'existing' : 'disclaimer'
  )

  if (stage === 'existing') {
    return <Existing savedConfiguration={savedConfiguration} active={active} />
  }

  if (stage === 'disclaimer') {
    return <Disclaimer setStage={setStage} />
  }

  return <Setup />
}
