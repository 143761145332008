import React, { useState } from 'react'
import styled from 'styled-components'
import ActivityHistory from './ActivityHistory'
import { Currency } from '../shared'
import { ArrowDownIcon, RecycleBottleIcon } from '../icons'
import { intl } from '../../lib'
import {
  ActivityRow_BagDeliveredAt,
  Common_BagRegistered,
  ActivityRow_PaidOut,
  ActivityRow_Pending,
  ActivityRow_PendingAction,
  ActivityRow_RecycledAt,
  ActivityRow_Expired
} from '../../translations/messages'

export const Container = styled.button<{ shouldBeYellow: boolean }>`
  position: relative;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: var(--shadow-sm);
  color: ${props => props.color};
  padding: var(--spacing-sm) var(--spacing-md);
  width: 100%;
  background-color: ${props => (props.shouldBeYellow ? 'var(--colors-yellow)' : 'white')};
  z-index: 1;
  border: none;
  text-align: left;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-column-gap: var(--spacing-sm);
  grid-template-rows: auto auto;
  align-items: center;
`

const ActivityHeading = styled.div`
  grid-column-start: 2;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
`

const ActivityStatus = styled.div`
  grid-column-start: 2;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  margin-top: var(--spacing-xs);
  font-size: var(--font-size-sm);
`

const RefundValue = styled.span`
  margin-right: var(--spacing-xs);
`

interface Props {
  activity: RefundType
}

export const ActivityRow = ({ activity }: Props) => {
  const [showHistory, setShowHistory] = useState(false)
  const { id, activityType, location, refund, status, currency, activities } = activity
  const shouldShowRefundValue = status === 'SUCCESSFUL' && refund && currency
  const isPendingAction = status === 'USER_ERROR' || status === 'SYSTEM_ERROR'
  const bagtag = activityType === 'BAG' ? id : ''

  const toggleDetails = () => {
    setShowHistory(!showHistory)
  }

  return (
    <React.Fragment>
      <h3
        style={{
          marginTop: 'var(--spacing-md)'
        }}
      >
        <Container
          id={`accButton-${activity.id}`}
          onClick={toggleDetails}
          shouldBeYellow={isPendingAction}
          aria-expanded={showHistory}
          aria-controls={`accSection-${activity.id}`}
        >
          <InnerContainer>
            <RecycleBottleIcon size="2rem" />

            <ActivityHeading className="truncateText">
              {activityType === 'BAG' && location
                ? intl.formatMessage(ActivityRow_BagDeliveredAt, { location })
                : activityType === 'BAG' && !location
                  ? intl.formatMessage(Common_BagRegistered)
                  : intl.formatMessage(ActivityRow_RecycledAt, { location })}
            </ActivityHeading>

            <ActivityStatus>
              {shouldShowRefundValue && (
                <RefundValue>
                  <Currency amount={refund} currency={currency} />
                </RefundValue>
              )}
              {isPendingAction ? (
                <span>{intl.formatMessage(ActivityRow_PendingAction)}</span>
              ) : status === 'SUCCESSFUL' ? (
                <span>{intl.formatMessage(ActivityRow_PaidOut)}</span>
              ) : status === 'EXPIRED' ? (
                <span>{intl.formatMessage(ActivityRow_Expired)}</span>
              ) : (
                <span>{intl.formatMessage(ActivityRow_Pending)}</span>
              )}
            </ActivityStatus>

            <ArrowDownIcon />
          </InnerContainer>
        </Container>
      </h3>

      {showHistory && (
        <div id={`accSection-${activity.id}`} role="region" aria-labelledby={`accButton-${activity.id}`}>
          <ActivityHistory bagtag={bagtag} activities={activities} status={status} shouldBeYellow={isPendingAction} />
        </div>
      )}
    </React.Fragment>
  )
}
