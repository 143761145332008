import React from 'react'
import styled from 'styled-components'
import useEmblaCarousel from 'embla-carousel-react'
import { VoucherCard } from './VoucherCard'

const CarouselOuter = styled.div`
  overflow: hidden;
`

const CarouselInner = styled.div`
  display: flex;
  margin: 0 var(--spacing-xl);
`

type Props = {
  vouchers: VoucherType[]
}

export const VoucherSwiper = ({ vouchers }: Props) => {
  const [emblaRef] = useEmblaCarousel({ loop: vouchers.length === 1 ? true : false })

  return (
    <React.Fragment>
      <CarouselOuter ref={emblaRef}>
        <CarouselInner>
          {vouchers.map(voucher => (
            <VoucherCard voucher={voucher} key={voucher.barcode} />
          ))}
        </CarouselInner>
      </CarouselOuter>
    </React.Fragment>
  )
}
