export function redirect(payload?: { type?: string; recipient?: string; errorMessage?: string }) {
  const encodedpayload = payload && btoa(JSON.stringify(payload))
  const maybeQueryParamString = encodedpayload ? `?encodedpayload=${encodedpayload}` : ''

  // if window.opener exists, we are not on native
  if (window.opener) {
    window.opener.location = window.location.origin + maybeQueryParamString
    window.close()
  } else {
    window.location.href = window.location.origin + maybeQueryParamString
  }
}
