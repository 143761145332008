import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { LocationCategoryInfo } from './LocationCategoryInfo'
import { Card, Checkbox } from '../shared'
import { theme, intl } from '../../lib'
import { LocationsContext } from './LocationsContext'
import {
  LocationSearch_FilterOnReturnPoint,
  CollectionPointTypes_RVM_Title,
  CollectionPointTypes_DEPOT_Title,
  CollectionPointTypes_OTC_Title,
  CollectionPointTypes_DONATION_STATION_Title
} from '../../translations/messages'

const Container = styled.div`
  > label {
    display: block;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-md);
  }
`

const FilterCard = styled(Card)`
  cursor: pointer;
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 2.5rem;
  }
`

const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const categoryTitleMap = {
  RVM: CollectionPointTypes_RVM_Title,
  DEPOT: CollectionPointTypes_DEPOT_Title,
  OTC: CollectionPointTypes_OTC_Title,
  DONATION_STATION: CollectionPointTypes_DONATION_STATION_Title
}

export const LocationFilter = () => {
  const { activeFilters, filterMarkers, locations } = useContext(LocationsContext)

  // Expensive computation, hence useMemo
  const locationCategories = useMemo(() => {
    return locations.reduce((acc: LocationCategory[], location) => {
      if (location.category && !acc.includes(location.category)) {
        acc.push(location.category)
      }
      return acc
    }, [])
  }, [locations])

  if (locationCategories.length === 0) {
    return null
  }

  return (
    <Container>
      <Title>
        {intl.formatMessage(LocationSearch_FilterOnReturnPoint)}
        <LocationCategoryInfo locationCategories={locationCategories} />
      </Title>

      {locationCategories.map(category => (
        <label htmlFor={category} key={category}>
          <FilterCard>
            <img src={theme.images.locationCategory[category].icon} alt={`${category} icon`} />

            <span>{intl.formatMessage(categoryTitleMap[category])}</span>

            <Checkbox
              id={category}
              aria-label={`Filter by ${category}`}
              onChange={() => filterMarkers(category)}
              checked={activeFilters.includes(category)}
            />
          </FilterCard>
        </label>
      ))}
    </Container>
  )
}
