import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { logError, logInfo } from '@tomra/datadog-browser-logging'
import { feedbackMessages } from './feedbackMessages'
import { Button, TextField, Loading, Alert, BackButton } from '../shared'
import { LockIcon, UserIcon, ArrowRightIcon } from '../icons'
import { intl, setItemInWebStorage, getItemFromWebStorage, emailRegex, theme } from '../../lib'
import { sendSignInLink } from '../../services'
import { Auth_SignIn, Common_Continue, Common_Email, Auth_SignInDescription } from '../../translations/messages'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 90vh;
`

export const SignInPage = () => {
  const navigate = useNavigate()
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  useEffect(() => {
    getItemFromWebStorage('emailForSignIn').then(email => setEmailValue(email))
  }, [])

  const sendLoginEmail = async (evt: React.FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      await setItemInWebStorage('emailForSignIn', emailValue)
      await sendSignInLink(emailValue, `${window.location.origin}/login/email-link`, 'LOGIN')
      navigate('/login/login-link-sent', { state: { email: emailValue } })
    } catch (error: any) {
      if (error.status === 412) {
        // User has set up with password login from manager
        navigate('/login/password', { state: { email: emailValue } })
      } else if (error.status === 409) {
        // Force user over from MT to R&E
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['mytomra-in-nsw'])
        logError(new Error('Attempted R&E login in MT'), error, { email: emailValue })
      } else if (error.status === 400) {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['auth/invalid-email'])
        logInfo(`Attempted login with invalid email. Error: ${error.message}`, { email: emailValue })
      } else {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
        logError(new Error('Failed to send login link'), error, { email: emailValue })
      }
    }
  }

  return (
    <>
      <BackButton to="/login" />

      <Form className="animated fadeInRight" onSubmit={sendLoginEmail}>
        <LockIcon className="centerMargin" size="5rem" />

        <h1>{intl.formatMessage(Auth_SignIn)}</h1>

        <p>{intl.formatMessage(Auth_SignInDescription, { appName: theme.name })}</p>

        <TextField
          onChange={(value, valid) => {
            setEmailValue(value.toLowerCase())
            setEmailValid(valid)
          }}
          value={emailValue}
          valid={emailValid}
          label={intl.formatMessage(Common_Email)}
          ariaLabel={intl.formatMessage(Common_Email)}
          autoComplete="username"
          type="email"
          pattern={emailRegex}
          required
        />

        {feedbackMessage && (
          <Alert backgroundColor="var(--colors-red)" color="white" className="bounceIn">
            {feedbackMessage}
          </Alert>
        )}

        <Button type="submit" disabled={!emailValue || !emailValid || requestStatus === 'loading'}>
          <UserIcon />
          {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Continue)}
          <ArrowRightIcon />
        </Button>
      </Form>
    </>
  )
}
