import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import { registerBagtag } from '../../../services'
import { IconButton, CameraScanner, Drawer, Loading, Button } from '../../shared'
import { intl, theme, STAGE } from '../../../lib'
import { BagIcon } from '../../icons'
import {
  Common_Bagdrop,
  BagdropButton_EnterManually,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser,
  NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser,
  NativeTranslations_RegisterBagdrop_Description,
  NativeTranslations_RegisterBagdrop_InvalidBagtag,
  NativeTranslations_RegisterBagdrop_Success,
  Common_TechnicalIssues,
  Common_Done
} from '../../../translations/messages'

const resultMessages = {
  '400': intl.formatMessage(NativeTranslations_RegisterBagdrop_InvalidBagtag),
  '409': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser),
  '412': intl.formatMessage(NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 3px solid black;
  border-radius: 100px;
  min-width: 100px;
  min-height: 100px;
  color: black;

  > small {
    margin-top: var(--spacing-xs);
  }
`

const FeedbackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  text-align: center;

  > h2 {
    margin: 0 var(--spacing-xl);
  }

  > img {
    margin-bottom: var(--spacing-lg);
  }
`

const EnterManuallyTextField = styled.input`
  border-radius: var(--radius-sm);
  padding: var(--spacing-md);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 100%;
`

const EnterManuallyContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-lg);
  z-index: 1;

  > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--spacing-lg);
    width: initial;
    font-size: var(--font-size-sm);
    padding: var(--spacing-md);
    min-height: initial;
    shadow: var(--shadow-lg);
    border-radius: var(--radius-sm);
  }
`

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const BagdropButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')
  const [bagtag, setBagtag] = useState('')
  const bagtagButtonDisabled = bagtag.length < 24

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await registerBagtag(value)
        setRequestStatus('success')
      } catch (error: any) {
        if (error.status !== 400) {
          logError(new Error('Failed to register bagtag'), error)
        }

        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <FeedbackContainer className="fadeIn">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2>{intl.formatMessage(NativeTranslations_RegisterBagdrop_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2>{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </FeedbackContainer>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_BAGDROP ? (
    <>
      <StyledIconButton onClick={toggleCamera}>
        <BagIcon color="black" size="3rem" />
        <small>{intl.formatMessage(Common_Bagdrop)}</small>
      </StyledIconButton>

      <Drawer
        visible={showCamera}
        style={{ padding: 0 }}
        onClose={toggleCamera}
        ignoreMaxContentWidth
        autoFocusOnFirstInteractiveElement={false}
      >
        <>
          {requestStatus !== 'initial' && renderFeedback()}
          <EnterManuallyContainer>
            <EnterManuallyTextField
              type="text"
              maxLength={24}
              placeholder={intl.formatMessage(BagdropButton_EnterManually)}
              onChange={evt => requestStatus === 'initial' && setBagtag(evt.target.value)}
            />
            <Button aria-disabled={bagtagButtonDisabled} disabled={bagtagButtonDisabled} onClick={() => onRead(bagtag)}>
              {intl.formatMessage(Common_Done)}
            </Button>
          </EnterManuallyContainer>
          <CameraScanner
            description={intl.formatMessage(NativeTranslations_RegisterBagdrop_Description)}
            onRead={onRead}
          />
        </>
      </Drawer>
    </>
  ) : null
}
