const millisToDays = dateInMillis => {
  const seconds = dateInMillis / 1000
  const minutes = seconds / 60
  return minutes / (60 * 24)
}

export const calculateDaysBetween = (fromDate, toDate) => {
  const fromDays = Math.floor(millisToDays(new Date(fromDate).getTime()))
  const toDays = Math.floor(millisToDays(new Date(toDate).getTime()))
  return toDays - fromDays
}
