import React from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import {
  Locations_StatusIndicators_Closed,
  Locations_StatusIndicators_ClosedForService,
  Locations_StatusIndicators_Open,
  Locations_StatusIndicators_SemiFull,
  Locations_StatusIndicators_TitleCentre,
  Locations_StatusIndicators_TitleGlass,
  Locations_StatusIndicators_TitlePet,
  Locations_StatusIndicators_TitlePlasticAndCans,
  Common_Unknown
} from '../../translations/messages'

const titleByStatusType = {
  glass: Locations_StatusIndicators_TitleGlass,
  plasticAndCans: Locations_StatusIndicators_TitlePlasticAndCans,
  centre: Locations_StatusIndicators_TitleCentre,
  PET: Locations_StatusIndicators_TitlePet
}

const descriptionByStatus = {
  OPEN: Locations_StatusIndicators_Open,
  CLOSED: Locations_StatusIndicators_Closed,
  CLOSED_FOR_SERVICE: Locations_StatusIndicators_ClosedForService,
  SEMI_FULL: Locations_StatusIndicators_SemiFull,
  UNKNOWN: Common_Unknown
}

const statusColorMap = {
  OPEN: 'var(--colors-green)',
  CLOSED_FOR_SERVICE: 'var(--colors-red)',
  CLOSED: 'var(--colors-red)',
  UNKNOWN: 'black',
  SEMI_FULL: 'var(--colors-yellow)'
}

const Container = styled.div`
  display: flex;
  align-items: center;
  transition: height 200ms;
  width: 100%;
  color: initial;
  padding: var(--spacing-sm);
  background-color: white;
  border-radius: var(--radius-sm);
`

const StatusColorIndicator = styled.div`
  position: relative;
  flex-grow: 1; // dynamically distribute all indicators evenly across width of parent

  > small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StatusText = styled.b<{ statusColor: string }>`
  color: ${props => props.statusColor};
`

type Props = {
  locationStatuses: MapLocationType['status']
}

export const LocationStatus = ({ locationStatuses }: Props) => {
  const statusTypes = Object.keys(locationStatuses || {})

  const indicators = statusTypes.map(type => {
    const statusItem = locationStatuses && locationStatuses[type]

    return (
      statusItem && (
        <StatusColorIndicator key={type}>
          <small>
            <b>{intl.formatMessage(titleByStatusType[type])}</b>

            <StatusText statusColor={statusColorMap[statusItem]}>
              {intl.formatMessage(descriptionByStatus[statusItem])}
            </StatusText>
          </small>
        </StatusColorIndicator>
      )
    )
  })

  return <Container>{indicators}</Container>
}
