import styled from 'styled-components'

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-page-width);
`

type TabProps = {
  active: boolean
}

export const Tab = styled.h2`
  color: black;
  font-weight: ${(props: TabProps) => (props.active ? 'bold' : 'initial')};
  padding-bottom: var(--spacing-sm);
  border-bottom: ${(props: TabProps) => (props.active ? `2px solid black` : 'initial')};
  cursor: pointer;
`
