import React, { useState } from 'react'
import styled from 'styled-components'
import {
  BrowseCharitiesPage_NoMatch,
  BrowseCharitiesPage_ShowingCampaigns,
  BrowseCharitiesPage_Filter,
  BrowseCharitiesPage_SortBy,
  BrowseCharitiesPage_ClearSorting,
  BrowseCharitiesPage_ClearFilter,
  Common_More
} from '../../../translations/messages'
import { useCharitySearchQuery } from '../../../hooks'
import { benefitOptions, orderOptions } from '../common/filterOptions'
import { BrowseCampaignsLoader } from '../common/BrowseCampaignsLoader'
import { CharityCampaignCard, Filter, SearchField } from '../common'
import { FeedbackMessage, Loading, Button } from '../../shared/common'
import { intl } from '../../../lib'

const Container = styled.div`
  padding: var(--spacing-md) var(--spacing-lg);
  display: flex;
  flex-direction: column;
  max-width: var(--max-page-width);
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
`

const SearchContainer = styled.div`
  margin-bottom: var(--spacing-lg);
`

const SearchResults = styled.div`
  margin-top: var(--spacing-md);
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: var(--spacing-sm);
`

const NumResults = styled.small`
  display: block;
  margin-bottom: var(--spacing-sm);
  font-weight: bold;
`

const FilterWrapper = styled.div`
  margin-top: var(--spacing-lg);
  display: flex;
`

type InnerProps = {
  campaigns: CharityCampaignType[]
  totalCampaigns: number
  isLoading: boolean
  isFetchingNextPage: boolean
}

const Inner = ({ campaigns, totalCampaigns, isLoading, isFetchingNextPage }: InnerProps) => {
  const hasResults = campaigns.length > 0

  if (isLoading) {
    return <BrowseCampaignsLoader count={3} />
  }

  if (!hasResults) {
    return <FeedbackMessage>{intl.formatMessage(BrowseCharitiesPage_NoMatch)}</FeedbackMessage>
  }

  if (hasResults) {
    return (
      <SearchResults>
        <NumResults>
          {intl.formatMessage(BrowseCharitiesPage_ShowingCampaigns, { num: totalCampaigns ?? 0 })}
        </NumResults>

        <List>
          {campaigns.map(campaign => (
            <ListItem key={campaign.id}>
              <CharityCampaignCard campaign={campaign} />
            </ListItem>
          ))}

          {isFetchingNextPage && <BrowseCampaignsLoader count={1} />}
        </List>
      </SearchResults>
    )
  }

  return null
}

export const BrowseCharitiesPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<CharityBenefitType | ''>('')
  const [searchOrder, setSearchOrder] = useState<CharitySearchOrderType | ''>('')

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useCharitySearchQuery(
    searchTerm,
    searchFilter,
    searchOrder
  )

  const campaigns = (data && data.pages.map(page => page.campaigns).flat()) || []
  const totalCampaigns = data?.pages[0].totalCampaigns || 0

  return (
    <Container>
      <SearchContainer>
        <SearchField
          value={searchTerm}
          onChange={value => {
            setSearchTerm(value)
          }}
        />
        <FilterWrapper>
          <Filter
            label={
              searchFilter ? BrowseCharitiesPage_ClearFilter.defaultMessage : BrowseCharitiesPage_Filter.defaultMessage
            }
            options={benefitOptions}
            defaultValue={searchFilter ?? ''}
            onChange={newValue => {
              setSearchFilter(newValue as CharityBenefitType)
            }}
          />
          <Filter
            label={
              searchOrder ? BrowseCharitiesPage_ClearSorting.defaultMessage : BrowseCharitiesPage_SortBy.defaultMessage
            }
            options={orderOptions}
            defaultValue={searchOrder ?? ''}
            onChange={newValue => {
              setSearchOrder(newValue as CharitySearchOrderType)
            }}
          />
        </FilterWrapper>
      </SearchContainer>

      <Inner
        campaigns={campaigns}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        totalCampaigns={totalCampaigns}
      />

      {hasNextPage && (
        <Button style={{ margin: 'var(--spacing-lg) 0' }} onClick={fetchNextPage} border>
          {isFetchingNextPage ? <Loading /> : intl.formatMessage(Common_More)}
        </Button>
      )}
    </Container>
  )
}
