import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ExportIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '2px'
    }}
    width={props.size || '1.6rem'}
    height={props.size || '1.6rem'}
    viewBox="0 0 25.706 29.767"
  >
    <g transform="translate(1 1.414)">
      <path
        d="M519.182,3744.25H509.152a3.647,3.647,0,0,0-3.647,3.647v4.559"
        transform="translate(-495.475 -3738.779)"
      />
      <path d="M512.255,3750.692l5.471-5.471-5.471-5.47" transform="translate(-494.019 -3739.75)" />
      <path
        d="M517.314,3754.544v9.117a1.824,1.824,0,0,1-1.823,1.824H499.079a1.823,1.823,0,0,1-1.824-1.824v-14.588a1.823,1.823,0,0,1,1.824-1.824h2.735"
        transform="translate(-497.255 -3738.132)"
      />
    </g>
  </svg>
)
