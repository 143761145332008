import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const ConnectionDownIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <path d="M5.96,11.284a5.25,5.25,0,0,1,6.629-.647" />
    <path d="M3.07,7.822a9.75,9.75,0,0,1,13.165-.029" />
    <path d="M.75,3.885A14.252,14.252,0,0,1,18.6,3.892" />
    <path d="M17.25,20.25a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375" />
    <line x1="17.25" y1="18" x2="17.25" y2="15" />
    <path d="M23.063,20.683a1.774,1.774,0,0,1-1.587,2.567H13.024a1.774,1.774,0,0,1-1.587-2.567l4.226-8.452a1.774,1.774,0,0,1,3.174,0Z" />
  </svg>
)
