import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useLocationDetailsQuery } from '../../hooks'
import { LocationCategory } from './LocationCategory'
import { LocationStatus } from './LocationStatus'
import { getItemFromWebStorage, setItemInWebStorage, intl } from '../../lib'
import { StarIcon, LocationIcon, TimeIcon, InfoIcon } from '../icons'
import { Button, Loading, Drawer } from '../shared'
import { isOnNative, openAppUrl } from '../../services'
import { LocationDetails_Directions, LocationDetails_MakeFavorite } from '../../translations/messages'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20rem;
`

const InnerContainer = styled.div`
  padding: var(--spacing-md);

  > *:not(:first-child) {
    margin-top: var(--spacing-lg);
  }
`

const LocationInfoItem = styled.div`
  display: flex;
  align-items: center;

  > svg {
    min-width: 1.6rem;
    margin-right: var(--spacing-sm);
  }

  :nth-child(even) {
    margin-bottom: var(--spacing-sm);
    margin-top: var(--spacing-sm);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-child {
    margin-right: var(--spacing-sm);
  }

  > button:first-child {
    margin-left: var(--spacing-sm);
  }
`

const LocationName = styled.div`
  background-color: white;
  padding: var(--spacing-lg) var(--spacing-sm);
  font-weight: bold;
  text-align: center;
`

export const LocationDetails = () => {
  const navigate = useNavigate()
  const { openedLocationId } = useParams()
  const [favoriteLocationId, setFavoriteLocationId] = useState('')
  const { data } = useLocationDetailsQuery(openedLocationId!)

  useEffect(() => {
    getItemFromWebStorage('favoriteLocationId').then(setFavoriteLocationId)
  }, [])

  const _openDirections = () => {
    const { latitude, longitude } = data || {}
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`

    if (isOnNative()) {
      openAppUrl(url)
    } else {
      window.open(url)
    }
  }

  const _toggleFavoriteLocation = () => {
    const isAlreadyFavoriteLocation = favoriteLocationId === openedLocationId
    isAlreadyFavoriteLocation ? _updateFavoriteLocation('') : _updateFavoriteLocation(openedLocationId)
  }

  const _updateFavoriteLocation = locationId => {
    setItemInWebStorage('favoriteLocationId', locationId)
    setFavoriteLocationId(locationId)
  }

  const isFavoriteLocation = openedLocationId === favoriteLocationId

  return (
    <Drawer onClose={() => navigate('..', { replace: true })} visible style={{ padding: 0 }}>
      <Container>
        {!data ? (
          <Loading className="centerAbsolute" />
        ) : (
          <React.Fragment>
            <LocationName>
              <span>{data.name}</span>
            </LocationName>

            <InnerContainer>
              <InnerContainer>
                <div>
                  <LocationInfoItem>
                    <LocationIcon />
                    <p>{data.address}</p>
                  </LocationInfoItem>

                  {data.readableOpeningHours && (
                    <LocationInfoItem>
                      <TimeIcon />
                      <p>{data.readableOpeningHours}</p>
                    </LocationInfoItem>
                  )}

                  {data.info && (
                    <LocationInfoItem>
                      <InfoIcon />
                      <p>{data.info}</p>
                    </LocationInfoItem>
                  )}
                </div>

                <LocationCategory locationCategory={data.category} />
              </InnerContainer>

              {data.status && <LocationStatus locationStatuses={data.status} />}

              <ButtonContainer>
                <Button onClick={_toggleFavoriteLocation} border>
                  <StarIcon fillColor={isFavoriteLocation ? 'var(--colors-yellow)' : ''} />
                  {intl.formatMessage(LocationDetails_MakeFavorite)}
                  <div />
                </Button>

                <Button onClick={_openDirections} background="colored">
                  {intl.formatMessage(LocationDetails_Directions)}
                </Button>
              </ButtonContainer>
            </InnerContainer>
          </React.Fragment>
        )}
      </Container>
    </Drawer>
  )
}
