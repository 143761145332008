import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { usePayoutMethodsQuery, usePayoutMethodMutation } from '../../../hooks/query-hooks'
import { theme, intl } from '../../../lib'
import { PayoutQuickSelect } from '../../payout/PayoutQuickSelect'
import { Button, Drawer } from '../../shared'
import { ArrowRightIcon, HeartIcon, VoucherIcon, SavingBankIcon, SettingsIcon } from '../../icons'
import {
  ActivePayout_Title,
  ActivePayout_SetupPayout,
  Common_DigitalVoucher,
  Common_Donation,
  Common_PayPal,
  Common_BankTransfer
} from '../../../translations/messages'

const Container = styled.div`
  margin-top: var(--spacing-lg);

  > small {
    display: block;
    font-weight: 500;
    margin-left: var(--spacing-md);
    margin-bottom: var(--spacing-sm);
  }
`

const payoutNameAndIconMap = (payoutMethod: PayoutMethodType | undefined) => {
  const { type, recipient, logoUrl } = payoutMethod || {}

  switch (type) {
    case 'PAYPAL':
      return {
        name: intl.formatMessage(Common_PayPal),
        icon: <img src={theme.images.paypalLogo} alt="paypal" height="24px" />
      }
    case 'E_VOUCHER':
      return {
        name: intl.formatMessage(Common_DigitalVoucher),
        icon: <VoucherIcon />
      }
    case 'DONATION':
      return {
        name: recipient ? recipient : intl.formatMessage(Common_Donation),
        icon: logoUrl ? (
          <img src={logoUrl} alt="donation" height="30px" width="30px" style={{ borderRadius: 'var(--radius-lg)' }} />
        ) : (
          <HeartIcon />
        )
      }
    case 'BANK_TRANSFER':
      return {
        name: intl.formatMessage(Common_BankTransfer),
        icon: <SavingBankIcon />
      }
    default:
      return {
        name: intl.formatMessage(ActivePayout_SetupPayout),
        icon: <SettingsIcon />
      }
  }
}

const LoadingPlaceholder = () => {
  return (
    <ContentLoader
      height="3.5rem"
      width="100%"
      viewBox="0 0 21rem 3.5rem"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="2rem" cy="1.8rem" r="1.2rem" />
      <rect x="25%" y="1rem" rx="3" ry="3" width="12rem" height="1.4rem" />
    </ContentLoader>
  )
}

export const ActivePayout = () => {
  const navigate = useNavigate()
  const { data: payoutMethods } = usePayoutMethodsQuery()
  const { isPending } = usePayoutMethodMutation()
  const [showQuickSelectDrawer, setShowQuickSelectDrawer] = useState(false)
  const activePayoutMethod = payoutMethods?.find(payoutMethod => payoutMethod.active === true)
  const hasPreConfiguredPayoutMethod = payoutMethods?.find(payoutMethod => payoutMethod.savedConfiguration)
  const { name, icon } = payoutNameAndIconMap(activePayoutMethod)

  return (
    <Container>
      <small>{intl.formatMessage(ActivePayout_Title)}</small>

      <Button
        style={{ justifyContent: 'space-between' }}
        onClick={
          hasPreConfiguredPayoutMethod ? () => setShowQuickSelectDrawer(true) : () => navigate('/payoutsettings')
        }
      >
        {isPending ? (
          <LoadingPlaceholder />
        ) : (
          <>
            {icon}
            <span>{name}</span>
            <ArrowRightIcon />
          </>
        )}
      </Button>

      <Drawer
        style={{ padding: 'var(--spacing-lg) var(--spacing-md)' }}
        onClose={() => setShowQuickSelectDrawer(false)}
        visible={showQuickSelectDrawer}
      >
        <PayoutQuickSelect />
      </Drawer>
    </Container>
  )
}
