import React, { useState, useEffect } from 'react'
import { fetchAndActivate, getBoolean, getString } from 'firebase/remote-config'
import { logError } from '@tomra/datadog-browser-logging'
import { STAGE, FIREBASE } from '../lib'
import { listenForAppUpdates, stopListenForAppUpdates } from '../services'

const oneMinute = 60000
const tenMinutes = oneMinute * 10

const defaultConfig = {
  enableAppAutoRefresh: false,
  latestNativeAppVersionJson: window.Cypress
    ? JSON.stringify({ MYTOMRA: { ios: '5.0', android: '5.0' } })
    : JSON.stringify({})
}

export const RemoteConfigContext = React.createContext<{
  enableAppAutoRefresh: boolean
  latestNativeAppVersionJson: string
}>({
  enableAppAutoRefresh: defaultConfig.enableAppAutoRefresh,
  latestNativeAppVersionJson: defaultConfig.latestNativeAppVersionJson
})

export const RemoteConfigProvider: React.FunctionComponent<{ children: React.ReactNode }> = props => {
  const [enableAppAutoRefresh, setEnableAppAutoRefresh] = useState(defaultConfig.enableAppAutoRefresh)
  const [latestNativeAppVersionJson, setLatestNativeAppVersionJson] = useState(defaultConfig.latestNativeAppVersionJson)

  useEffect(() => {
    enableAppAutoRefresh ? listenForAppUpdates() : stopListenForAppUpdates()
  }, [enableAppAutoRefresh])

  useEffect(() => {
    if (STAGE === 'test') {
      FIREBASE.remoteConfig.settings.minimumFetchIntervalMillis = oneMinute // defaults to 12 hours
    }

    const fetchAndSetConfig = () => {
      fetchAndActivate(FIREBASE.remoteConfig).then(
        () => {
          setEnableAppAutoRefresh(getBoolean(FIREBASE.remoteConfig, 'enableAppAutoRefresh'))
          setLatestNativeAppVersionJson(getString(FIREBASE.remoteConfig, 'latestNativeAppVersionJson'))
        },
        error => {
          logError(new Error('Failed to fetch remote config'), error)
        }
      )
    }

    fetchAndSetConfig()
    const intervalRef = setInterval(fetchAndSetConfig, STAGE === 'test' ? oneMinute : tenMinutes)

    return () => {
      clearInterval(intervalRef)
    }
  }, [])

  return (
    <RemoteConfigContext.Provider
      value={{
        enableAppAutoRefresh,
        latestNativeAppVersionJson
      }}
    >
      {props.children}
    </RemoteConfigContext.Provider>
  )
}
