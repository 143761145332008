import React from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import { LocationCategory } from './LocationCategory'
import {
  CPTInfo_Title,
  CollectionPointTypes_RVM_Description,
  CollectionPointTypes_DEPOT_Description,
  CollectionPointTypes_OTC_Description,
  CollectionPointTypes_DONATION_STATION_Description
} from '../../translations/messages'
import { InfoIcon } from '../icons'

const Container = styled.div`
  :hover {
    > div {
      display: block;
    }
  }
`

const Tooltip = styled.div`
  position: absolute;
  bottom: var(--spacing-lg);
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background-color: black;
  color: white;
  border-radius: var(--radius-sm);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-lg);
  width: 90%;
  max-width: var(--max-page-width);
  z-index: 100;

  > button {
    position: absolute;
    top: var(--spacing-md);
    right: var(--spacing-md);
  }
`

const Section = styled.section`
  margin-top: var(--spacing-lg);

  > div {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);

    > img {
      width: 100%;
      max-width: 2rem;
      height: auto;
      margin-right: var(--spacing-sm);
    }
  }
`

const categoryDescriptionMap = {
  RVM: CollectionPointTypes_RVM_Description,
  DEPOT: CollectionPointTypes_DEPOT_Description,
  OTC: CollectionPointTypes_OTC_Description,
  DONATION_STATION: CollectionPointTypes_DONATION_STATION_Description
}

export const LocationCategoryInfo = ({ locationCategories }: { locationCategories: LocationCategory[] }) => (
  <Container>
    <InfoIcon />

    <Tooltip className="fadeIn">
      <p>
        <b>{intl.formatMessage(CPTInfo_Title)}</b>
      </p>

      {locationCategories.map(category => (
        <Section key={category}>
          <LocationCategory locationCategory={category} />

          <p>{intl.formatMessage(categoryDescriptionMap[category])}</p>
        </Section>
      ))}
    </Tooltip>
  </Container>
)
