import React, { useState } from 'react'
import styled from 'styled-components'
import { intl } from '../../lib'
import { Tab, TabContainer, BackButton, Loading } from '../shared'
import { useVouchersQuery } from '../../hooks'
import { VoucherSwiper } from './VoucherSwiper'
import {
  VoucherPage_Archived,
  VoucherPage_Available,
  VoucherPage_NoVouchers,
  Common_Vouchers
} from '../../translations/messages'

const Container = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
`

const HeaderContainer = styled.div`
  padding: 70px var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

  > h1 {
    margin-bottom: var(--spacing-xl);
  }
`

const NoVouchersMessage = styled.h1`
  color: var(--colors-grey);
  text-align: center;
`

export const VoucherPage = () => {
  const { data } = useVouchersQuery()
  const [activeTab, setActiveTab] = useState<'unredeemed' | 'archived'>('unredeemed')

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const { unredeemed, archived } = data
  const hasNoVouchers = activeTab === 'archived' ? archived.length === 0 : unredeemed.length === 0

  return (
    <Container className="fadeIn">
      <BackButton to="/" />

      <HeaderContainer>
        <h1>{intl.formatMessage(Common_Vouchers)}</h1>

        <TabContainer>
          <Tab onClick={() => setActiveTab('unredeemed')} active={activeTab === 'unredeemed'}>
            {intl.formatMessage(VoucherPage_Available, { numOfVouchers: unredeemed.length })}
          </Tab>
          <Tab onClick={() => setActiveTab('archived')} active={activeTab === 'archived'}>
            {intl.formatMessage(VoucherPage_Archived, { numOfVouchers: archived?.length })}
          </Tab>
        </TabContainer>
      </HeaderContainer>

      {hasNoVouchers ? (
        <NoVouchersMessage className="centerAbsolute">{intl.formatMessage(VoucherPage_NoVouchers)}</NoVouchersMessage>
      ) : (
        <VoucherSwiper vouchers={activeTab === 'archived' ? archived : unredeemed} />
      )}
    </Container>
  )
}
