import React, { useState } from 'react'
import styled from 'styled-components'
import { EarthIcon } from '../../icons'
import { CountryStatePicker } from './CountryStatePicker'
import { IconButton, Drawer } from '../'
import { theme, VIEWER_COUNTRY_STATE, countryStateMap } from '../../../lib'

const StyledIconButton = styled(IconButton)`
  > svg {
    margin-right: var(--spacing-md);
  }
`

export const CountryPickerButton = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const countryStateToRender = countryStateMap[VIEWER_COUNTRY_STATE].name

  return theme.showCountryPicker ? (
    <React.Fragment>
      <StyledIconButton onClick={() => setShowDrawer(!showDrawer)}>
        <EarthIcon color="black" />

        {countryStateToRender}
      </StyledIconButton>

      <Drawer onClose={() => setShowDrawer(!showDrawer)} visible={showDrawer}>
        <CountryStatePicker />
      </Drawer>
    </React.Fragment>
  ) : null
}
