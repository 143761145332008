import React from 'react'
import styled from 'styled-components'
import { encode } from '../../../lib/code128Encoder'

const Container = styled.div`
  padding-top: var(--spacing-md);
  > p {
    text-align: center;
  }
`

const _Barcode = styled.p<{ size?: string }>`
  font-family: 'Libre Barcode 128';
  font-size: ${props => props.size};
  line-height: 1;
  white-space: pre; // will break without this due to stripping zeros!
  text-align: center;
  margin-bottom: -1rem;
`

type Props = {
  value: string
  size?: string
  displayValue?: string
}

export const Barcode = ({ value, size, displayValue }: Props) => {
  const encodedValue = encode(value)

  return (
    <Container>
      <_Barcode size={size || '5rem'}>{encodedValue}</_Barcode>
      {displayValue && (
        <p>
          <small>{displayValue}</small>
        </p>
      )}
    </Container>
  )
}
