import React from 'react'
import styled from 'styled-components'
import { Currency } from '../../shared'
import { StarIcon } from '../../icons'
import { intl } from '../../../lib'
import { CampaignInfo_RaisedSoFar, CampaignInfo_Goal } from '../../../translations/messages'

const Container = styled.div`
  width: 100%;
`

const ProgressBar = styled.div<{ percentage: number }>`
  position: relative;
  padding: var(--spacing-xs);
  color: black;
  display: flex;
  justify-content: center;
  border: 2px solid black;
  border-radius: var(--radius-lg);
  background: ${props =>
    `linear-gradient(to right, var(--colors-tertiary) ${props.percentage}%, transparent 0px, transparent)`};
  height: 15px;
  margin-top: var(--spacing-md);
`

const FinancialGoal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-md);

  > div {
    text-align: center;
  }
`

const StarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 100%;

  > svg {
    position: relative;
  }

  > svg:first-child {
    right: var(--spacing-md);
    top: var(--spacing-xs);
  }

  > svg:nth-child(2) {
    bottom: var(--spacing-xs);
  }

  > svg:last-child {
    left: var(--spacing-xs);
  }
`

type Props = {
  percentage: number
  donatedTotal: number
  financialGoal: number
}

export const CampaignProgressBar = ({ percentage, donatedTotal, financialGoal }: Props) => (
  <Container>
    <ProgressBar percentage={percentage > 100 ? 100 : percentage}>
      {percentage >= 100 && (
        <StarsContainer data-testid="progressbar-stars">
          <StarIcon fillColor="var(--colors-yellow)" size="0.8rem" />
          <StarIcon fillColor="var(--colors-yellow)" size="1rem" />
          <StarIcon fillColor="var(--colors-yellow)" size="0.8rem" />
        </StarsContainer>
      )}
    </ProgressBar>

    <FinancialGoal>
      <div>
        <p>{intl.formatMessage(CampaignInfo_RaisedSoFar)}</p>

        <Currency amount={donatedTotal} />
      </div>

      <div>
        <p>{intl.formatMessage(CampaignInfo_Goal)}</p>

        <Currency amount={financialGoal} />
      </div>
    </FinancialGoal>
  </Container>
)
