import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import { checkContainerEligibility } from '../../services'
import { ListButton, CameraScanner, Drawer, Loading, Button } from '../shared'
import { intl, theme, VIEWER_COUNTRY_STATE, STAGE } from '../../lib'
import { BarcodeScannerIcon, ArrowRightIcon } from '../icons'
import {
  ContainerScanner_Title,
  ContainerScannerButton_EnterManually,
  NativeTranslations_ContainerScanner_Description,
  NativeTranslations_ContainerScanner_Success,
  NativeTranslations_ContainerScanner_Failed,
  Common_Done,
  Common_TechnicalIssues
} from '../../translations/messages'

const resultMessages = {
  '204': intl.formatMessage(NativeTranslations_ContainerScanner_Success),
  '404': intl.formatMessage(NativeTranslations_ContainerScanner_Failed),
  genericError: intl.formatMessage(Common_TechnicalIssues)
}

const FeedbackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;

  > img {
    margin-bottom: var(--spacing-lg);
  }

  > h2 {
    margin: 0 var(--spacing-xl);
  }
`

const EnterManuallyTextField = styled.input`
  border-radius: var(--radius-sm);
  padding: var(--spacing-md);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 100%;
`

const EnterManuallyContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-lg);
  z-index: 1;

  > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--spacing-lg);
    width: initial;
    font-size: var(--font-size-sm);
    padding: var(--spacing-md);
    min-height: initial;
    shadow: var(--shadow-lg);
    border-radius: var(--radius-sm);
  }
`

let timeoutRef

type Props = {
  countryStateFeatures: CountryType['features']
}

export const ContainerScannerButton = ({ countryStateFeatures }: Props) => {
  const [showCamera, setShowCamera] = useState(false)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [errorMsg, setErrorMsg] = useState('')
  const [barcode, setBarcode] = useState('')
  const barcodeButtonDisabled = barcode.length < 8

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [])

  const onRead = async value => {
    if (requestStatus === 'initial') {
      setRequestStatus('loading')

      try {
        await checkContainerEligibility(value, VIEWER_COUNTRY_STATE)
        setRequestStatus('success')
      } catch (error: any) {
        if (error.status !== 404) {
          logError(new Error('Failed to scan for container eligibility'), error)
        }

        setErrorMsg(resultMessages[error.status] || resultMessages.genericError)
        setRequestStatus('failed')
      }

      timeoutRef = setTimeout(() => {
        setRequestStatus('initial')
      }, 3000)
    }
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const renderFeedback = () => {
    return (
      <FeedbackContainer className="fadeIn">
        {requestStatus === 'success' ? (
          <>
            <img src={theme.images.success} alt="success" />
            <h2>{intl.formatMessage(NativeTranslations_ContainerScanner_Success)}</h2>
          </>
        ) : requestStatus === 'failed' ? (
          <>
            <img src={theme.images.error} alt="error" />
            <h2>{errorMsg}</h2>
          </>
        ) : requestStatus === 'loading' ? (
          <Loading />
        ) : null}
      </FeedbackContainer>
    )
  }

  return STAGE === 'test' || countryStateFeatures.HAS_CONTAINER_SCANNER ? (
    <>
      <ListButton borderBottom onClick={toggleCamera}>
        <div>
          <BarcodeScannerIcon color="black" />
          <span>{intl.formatMessage(ContainerScanner_Title)}</span>
        </div>
        <ArrowRightIcon />
      </ListButton>

      <Drawer
        visible={showCamera}
        style={{ padding: 0 }}
        onClose={toggleCamera}
        ignoreMaxContentWidth
        autoFocusOnFirstInteractiveElement={false}
      >
        <>
          {requestStatus !== 'initial' && renderFeedback()}
          <EnterManuallyContainer>
            <EnterManuallyTextField
              type="text"
              maxLength={13}
              placeholder={intl.formatMessage(ContainerScannerButton_EnterManually)}
              onChange={evt => requestStatus === 'initial' && setBarcode(evt.target.value)}
            />

            <Button
              aria-disabled={barcodeButtonDisabled}
              disabled={barcodeButtonDisabled}
              onClick={() => onRead(barcode)}
            >
              {intl.formatMessage(Common_Done)}
            </Button>
          </EnterManuallyContainer>
          <CameraScanner
            description={intl.formatMessage(NativeTranslations_ContainerScanner_Description)}
            onRead={onRead}
          />
        </>
      </Drawer>
    </>
  ) : null
}
