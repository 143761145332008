import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { BrowseCharitiesPage_SearchPlaceholder } from '../../../translations/messages'
import { SearchIcon, CloseIcon } from '../../icons'
import { IconButton } from '../../shared/common'

const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const ClearButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`

const StyledSearchField = styled.label`
  position: relative;
  padding: var(--spacing-sm) 0;
  border-bottom: 2px solid black;

  input {
    padding-left: var(--spacing-xl);
    width: 100%;
    border: none;
    font-size: inherit;
    background: transparent;
    transition: border-color 0.25s ease-out;

    &:focus {
      border-bottom-color: var(--colors-red);
      outline: none;
    }

    &::placeholder {
      color: black;
      opacity: 1;
      font-style: italic;
    }
  }
`

type Props = {
  value: string
  onChange: (value: string) => void
}

export const SearchField: FunctionComponent<Props> = ({ value, onChange }) => {
  return (
    <StyledSearchField>
      <Icon>
        <SearchIcon />
      </Icon>

      <input
        type="text"
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        placeholder={BrowseCharitiesPage_SearchPlaceholder.defaultMessage}
      />

      {value.length > 0 && (
        <ClearButton onClick={() => onChange('')}>
          <CloseIcon size="1rem" />
        </ClearButton>
      )}
    </StyledSearchField>
  )
}
