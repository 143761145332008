import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CalendarIcon } from '../../icons'
import { IconButton } from '../../shared/common'

const ReplacementLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--spacing-xl) + var(--spacing-xs));
  width: 65%;
  background: var(--colors-background);
`

const IconWrapper = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--spacing-sm);
  z-index: -1;

  &:hover {
    cursor: pointer;
  }
`

const DatePicker = styled.div`
  width: 100%;
  margin-bottom: var(--spacing-lg);

  label {
    display: inline-block;
    font-weight: bold;
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-xs);
    transform: scale(0.8);
  }

  input {
    color: black;
    display: block;
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) calc(var(--spacing-xl) + var(--spacing-xs));
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    -webkit-appearance: none;

    // Makes the native image picker calendar icon invisible and moves it to the left,
    // but it's still clickable so we can trigger the date picker in browsers that
    // supports it
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      opacity: 1;
      left: -1rem;
      background: transparent;
      z-index: 1;
    }
  }

  .error,
  .error ~ ${ReplacementLabel}, .error ~ ${IconWrapper} {
    color: var(--colors-red);
    border-color: var(--colors-red);
  }
`

const InputWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &.replaceLabel input {
    color: white;
  }
`

// This is a hack to make the background of the datepicker white
const Background = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 6rem;
  background: var(--colors-background);
  z-index: -1;
`

type Props = {
  label: string
  value: string
  valid: boolean
  onChange: (value: string) => void
  maxDate?: string
  required?: boolean
  replaceLabel?: boolean
  replacementLabel?: string
  errorMsg?: string
}

export const DatePickerField: FunctionComponent<Props> = ({
  label,
  value,
  valid,
  onChange,
  maxDate,
  required,
  replaceLabel,
  replacementLabel,
  errorMsg
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const focusOnDateInput = evt => {
    evt.preventDefault()

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    if (!errorMsg) {
      return
    }

    const timeout = setTimeout(() => {
      setShowError(true)
    }, 850)

    return () => {
      setShowError(false)
      clearTimeout(timeout)
    }
  }, [errorMsg])

  return (
    <DatePicker>
      <label htmlFor={label.toLowerCase()}>{label}</label>

      <InputWrapper className={replaceLabel && !hasFocus ? 'replaceLabel' : ''}>
        <Background />
        <input
          id={label.toLowerCase()}
          ref={inputRef}
          className={valid ? '' : 'error'}
          type="date"
          onChange={({ target: { value } }) => onChange(value)}
          value={value}
          max={maxDate}
          required={required}
          pattern="\d{4}-\d{2}-\d{2}"
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />

        <IconWrapper onClick={focusOnDateInput}>
          <CalendarIcon />
        </IconWrapper>

        {replaceLabel && !hasFocus && (
          <ReplacementLabel onClick={focusOnDateInput}>{replacementLabel}</ReplacementLabel>
        )}
      </InputWrapper>

      {showError && <div style={{ color: 'var(--colors-red)', marginTop: 'var(--spacing-xs)' }}>{errorMsg}</div>}
    </DatePicker>
  )
}
