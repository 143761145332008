import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const QrCodeIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 40 41.56"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '2.5px'
    }}
  >
    <path d="M1.25,22.08v2.6h2.5" />
    <path d="M1.25,1.25h10V11.67h-10Z" />
    <path d="M28.75,1.25h10V11.67h-10Z" />
    <path d="M1.25,29.89h10V40.31h-10Z" />
    <path d="M20,1.25h3.75" />
    <path d="M16.25,1.25V6.46H20" />
    <path d="M23.75,6.46v5.21h-7.5" />
    <path d="M1.25,16.87h10v2.6" />
    <path d="M8.75,24.68h2.5" />
    <path d="M38.75,40.31h-5V35.1h5V29.89h-5" />
    <path d="M23.75,40.31h-7.5V29.89" />
    <path d="M28.75,29.89V40.31" />
    <path d="M21.25,29.89h2.5V35.1h-2.5" />
    <path d="M16.25,22.08v2.6h2.5" />
    <path d="M23.75,24.68h5" />
    <path d="M26.25,24.68v-5.2" />
    <path d="M16.25,16.87h5v2.6" />
    <path d="M38.75,16.87v2.6" />
    <path d="M38.75,23.39v1.29" />
    <path d="M31.25,16.87v2.6h2.5v3.91" />
  </svg>
)
