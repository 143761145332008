import React, { useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { deleteUser, unregisterNotifications } from '../../services'
import { Button, Loading } from '../shared'
import { FIREBASE, removeItemFromWebStorage, intl } from '../../lib'
import {
  Common_Cancel,
  ConfirmDelete_Confirm,
  ConfirmDelete_ConfirmDelete,
  Common_GeneralError
} from '../../translations/messages'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
`

const ErrorMsg = styled.p`
  color: var(--colors-red);
  font-weight: bold;
`

export const ButtonRow = styled.span`
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;

  > button {
    margin: var(--spacing-sm);
  }
`

export const ConfirmDelete = ({ onClose }: { onClose: () => void }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')

  const _deleteUser = async () => {
    try {
      setRequestStatus('loading')
      await unregisterNotifications()
      await deleteUser().run()
      await removeItemFromWebStorage('lastAuthTimestamp')
      await FIREBASE.auth.signOut()
    } catch (error: any) {
      setRequestStatus('failed')
      logError(new Error('Failed to properly delete user'), error)
    }
  }

  return (
    <Container>
      {requestStatus === 'failed' ? (
        <ErrorMsg>{intl.formatMessage(Common_GeneralError)}</ErrorMsg>
      ) : (
        <p>{intl.formatMessage(ConfirmDelete_Confirm)}</p>
      )}
      <ButtonRow>
        <Button border={true} onClick={onClose} disabled={requestStatus === 'loading'}>
          {intl.formatMessage(Common_Cancel)}
        </Button>
        <Button background="danger" onClick={_deleteUser} disabled={requestStatus === 'loading'}>
          {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(ConfirmDelete_ConfirmDelete)}
        </Button>
      </ButtonRow>
    </Container>
  )
}
