import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ backgroundColor: string | undefined; percentage: number }>`
  padding: var(--spacing-xs);
  color: black;
  display: flex;
  justify-content: center;
  border: 2px solid black;
  border-radius: var(--radius-lg);
  background: ${props => `linear-gradient(to right, ${props.backgroundColor} ${props.percentage}%, white 0px, white)`};
  width: 100%;
`

type Props = {
  percentage: number
  color?: string
  backgroundColor?: string
  children: React.ReactNode
}

export const ProgressBar = ({ percentage, color, backgroundColor, children }: Props) => (
  <Container percentage={percentage} color={color} backgroundColor={backgroundColor}>
    <small>
      <b>{children}</b>
    </small>
  </Container>
)

ProgressBar.defaultProps = {
  color: 'black',
  backgroundColor: 'var(--colors-primary)'
}
