export const Common_Update = {
  id: 'Common.Update',
  defaultMessage: 'Update'
}

export const Common_Send = {
  id: 'Common.Send',
  defaultMessage: 'Send'
}

export const Common_Confirm = {
  id: 'Common.Confirm',
  defaultMessage: 'Confirm'
}

export const Common_Ok = {
  id: 'Common.Ok',
  defaultMessage: 'Ok'
}

export const Common_EditProfile = {
  id: 'Common.EditProfile',
  defaultMessage: 'Edit profile'
}

export const Common_PrivacyPolicy = {
  id: 'Common.PrivacyPolicy',
  defaultMessage: 'Privacy Policy'
}

export const Common_PayPal = {
  id: 'Common.PayPal',
  defaultMessage: 'PayPal'
}

export const Common_Donation = {
  id: 'Common.Donation',
  defaultMessage: 'Donation'
}

export const Common_BankTransfer = {
  id: 'Common.BankTransfer',
  defaultMessage: 'Bank Transfer'
}

export const Common_ContactUs = {
  id: 'Common.ContactUs',
  defaultMessage: 'Contact us'
}

export const Common_Vouchers = {
  id: 'Common.Vouchers',
  defaultMessage: 'Vouchers'
}

export const Common_Refunds = {
  id: 'Common.Refunds',
  defaultMessage: 'Refunds'
}

export const Common_Achievements = {
  id: 'Common.Achievements',
  defaultMessage: 'Achievements'
}

export const Common_DigitalVoucher = {
  id: 'Common.DigitalVoucher',
  defaultMessage: 'Digital Voucher'
}

export const Common_TechnicalIssues = {
  id: 'Common.TechnicalIssues',
  defaultMessage: 'It seems we are having some technical issues at the moment. Please try again later.'
}

export const Auth_ReadAndAgreeToThe = {
  id: 'Auth.ReadAndAgreeToThe',
  defaultMessage: 'I have read and agree to the'
}

export const Common_TermsAndConditions = {
  id: 'Common.TermsAndConditions',
  defaultMessage: 'Terms and Conditions'
}

export const Common_SessionExpired = {
  id: 'Common.SessionExpired',
  defaultMessage: 'Your session has expired. Please log in again.'
}

export const Common_Unknown = {
  id: 'Common.Unknown',
  defaultMessage: 'Unknown'
}

export const Common_AnimalWelfare = {
  id: 'Common.AnimalWelfare',
  defaultMessage: 'Animal Welfare'
}

export const Common_ArtsAndCulture = {
  id: 'Common.ArtsAndCulture',
  defaultMessage: 'Arts and Culture'
}

export const Common_CommunityDevelopment = {
  id: 'Common.CommunityDevelopment',
  defaultMessage: 'Community Development'
}

export const Common_DisabilityServices = {
  id: 'Common.DisabilityServices',
  defaultMessage: 'Disability Services'
}

export const Common_DomesticAndFamily = {
  id: 'Common.DomesticAndFamily',
  defaultMessage: 'Domestic and Family'
}

export const Common_DroughtAndBushfireRelief = {
  id: 'Common.DroughtAndBushfireRelief',
  defaultMessage: 'Natural Disaster Relief'
}

export const Common_Education = {
  id: 'Common.Education',
  defaultMessage: 'Education'
}

export const Common_Environment = {
  id: 'Common.Environment',
  defaultMessage: 'Environment'
}

export const Common_Health = {
  id: 'Common.Health',
  defaultMessage: 'Health'
}

export const Common_HumanAndCivilRights = {
  id: 'Common.HumanAndCivilRights',
  defaultMessage: 'Human and Civil Rights'
}

export const Common_HumanServices = {
  id: 'Common.HumanServices',
  defaultMessage: 'Human Services'
}

export const Common_InternationalAid = {
  id: 'Common.InternationalAid',
  defaultMessage: 'International Aid'
}

export const Common_Religion = {
  id: 'Common.Religion',
  defaultMessage: 'Religion'
}

export const Common_ResearchAndPublicPolicy = {
  id: 'Common.ResearchAndPublicPolicy',
  defaultMessage: 'Research and Public Policy'
}

export const Common_Sports = {
  id: 'Common.Sports',
  defaultMessage: 'Sports'
}

export const Common_VeteransServices = {
  id: 'Common.VeteransServices',
  defaultMessage: 'Veterans Services'
}

export const Common_By = {
  id: 'Common.By',
  defaultMessage: 'By'
}

export const Common_Description = {
  id: 'Common.Description',
  defaultMessage: 'Description'
}

export const Common_Charities = {
  id: 'Common.Charities',
  defaultMessage: 'Charities'
}

export const Common_EditPayout = {
  id: 'Common.EditPayout',
  defaultMessage: 'Edit payout'
}

export const Common_CampaignEnded = {
  id: 'Common.CampaignEnded',
  defaultMessage: 'Campaign has ended'
}

export const Common_Bagdrop = {
  id: 'Common.Bagdrop',
  defaultMessage: 'Bag drop'
}

export const Common_BrowseCharities = {
  id: 'Common.BrowseCharities',
  defaultMessage: 'Browse charities'
}

export const Common_Enable = {
  id: 'Common.Enable',
  defaultMessage: 'Enable'
}

export const Common_UpdateFailed = {
  id: 'Common.UpdateFailed',
  defaultMessage: 'Failed to update. Please try again later.'
}

export const Common_UpdatePayoutFailure = {
  id: 'Common.UpdatePayoutFailure',
  defaultMessage: 'Failed to update your payout setting at this moment. Please try again later.'
}

export const Common_UseSavedAccount = {
  id: 'Common_UseExistingAccount.UseSavedAccount',
  defaultMessage: 'Use saved account'
}

export const Common_UsePrevious = {
  id: 'Common_UseExistingAccount.UsePrevious',
  defaultMessage: 'Use previous'
}

export const Common_BagRegistered = {
  id: 'Common.BagRegistered',
  defaultMessage: 'Bag registered'
}

export const Common_GeneralError = {
  id: 'Common.GeneralError',
  defaultMessage: 'Whoopsie! Something went wrong. Please try again later.'
}

export const Common_FetchError = {
  id: 'Common.FetchError',
  defaultMessage: 'Seems we have some trouble fetching your data. Please check your internet connection, and try again.'
}

export const AccountPage_ChangeLocation = {
  id: 'AccountPage.ChangeLocation',
  defaultMessage: 'Change recycling location'
}

export const Auth_FirstNameDisclaimer = {
  id: 'Auth.Forms.Labels.FirstnameDisclaimer',
  defaultMessage:
    'This will be displayed on the screen of the Reverse Vending Machine if you choose to identify at the machine with {appName}'
}

export const Auth_Name = {
  id: 'Auth.Forms.Labels.Name',
  defaultMessage: 'Name'
}

export const Auth_EmailConditionsNotMet = {
  id: 'Auth.Forms.Feedback.EmailConditionsNotMet',
  defaultMessage: 'Looks like you have entered an invalid email address'
}

export const Auth_EmailNotSameAsLink = {
  id: 'Auth.Forms.Feedback.EmailNotSameAsLink',
  defaultMessage: 'The email does not match the one we sent the login link to'
}

export const Auth_MissingEmail = {
  id: 'Auth.Forms.Feedback.MissingEmail',
  defaultMessage: 'Missing email for password login. Please go back and enter your email again.'
}

export const Auth_MyTomraInNsw = {
  id: 'Auth.Forms.Feedback.MyTomraInNsw',
  defaultMessage:
    'myTOMRA is not available in New South Wales anymore. Please download the new Return and Earn app and use that instead.'
}

export const Auth_OldPassword = {
  id: 'Auth.Forms.Feedback.OldPassword',
  defaultMessage: 'Old password'
}

export const Auth_NewPassword = {
  id: 'Auth.Forms.Feedback.NewPassword',
  defaultMessage: 'New password'
}

export const Auth_NewPasswordVerify = {
  id: 'Auth.Forms.Feedback.NewPasswordVerify',
  defaultMessage: 'Verify new password'
}

export const Auth_PasswordRequirements = {
  id: 'Auth.Forms.Feedback.PasswordRequirements',
  defaultMessage: 'Must be minimum 8 characters'
}

export const Auth_PasswordsMustMatch = {
  id: 'Auth.Forms.Feedback.PasswordsMustMatch',
  defaultMessage: 'New password and verify new password must match one another'
}

export const Auth_PasswordWasUpdated = {
  id: 'Auth.Forms.Feedback.PasswordWasUpdated ',
  defaultMessage: 'Great! Your password has been updated.'
}

export const Auth_ExpiredLoginLink = {
  id: 'Auth.Forms.Feedback.ExpiredLoginLink',
  defaultMessage:
    'The sign-in link you clicked has expired. Please note that the sign-in link is only valid for 6 hours, so try entering your email again to receive a new one.'
}

export const Auth_InvalidLoginLink = {
  id: 'Auth.Forms.Feedback.InvalidLoginLink',
  defaultMessage:
    'The sign-in link you clicked is no longer valid. Please note that the sign-in link can only be used once, so try entering your email again to receive a new one.'
}

export const Auth_UserDisabled = {
  id: 'Auth.Forms.Feedback.UserDisabled',
  defaultMessage: 'Your user is disabled.'
}

export const Auth_WrongPassword = {
  id: 'Auth.Forms.Feedback.WrongPassword',
  defaultMessage: 'Sorry, that does not seem to be the right password.'
}

export const Auth_EnterEmailToComplete = {
  id: 'Auth.Forms.Feedback.EnterEmailToComplete',
  defaultMessage: 'Please enter your email again to complete login.'
}

export const Auth_AlmostThere = {
  id: 'Auth.AlmostThere',
  defaultMessage: 'Almost there'
}

export const Auth_Register = {
  id: 'Auth.Register',
  defaultMessage: 'Register'
}

export const Auth_RegisterDescription = {
  id: 'Auth.RegisterDescription',
  defaultMessage: 'Register a new {appName} account. It is free and easy.'
}

export const Auth_SignIn = {
  id: 'Auth.SignIn',
  defaultMessage: 'Sign in'
}

export const Auth_SignInDescription = {
  id: 'Auth.SignInDescription',
  defaultMessage: 'Sign in to your {appName} account by typing your email address below and press continue.'
}

export const Auth_LoggingYouIn = {
  id: 'Auth.LoggingYouIn',
  defaultMessage: 'Logging you in..'
}

export const Auth_EmailLinkSentAt = {
  id: 'Auth.EmailLinkSentAt',
  defaultMessage: "We've sent you a login link to your email address at: "
}

export const Auth_RegisterDisclaimer = {
  id: 'Auth.RegisterDisclaimer',
  defaultMessage:
    'Previously registered users will NOT receive the login link, please go back and select "Sign-in" instead'
}

export const Auth_LoginDisclaimer = {
  id: 'Auth.LoginDisclaimer',
  defaultMessage:
    'Only registered users will receive the sign-in link. If you are new, go back and select "Register" instead'
}

export const Auth_OpenEmailAndTapToSignIn = {
  id: 'Auth.OpenEmailAndTapToSignIn',
  defaultMessage: 'Please open your email app on your device, and tap the link in the email to sign in.'
}

export const Auth_WaitTwoMinutes = {
  id: 'Auth.WaitTwoMinutes',
  defaultMessage: 'Check again in a couple of minutes'
}

export const Auth_DidntReceiveLink = {
  id: 'Auth.DidntReceiveLink',
  defaultMessage: "Didn't receive the link?"
}

export const Auth_CheckSpamFolder = {
  id: 'Auth.CheckSpamFolder',
  defaultMessage: 'Refresh your inbox or check your spam folder'
}

export const Auth_CheckSpellingOfEmail = {
  id: 'Auth.CheckSpellingOfEmail',
  defaultMessage: 'Check the spelling of your email address. If incorrect then click back and amend'
}

export const Auth_ContactSupport = {
  id: 'Auth.ContactSupport',
  defaultMessage: 'If nothing works, contact support'
}

export const Auth_ContinueWithPassword = {
  id: 'Auth.ContinueWithPassword ',
  defaultMessage:
    "We've noted that you've requested to log in with a password instead of an email link. Please enter your password to continue."
}

export const CollectionPointTypes_RVM_Title = {
  id: 'RecyclingOptions.RVM.Title',
  defaultMessage: 'Reverse vending machines'
}

export const CollectionPointTypes_DEPOT_Title = {
  id: 'CollectionPointTypes.DEPOT.Title',
  defaultMessage: 'Automated depot'
}

export const CollectionPointTypes_OTC_Title = {
  id: 'CollectionPointTypes.OTC.Title',
  defaultMessage: 'Over the counter'
}

export const CollectionPointTypes_DONATION_STATION_Title = {
  id: 'CollectionPointTypes.DONATION_STATION.Title',
  defaultMessage: 'Donation station'
}

export const CollectionPointTypes_RVM_Description = {
  id: 'CollectionPointTypes.RVM.Description',
  defaultMessage: 'Self-service, convenient locations and ideal for up to 500 containers.'
}

export const CollectionPointTypes_DEPOT_Description = {
  id: 'CollectionPointTypes.DEPOT.Description',
  defaultMessage:
    'Staffed and offers additional services. Ideal for any no. of containers. Digital payments via the app coming soon.'
}

export const CollectionPointTypes_OTC_Description = {
  id: 'CollectionPointTypes.OTC.Description',
  defaultMessage:
    'Staffed and good for cach-in-hand refunds. Ideal for < 100 containers. Digital payments via the app coming soon.'
}

export const CollectionPointTypes_DONATION_STATION_Description = {
  id: 'CollectionPointTypes.DONATION_STATION.Description',
  defaultMessage: 'Self-service and donations only. Ideal for on-the-go returns.'
}

export const NativeAppUpdatedDialog_Title = {
  id: 'NativeAppUpdatedDialog.Title',
  defaultMessage: 'Update recommended'
}

export const NativeAppUpdatedDialog_FirstDescription = {
  id: 'NativeAppUpdatedDialog.FirstDescription',
  defaultMessage: 'You are using an outdated version of {appName}.'
}

export const NativeAppUpdatedDialog_SecondDescription = {
  id: 'NativeAppUpdatedDialog.SecondDescription',
  defaultMessage: 'We recommend that you update to make use of the latest bug fixes and improvements.'
}

export const AppUpdatedDialog_Title = {
  id: 'AppUpdatedDialog.Title',
  defaultMessage: 'Update ready'
}

export const AppUpdatedDialog_Button = {
  id: 'AppUpdatedDialog.Button',
  defaultMessage: 'Reload'
}

export const ActivityHistory_DigitalVoucherRedeemed = {
  id: 'ActivityHistory.DigitalVoucherRedeemed',
  defaultMessage: 'Digital voucher redeemed'
}

export const ActivityHistory_TransferToPayPal = {
  id: 'ActivityHistory.TransferToPayPal',
  defaultMessage: 'Transfer to PayPal'
}

export const ActivityHistory_DonateToCharityCampaign = {
  id: 'ActivityHistory.DonateToCharityCampaign',
  defaultMessage: 'Donation to {recipient}'
}

export const ActivityHistory_BankTransfer = {
  id: 'ActivityHistory.BankTransfer',
  defaultMessage: 'Transfer to bank account'
}

export const AcivityHistory_PendingTransferToPayPal = {
  id: 'ActivityHistory.PendingTransferToPayPal',
  defaultMessage: 'Pending transfer to PayPal'
}

export const ActivityHistory_PendingDonation = {
  id: 'ActivityHistory.PendingDonation',
  defaultMessage: 'Pending donation to {recipient}'
}

export const ActivityHistory_PendingBankTransfer = {
  id: 'ActivityHistory.PendingBankTransfer',
  defaultMessage: 'Pending transfer to bank account'
}

export const ActivityHistory_FailedToCreateDigitalVoucher = {
  id: 'ActivityHistory.FailedToCreateDigitalVoucher',
  defaultMessage: 'Failed to create digital voucher'
}

export const ActivityHistory_AttemptedTransferToPayPal = {
  id: 'ActivityHistory.AttemptedTransferToPayPal',
  defaultMessage: 'Attempted transfer to PayPal'
}

export const ActivityHistory_AttemptedDonation = {
  id: 'ActivityHistory.AttemptedDonation',
  defaultMessage: 'Attempted donation to {recipient}'
}

export const ActivityHistory_AttemptedBankTransfer = {
  id: 'ActivityHistory.AttemptedBankTransfer',
  defaultMessage: 'Attempted transfer to bank account'
}

export const ActivityHistory_CreatedEVoucher = {
  id: 'ActivityHistory.CreatedEVoucher',
  defaultMessage: 'Added as digital voucher'
}

export const ActivityHistory_AddedFromBag = {
  id: 'ActivityHistory.AddedFromBag',
  defaultMessage: 'added from bag'
}

export const ActivityHistory_RecycledFor = {
  id: 'ActivityHistory.RecycledFor',
  defaultMessage: 'Recycled for'
}

export const ActivityHistory_ExpiredVoucher = {
  id: 'ActivityHistory.ExpiredVoucher',
  defaultMessage: 'Voucher has expired.'
}

export const ActivityHistory_RetryFailed = {
  id: 'ActivityHistory.RetryFailed',
  defaultMessage: 'Still failing. Please attempt to resolve the issue by following the instructions above.'
}

export const ActivityHistory_RetryPayment = {
  id: 'ActivityHistory.RetryPayment',
  defaultMessage: 'Retry transfer'
}

export const TextField_InvalidInput = {
  id: 'TextField.InvalidInput',
  defaultMessage: "This doesn't look correct"
}

export const TextField_RequiredField = {
  id: 'TextField.RequiredField',
  defaultMessage: 'This field is required'
}

export const LocationDetails_FailedToFetch = {
  id: 'LocationDetails.FailedToFetch',
  defaultMessage: 'Whoopsie! Failed to fetch location. Please try again later.'
}

export const LocationDetails_MakeFavorite = {
  id: 'LocationDetails.MakeFavorite',
  defaultMessage: 'Favorite'
}

export const LocationDetails_Directions = {
  id: 'LocationDetails.Directions',
  defaultMessage: 'Get directions'
}

export const CPTInfo_Title = {
  id: 'CPTInfo.Title',
  defaultMessage: 'Return point types:'
}

export const LocationSearch_Title = {
  id: 'LocationSearch.Title',
  defaultMessage: 'Search or filter'
}

export const Location_GoToYourLocation = {
  id: 'LocationSearch.GoToYourLocation',
  defaultMessage: 'Go to your location'
}

export const LocationSearch_TextFieldLabel = {
  id: 'LocationSearch.TextFieldLabel',
  defaultMessage: 'Cities, suburbs etc.'
}

export const LocationSearch_SubmitSearchButtonText = {
  id: 'LocationSearch.SubmitSearchButtonText',
  defaultMessage: 'Search'
}

export const LocationSearch_ErrorMessage = {
  id: 'LocationSearch.ErrorMessage',
  defaultMessage: 'Failed to search for locations. Please try again.'
}

export const LocationSearch_NumberOfResults = {
  id: 'LocationSearch.NumberOfResults',
  defaultMessage: '{numberOfResults} results found for "{searchValue}"'
}

export const LocationSearch_NoResultsDisclaimer = {
  id: 'LocationSearch.NoResultsDisclaimer',
  defaultMessage: 'Please doucle-check spelling or try a more generic search term.'
}

export const LocationSearch_NoLocationsInViewport = {
  id: 'LocationSearch.NoLocationsInViewport',
  defaultMessage: 'No reverse vending locations in this area yet.'
}

export const LocationSearch_FilterOnReturnPoint = {
  id: 'LocationSearch.FilterOnReturnPoint',
  defaultMessage: 'Filter on return point:'
}

export const LocationList_Title = {
  id: 'LocationList.Title',
  defaultMessage: 'Selected map area'
}

export const LocationList_Subtitle = {
  id: 'LocationList.Subtitle',
  defaultMessage: 'Showing {numberOfLocations} location(s)'
}

export const LocationList_NoLocations = {
  id: 'LocationList.NoLocations',
  defaultMessage: 'No locations found'
}

export const LocationsPage_MapLoadingFailed = {
  id: 'LocationsPage.MapLoadingFailed',
  defaultMessage: 'Unable to load map at the moment. Please try again later.'
}

export const NewsLetterSetting_Subscribed = {
  id: 'NewsLetterSetting.Subscribed',
  defaultMessage: 'Thanks for signing up!'
}

export const NewsLetterSetting_Unsubscribed = {
  id: 'NewsLetterSetting.Unsubscribed',
  defaultMessage: 'You have unsubscribed from our newsletter.'
}

export const NewsLetterSetting_UpdateFailed = {
  id: 'NewsLetterSetting.UpdateFailed',
  defaultMessage: 'We apologize, we were unable to update your subscription status at this moment.'
}

export const ActivityRow_BagDeliveredAt = {
  id: 'ActivityRow.BagDeliveredAt',
  defaultMessage: 'Bag delivered at {location}'
}

export const ActivityRow_RecycledAt = {
  id: 'ActivityRow.RecycledAt',
  defaultMessage: 'Recycled at {location}'
}

export const ActivityRow_PendingAction = {
  id: 'ActivityRow.PendingAction',
  defaultMessage: 'Pending action'
}

export const ActivityRow_PaidOut = {
  id: 'ActivityRow.PaidOut',
  defaultMessage: 'Paid out'
}

export const ActivityRow_Expired = {
  id: 'ActivityRow.Expired',
  defaultMessage: 'Expired'
}

export const ActivityRow_Pending = {
  id: 'ActivityRow.Pending',
  defaultMessage: 'Pending'
}

export const Settings_Title = {
  id: 'Settings.Title',
  defaultMessage: 'Settings'
}

export const Settings_Faq = {
  id: 'Settings.Faq',
  defaultMessage: 'FAQ'
}

export const Settings_Tos = {
  id: 'Settings.Tos',
  defaultMessage: 'Terms of Service'
}

export const Common_Back = {
  id: 'Common.Back',
  defaultMessage: 'Back'
}

export const Common_Logout = {
  id: 'Common.Logout',
  defaultMessage: 'Log out'
}

export const LanguagePicker_Language = {
  id: 'LanguagePicker.Language',
  defaultMessage: 'Language'
}

export const LanguagePicker_LebaneseArabic = {
  id: 'LanguagePicker.LebaneseArabic',
  defaultMessage: 'Arabic (Lebanese)'
}

export const LanguagePicker_German = {
  id: 'LanguagePicker.German',
  defaultMessage: 'Deutsche'
}

export const LanguagePicker_AustralianEnglish = {
  id: 'LanguagePicker.AustralianEnglish',
  defaultMessage: 'English (AU)'
}

export const LanguagePicker_BritishEnglish = {
  id: 'LanguagePicker.BritishEnglish',
  defaultMessage: 'English (GB)'
}

export const LanguagePicker_AmericanEnglish = {
  id: 'LanguagePicker.AmericanEnglish',
  defaultMessage: 'English (US)'
}

export const LanguagePicker_Spanish = {
  id: 'LanguagePicker.Spanish',
  defaultMessage: 'Español'
}

export const LanguagePicker_CanadianFrench = {
  id: 'LanguagePicker.CanadianFrench',
  defaultMessage: 'Français (CA)'
}

export const LanguagePicker_Portuguese = {
  id: 'LanguagePicker.Portuguese',
  defaultMessage: 'Português'
}

export const LanguagePicker_Chinese = {
  id: 'LanguagePicker.Chinese',
  defaultMessage: '中文'
}

export const LanguagePicker_HongKongChinese = {
  id: 'LanguagePicker.ChineseHK',
  defaultMessage: 'Chinese (HK)'
}

export const LanguagePicker_Vietnamese = {
  id: 'LanguagePicker.Vietnamese',
  defaultMessage: 'Vietnamese'
}

export const WelcomePage_Subtitle = {
  id: 'WelcomePage.Subtitle',
  defaultMessage:
    'Collect your recyclables, return them to your nearest recycling point and collect your digital refunds.'
}

export const WelcomePage_Locations = {
  id: 'WelcomePage.Locations',
  defaultMessage: 'Find closest machine'
}

export const Header_Greeting = {
  id: 'Header.Greeting',
  defaultMessage: 'Hi {name}!'
}

export const Header_ReadyToRecycle = {
  id: 'Header.ReadyToRecycle',
  defaultMessage: 'Ready to recycle?'
}

export const RefundStatisticsSwiper_AvailableBalance = {
  id: 'RefundStatisticsSwiper.AvailableBalance',
  defaultMessage: 'Available balance'
}

export const RefundStatisticsSwiper_TotalPaidOut = {
  id: 'RefundStatisticsSwiper.TotalPaidOut',
  defaultMessage: 'Total paid out'
}

export const RefundStatisticsSwiper_TotalEarnings = {
  id: 'RefundStatisticsSwiper.TotalEarnings',
  defaultMessage: 'Total earnings'
}

export const ActivePayout_Title = {
  id: 'ActivePayout.Title',
  defaultMessage: 'Your active payout'
}

export const ActivePayout_SetupPayout = {
  id: 'ActivePayout.SetupPayout',
  defaultMessage: 'Set up payout'
}

export const ActivitiesPage_NoActivities = {
  id: 'ActivitiesPage.NoActivities',
  defaultMessage: 'As soon as you get recycling, your refund history will appear here.'
}

export const ActivitiesPage_SendTaxReceipts = {
  id: 'ActivitiesPage.SendTaxReceipts',
  defaultMessage: 'Send tax receipts'
}

export const ActivitiesPage_LoadingActivitiesFailed = {
  id: 'ActivitiesPageContainer.LoadingActivitiesFailed',
  defaultMessage: 'We apologize, we are unable to list your refunds at the moment.'
}

export const TaxReceiptDrawer_Title = {
  id: 'TaxReceiptDrawer.Title',
  defaultMessage: 'Send tax deductible donation receipts'
}

export const TaxReceiptDrawer_Description = {
  id: 'TaxReceiptDrawer.Description',
  defaultMessage: 'A pdf with your donation summary will be sent to your email.'
}

export const TaxReceiptDrawer_From = {
  id: 'TaxReceiptDrawer.From',
  defaultMessage: 'From'
}

export const TaxReceiptDrawer_To = {
  id: 'TaxReceiptDrawer.To',
  defaultMessage: 'To'
}

export const TaxReceiptDrawer_Today = {
  id: 'TaxReceiptDrawer.Today',
  defaultMessage: 'Today'
}

export const TaxReceiptDrawer_EmailSent = {
  id: 'TaxReceiptDrawer.EmailSent',
  defaultMessage: 'Email sent'
}

export const TaxReceiptDrawer_EmailNotSent = {
  id: 'TaxReceiptDrawer.EmailNotSent',
  defaultMessage: 'Email was not sent. Please try again later.'
}

export const TaxReceiptDrawer_FromDateMustBeBeforeToDate = {
  id: 'TaxReceiptDrawer.FromDateMustBeBeforeToDate',
  defaultMessage: 'From date must be before to date'
}

export const TaxReceiptDrawer_ToMustBeInPast = {
  id: 'TaxReceiptDrawer.ToMustBeInPast',
  defaultMessage: 'Cannot get receipts from the future'
}

export const TaxReceiptDrawer_NoValidDonations = {
  id: 'TaxReceiptDrawer.NoValidDonations',
  defaultMessage: 'You have no tax deductible donations yet.'
}

export const AchievementDetailsPage_InvalidAchievement = {
  id: 'AchievementDetailsPage.InvalidAchievement',
  defaultMessage: "This achievement doesn't exists"
}

export const AchievementDetailsPage_Levels = {
  id: 'AchievementDetailsPage.Levels',
  defaultMessage: 'Levels'
}

export const VoucherPage_Available = {
  id: 'VoucherPage.Available',
  defaultMessage: 'Available ({numOfVouchers})'
}

export const VoucherPage_Archived = {
  id: 'VoucherPage.Archived',
  defaultMessage: 'Archived ({numOfVouchers})'
}

export const VoucherPage_NoVouchers = {
  id: 'VoucherPage.NoVouchers',
  defaultMessage: 'No vouchers.'
}

export const ContactUs_Description = {
  id: 'ContactUs.Description',
  defaultMessage: 'We are here to help. Please contact us through one of the methods below.'
}

export const ContactUs_NotAvailable = {
  id: 'ContactUs.NotAvailable',
  defaultMessage: "{appName} unfortunately isn't eligible for use in {countryStateName} yet."
}

export const ContactUs_FindUsOnFacebook = {
  id: 'ContactUs.FindUsOnFacebook',
  defaultMessage: 'Find us on Facebook'
}

export const MyAchievements_RecycledContainers = {
  id: 'MyAchievements_RecycledContainers',
  defaultMessage: 'Recycled containers'
}

export const MyAchievements_NotRecycledYet = {
  id: 'MyAchievements_NotRecycledYet',
  defaultMessage: 'As soon as you get recycling, your achievements will appear here'
}

export const Achievements_Name_Description_Unknown = {
  id: 'Achievements.Name.Description.Unknown',
  defaultMessage: 'Unknown achievement'
}

export const Achievement_Done = {
  id: 'Achievement.Done',
  defaultMessage: 'Done!'
}

export const Achievement_NotStarted = {
  id: 'Achievement.NotStarted',
  defaultMessage: 'Not started'
}

export const Achievement_Remaining = {
  id: 'Achievement.Remaining',
  defaultMessage: '{remainingValue} remaining'
}

export const VoucherCard_RedeemAt = {
  id: 'VoucherCard.RedeemAt',
  defaultMessage: 'Redeem at:'
}

export const VoucherCard_AmountDisclaimer = {
  id: 'VoucherCard.AmountDisclaimer',
  defaultMessage: 'Inclusive of GST'
}

export const VoucherCard_Created = {
  id: 'VoucherCard.Created',
  defaultMessage: 'Created: {date}'
}

export const VoucherCard_ValidUntil = {
  id: 'VoucherCard.ValidUntil',
  defaultMessage: 'Valid until: {date}'
}

export const FindLocation_ButtonText = {
  id: 'FindLocation.ButtonText',
  defaultMessage: 'Find recycling location'
}

export const Common_DeleteAccount = {
  id: 'Common.DeleteAccount',
  defaultMessage: 'Delete my account'
}

export const HomePage_Other = {
  id: 'HomePage.Other',
  defaultMessage: 'Other'
}

export const AchievementsPage_YourAchievements = {
  id: 'AchievementsPage.YourAchievements',
  defaultMessage: 'View completed'
}

export const AchievementsPage_AllAchievements = {
  id: 'AchievementsPage.AllAchievements',
  defaultMessage: 'View all'
}

export const CharitiesPage_MyDonations = {
  id: 'CharitiesPage.MyDonations',
  defaultMessage: 'My donations'
}

export const BrowseCharitiesPage_Filter = {
  id: 'BrowseCharitiesPage.Filter',
  defaultMessage: 'Filter'
}

export const BrowseCharitiesPage_ClearFilter = {
  id: 'BrowseCharitiesPage.ClearFilter',
  defaultMessage: 'Clear filter'
}

export const BrowseCharitiesPage_SortBy = {
  id: 'BrowseCharitiesPage.SortBy',
  defaultMessage: 'Sort by'
}

export const BrowseCharitiesPage_ClearSorting = {
  id: 'BrowseCharitiesPage.ClearSorting',
  defaultMessage: 'Clear sorting'
}

export const BrowseCharitiesPage_Latest = {
  id: 'BrowseCharitiesPage.Latest',
  defaultMessage: 'Latest'
}

export const BrowseCharitiesPage_Oldest = {
  id: 'BrowseCharitiesPage.Oldest',
  defaultMessage: 'Oldest'
}

export const BrowseCharitiesPage_Trending = {
  id: 'BrowseCharitiesPage.Trending',
  defaultMessage: 'Trending'
}

export const BrowseCharitiesPage_MostPopular = {
  id: 'BrowseCharitiesPage.MostPopular',
  defaultMessage: 'Most popular'
}

export const BrowseCharitiesPage_Alphabetical = {
  id: 'BrowseCharitiesPage.Alphabetical',
  defaultMessage: 'Alphabetical'
}

export const BrowseCharitiesPage_MostMoneyRaised = {
  id: 'BrowserCharitiesPage.MostMoneyRaised',
  defaultMessage: 'Most money raised'
}

export const CampaignInfo_DaysLeft = {
  id: 'CampaignInfo.DaysLeft',
  defaultMessage: '{daysLeft} {daysLeft, plural, one {day} other {days}} left'
}

export const CampaignInfo_EndingToday = {
  id: 'CampaignInfo.ExpiresToday',
  defaultMessage: 'Expires today'
}

export const CampaignInfo_RaisedSoFar = {
  id: 'CampaignInfo.RaisedSoFar',
  defaultMessage: 'Raised so far'
}

export const CampaignInfo_Goal = {
  id: 'CampaignInfo.Goal',
  defaultMessage: 'Goal'
}

export const Common_CharityCampaign_PayoutDisclaimer = {
  id: 'Common.CharityCampaign.PayoutDisclaimer',
  defaultMessage: 'By setting this payout your future recycling sessions will automatically be donated in full.'
}

export const SetupCampaignPayout_SetAsPayoutConfirm = {
  id: 'SetupCampaignPayout.SetAsPayoutConfirm',
  defaultMessage: 'Would you like to set this as your payout?'
}

export const SetupCampaignPayout_PayoutIsActive = {
  id: 'SetupCampaignPayout.PayoutIsActive',
  defaultMessage:
    'This campaign is set as your payout. Your future recycling sessions will automatically be donated to {charityName} in full.'
}

export const SetupCampaignPayout_SetAsPayout = {
  id: 'SetupCampaignPayout.SetAsPayout',
  defaultMessage: 'Set as payout'
}

export const Common_Cancel = {
  id: 'Common.Cancel',
  defaultMessage: 'Cancel'
}

export const CampaignInfo_GoalMet = {
  id: 'CampaignInfo.GoalMet ',
  defaultMessage: 'The campaign goal is met, but any donation exceeding the goal is welcomed by the charity.'
}

export const CharityInfoDrawer_VisitWebsite = {
  id: 'CharityInfoDrawer.VisitWebsite',
  defaultMessage: 'Visit website'
}

export const MyDonationsPage_NotDonatedYet = {
  id: 'MyDonationsPage.NotDonatedYet ',
  defaultMessage: 'As soon as you get recycling, your donations will appear here.'
}

export const MyDonationsPage_YouHaveDonated = {
  id: 'MyDonationsPage.YouHaveDonated ',
  defaultMessage: 'You have donated'
}

export const MyDonationsPage_PreviousDonations = {
  id: 'MyDonationsPage.PreviousDonations ',
  defaultMessage: 'Previous donations'
}

export const MyDonationsPage_LoadingUserDataFailed = {
  id: 'MyDonationsPage.LoadingUserDataFailed ',
  defaultMessage: 'We apologize, we are unable to list your donations the the moment.'
}

export const MyDonationsPage_YourContribution = {
  id: 'MyDonationsPage.YourContribution',
  defaultMessage: 'Your contribution'
}

export const BrowseCharitiesPage_NoMatch = {
  id: 'BrowseCharitiesPage.NoMatch',
  defaultMessage: 'No campaigns matched your search'
}

export const BrowseCharitiesPage_ShowingCampaigns = {
  id: 'BrowseCharitiesPage.ShowingCampaigns',
  defaultMessage: 'Showing {num} campaigns'
}

export const BrowseCharitiesPage_SearchPlaceholder = {
  id: 'BrowseCharitiesPage.SearchPlaceholder',
  defaultMessage: 'search for charity, campaign...'
}

export const BrowseCharitiesPage_FailedToLoadCampaigns = {
  id: 'BrowseCharitiesPage.FailedToLoadCampaigns',
  defaultMessage: 'Failed to load campaigns'
}

export const BrowseCharitiesPage_Donations = {
  id: 'BrowseCharitiesPage.Donations',
  defaultMessage: '{numberOfDonations} donations'
}

export const Common_Close = {
  id: 'Common.Close',
  defaultMessage: 'Close'
}

export const Common_Done = {
  id: 'Common.Done',
  defaultMessage: 'Done'
}

export const NativeTranslations_RegisterBagdrop_Success = {
  id: 'NativeTranslations.RegisterBagdrop.Success',
  defaultMessage: 'Registered!'
}

export const NativeTranslations_RegisterBagdrop_InvalidBagtag = {
  id: 'NativeTranslations.RegisterBagdrop.InvalidBagtag',
  defaultMessage: 'This tag is invalid.'
}

export const NativeTranslations_RegisterBagdrop_AlreadyRegisteredByUser = {
  id: 'NativeTranslations.RegisterBagdrop.AlreadyRegisteredByUser',
  defaultMessage: "You've already registered this bag."
}

export const NativeTranslations_RegisterBagdrop_AlreadyRegisteredToAnotherUser = {
  id: 'NativeTranslations.RegisterBagdrop.AlreadyRegisteredToAnotherUser',
  defaultMessage: 'This tag has already been scanned by someone else. Please pick up a new tag, and try again.'
}

export const NativeTranslations_RegisterBagdrop_Description = {
  id: 'NativeTranslations.RegisterBagdrop.Description',
  defaultMessage: 'Scan your bag tag to register it.'
}

export const NativeTranslations_RegisterQRCode_Success = {
  id: 'NativeTranslations.RegisterQRCode.Success',
  defaultMessage: 'Logged in!'
}

export const NativeTranslations_RegisterQRCode_InvalidCode = {
  id: 'NativeTranslations.RegisterQRCode.InvalidCode',
  defaultMessage: 'This QR code is invalid.'
}

export const NativeTranslations_RegisterQRCode_LoginFailed = {
  id: 'NativeTranslations.RegisterQRCode.LoginFailed',
  defaultMessage: 'Login failed. Please try again later.'
}

export const NativeTranslations_RegisterQRCode_LoginNotPossible = {
  id: 'NativeTranslations.RegisterQRCode.LoginNotPossible',
  defaultMessage: 'Login not possible. Please try again later.'
}

export const NativeTranslations_RegisterQRCode_Description = {
  id: 'NativeTranslations.RegisterQRCode.Description',
  defaultMessage: 'Scan the QR code on the machine to identify yourself.'
}

export const NativeTranslations_ContainerScanner_Description = {
  id: 'NativeTranslations.ContainerScanner.Description',
  defaultMessage: "Scan a container barcode to check if it's eligible."
}

export const NativeTranslations_ContainerScanner_Success = {
  id: 'NativeTranslations.ContainerScanner.Success',
  defaultMessage: 'Hooray! This container is eligible for a refund.'
}

export const NativeTranslations_ContainerScanner_Failed = {
  id: 'NativeTranslations.ContainerScanner.Failed',
  defaultMessage: 'Sorry! This container isn’t eligible for a refund. Please dispose of it responsibly.'
}

export const ContainerScanner_Title = {
  id: 'ContainerScanner.CheckEligibility',
  defaultMessage: 'Container checker'
}

export const ContainerScannerButton_EnterManually = {
  id: 'ContainerScannerButton.EnterManually',
  defaultMessage: 'Enter barcode manually..'
}

export const BarcodeButton_Description = {
  id: 'BarcodeButton.Description',
  defaultMessage: 'Scan your barcode at the machine'
}

export const CountryStatePicker_Description = {
  id: 'CountryStatePicker.Description',
  defaultMessage:
    'Different rules apply for the various recycling schemes around the globe. Let us know where you are to give you the best recycling experience.'
}

export const CountryStatePicker_Location = {
  id: 'CountryStatePicker.Location',
  defaultMessage: 'Location'
}

export const CountryStatePicker_Title = {
  id: 'CountryStatePicker.Title',
  defaultMessage: 'Select location'
}

export const UserErrors_PayPal_RISK_DECLINE = {
  id: 'UserErrors.PayPal.RISK_DECLINE',
  defaultMessage:
    'Your transaction was declined due to risk concerns. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_ACCOUNT_RESTRICTED = {
  id: 'UserErrors.PayPal.ACCOUNT_RESTRICTED',
  defaultMessage:
    "Your PayPal account has been limited. You'll need to remove these limitations to receive your refund."
}

export const UserErrors_PayPal_CURRENCY_COMPLIANCE = {
  id: 'UserErrors.PayPal.CURRENCY_COMPLIANCE',
  defaultMessage:
    'You have rejected to convert or accept the currency of refunds. Please configure your PayPal account properly.'
}

export const UserErrors_PayPal_NO_PAYOUT = {
  id: 'UserErrors.PayPal.NO_PAYOUT',
  defaultMessage:
    'You have no payout configured for your PayPal account. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_RECEIVER_ACCOUNT_INVALID = {
  id: 'UserErrors.PayPal.RECEIVER_ACCOUNT_INVALID',
  defaultMessage: 'Your account is invalid. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_RECEIVER_ACCOUNT_LOCKED = {
  id: 'UserErrors.PayPal.RECEIVER_ACCOUNT_LOCKED',
  defaultMessage: 'Your account is locked or inactive. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_RECEIVER_COUNTRY_NOT_ALLOWED = {
  id: 'UserErrors.PayPal.RECEIVER_COUNTRY_NOT_ALLOWED',
  defaultMessage:
    'Your country cannot receive refunds through PayPal. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_RECEIVING_LIMIT_EXCEEDED = {
  id: 'UserErrors.PayPal.RECEIVING_LIMIT_EXCEEDED',
  defaultMessage: 'Your account has exceeded the receiving limit. Please log into your PayPal account for more details.'
}

export const UserErrors_PayPal_REVERSED_PAYPAL = {
  id: 'UserErrors.PayPal.REVERSED_PAYPAL',
  defaultMessage: 'Payment has been reversed from PayPal. Resolve any issues with your PayPal account and try again.'
}

export const UserErrors_PayPal_DEFAULT = {
  id: 'UserErrors.PayPal.DEFAULT',
  defaultMessage:
    'We are unable to transfer your refunds at this moment, but we are looking into it. The refunds will be available on your PayPal account within two business days.'
}

export const UserErrors_BankTransfer_INCORRECT_ACCOUNT_NUMBER = {
  id: 'UserErrors.BankTransfer.INCORRECT_ACCOUNT_NUMBER',
  defaultMessage: 'The account number is incorrect. Please check your account number before trying again.'
}

export const UserErrors_BankTransfer_TRANSFER_ISSUE = {
  id: 'UserErrors.BankTransfer.TRANSFER_ISSUE',
  defaultMessage:
    'There was a problem transferring your money. Please check your bank account to resolve any issues, or use a different account.'
}

export const SystemErrors_Voucher_DEFAULT = {
  id: 'SystemErrors.Voucher.DEFAULT',
  defaultMessage:
    'We are unable to create a voucher for your refunds, but we are looking into it. The refunds will be available within two business days.'
}

export const SystemErrors_BankTransfer_DEFAULT = {
  id: 'SystemErrors.BankTransfer.DEFAULT',
  defaultMessage:
    'We are unable to transfer your refunds at this moment, but we are looking into it. The refunds will be available on your bank account within two business days.'
}

export const LocationMarkers_FailedToLoadKml = {
  id: 'LocationMarkers.FailedToLoadKml',
  defaultMessage: 'Whoopsie! We were unable to load recycling locations at this time. Please try again later.'
}

export const Locations_StatusIndicators_TitleGlass = {
  id: 'Locations.StatusIndicators.TitleGlass',
  defaultMessage: 'Glass'
}

export const Locations_StatusIndicators_TitlePlasticAndCans = {
  id: 'Locations.StatusIndicators.TitlePlasticAndCans',
  defaultMessage: 'Plastic & Cans'
}

export const Locations_StatusIndicators_TitleCentre = {
  id: 'Locations.StatusIndicators.TitleCentre',
  defaultMessage: 'Recycling centre'
}

export const Locations_StatusIndicators_TitlePet = {
  id: 'Locations.StatusIndicators.TitlePet',
  defaultMessage: 'PET'
}

export const Locations_StatusIndicators_Open = {
  id: 'Locations.StatusIndicators.Open',
  defaultMessage: 'Open'
}

export const Locations_StatusIndicators_Closed = {
  id: 'Locations.StatusIndicators.Closed',
  defaultMessage: 'Closed'
}

export const Locations_StatusIndicators_ClosedForService = {
  id: 'Locations.StatusIndicators.ClosedForService',
  defaultMessage: 'Temporarily unavailable'
}

export const Locations_StatusIndicators_SemiFull = {
  id: 'Locations.StatusIndicators.SemiFull',
  defaultMessage: 'Almost full'
}

export const App_ConnectionDown = {
  id: 'App.ConnectionDown',
  defaultMessage: 'No internet connection.'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_ITEM_COUNT',
  defaultMessage: 'Recycle {levelRequirement} containers'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT',
  defaultMessage: 'Recycle {levelRequirement} metal containers'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT',
  defaultMessage: 'Recycle {levelRequirement} plastic containers'
}

export const Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_UNIQUE_LOCATION_COUNT',
  defaultMessage: 'Recycle at {levelRequirement} unique locations'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_SESSION_COUNT',
  defaultMessage: 'Recycle {levelRequirement} times'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_BAG_COUNT',
  defaultMessage: 'Recycle {levelRequirement} {levelRequirement, plural, one {bag} other {bags}}'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT',
  defaultMessage: '{levelRequirement} {currency} digitally paid out'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT',
  defaultMessage: '{levelRequirement} {currency} paid out through digital vouchers'
}

export const Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_HIGHEST_ITEM_COUNT',
  defaultMessage: 'Recycle {levelRequirement} containers in one session'
}

export const Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT = {
  id: 'Achievements.Description.ACHIEVEMENT_SESSIONS_WITHOUT_REJECT',
  defaultMessage: '{levelRequirement} recycling sessions with all containers accepted'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_DONATION_COUNT',
  defaultMessage: 'Donated {levelRequirement} {levelRequirement, plural, one {time} other {times}}'
}

export const Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT = {
  id: 'Achievements.Description.ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT',
  defaultMessage: 'Donated {levelRequirement} {levelRequirement, plural, one {bottle} other {bottles}}'
}

export const Common_UpdatePayoutSuccess = {
  id: 'Common.UpdatePayoutSuccess',
  defaultMessage: 'Great job! Your payout setting was successfully updated.'
}

export const Common_Continue = {
  id: 'Common.Continue',
  defaultMessage: 'Continue'
}

export const Common_More = {
  id: 'Common.More',
  defaultMessage: 'More'
}

export const Header_Barcode = {
  id: 'Header.Barcode',
  defaultMessage: 'Barcode'
}

export const Header_QrCode = {
  id: 'Header.QrCode',
  defaultMessage: 'QR code'
}

export const PayoutSettings_AddOrEdit = {
  id: 'PayoutSettings.AddOrEdit',
  defaultMessage: 'Add or edit your payout preferences here.'
}

export const PayoutSettings_PayPal_Description = {
  id: 'PayoutSettings.PayPal.Description',
  defaultMessage: 'Get your refunds paid out via your PayPal account.'
}

export const PayoutSettings_QuickSelect_Title = {
  id: 'PayoutSettings.QuickSelect.Title',
  defaultMessage: 'Switch payout'
}

export const PayoutSettings_QuickSelect_Confirm_Donation_Title = {
  id: 'PayoutSettings.QuickSelect.Confirm.Donation.Title',
  defaultMessage: 'Switch to donation?'
}

export const PayoutSettings_QuickSelect_Confirm_Donation_Description = {
  id: 'PayoutSettings.QuickSelect.Confirm.Donation.Description',
  defaultMessage:
    'Are you sure you want to switch to donation payout? Please note you can change your payout at anytime.'
}

export const PayoutSettings_NoPayoutOptions = {
  id: 'PayoutSettings.NoPayoutOptions',
  defaultMessage: "We currently don't offer any ways to payout your refunds, please check again later."
}

export const PayoutSettings_Voucher_Description = {
  id: 'PayoutSettings.Voucher.Description',
  defaultMessage: 'Get your refunds as a Digital Voucher directly in the app.'
}

export const PayoutSettings_Voucher_Disclaimer = {
  id: 'PayoutSettings.Voucher.Disclaimer',
  defaultMessage: 'Available only at select locations. Refunds will otherwise accumulate as balance.'
}

export const PayoutSettings_Donation_Description = {
  id: 'PayoutSettings.Donation.Description',
  defaultMessage: 'Donate your refunds to a charity campaign of your choosing.'
}

export const PayoutSettings_VoucherDisclaimer = {
  id: 'PayoutSettings.VoucherDisclaimer',
  defaultMessage: 'Note that any unused vouchers will be converted to a {payoutMethod} payout.'
}

export const PayoutSettings_Selected = {
  id: 'PayoutSettings.Selected',
  defaultMessage: 'Selected payout.'
}

export const PayoutSettings_Locked = {
  id: 'PayoutSettings.Locked',
  defaultMessage: 'This payout method has been temporarily locked. Please contact customer support.'
}

export const PayoutSettings_BankTransfer_Description = {
  id: 'PayoutSettings.BankTransfer.Description',
  defaultMessage:
    'Through our partner Zepto Payments, you can safely set up bank transfer of refunds directly to your bank account.'
}

export const PayoutSettings_DeleteBankTransferDescription = {
  id: 'PayoutSettings.DeleteBankTransfer.Description',
  defaultMessage:
    'Your active payout will be changed to Digital Voucher (only available at selected return points). You can edit this in the edit payout tab.'
}

export const PayoutSettings_BankTransfer_Disclaimer_One = {
  id: 'PayoutSettings.BankTransfer.Disclaimer.One',
  defaultMessage:
    'Bank transfers from reverse vending machines take 1-3 business days, and express bag drops may take longer. Unused vouchers will be paid out.'
}

export const PayoutSettings_BankTransfer_Disclaimer_Two = {
  id: 'PayoutSettings.BankTransfer.Disclaimer.Two',
  defaultMessage: 'Continuing means you agree to let TOMRA and Zepto keep your account details for payments.'
}

export const PayoutSettings_BankTransfer_Disclaimer_Three = {
  id: 'PayoutSettings.BankTransfer.Disclaimer.Three ',
  defaultMessage:
    'Choose an account to receive payments. Payments to the wrong account cannot be retrieved. If you are unsure, please check with your bank if your account can receive payments.'
}

export const PayoutSettings_BankTransfer_Confirmation = {
  id: 'PayoutSettings.BankTransfer.Confirmation ',
  defaultMessage:
    'Is this the account you would like to receive payments to? Payments to the wrong account cannot be retrieved.'
}

export const PayoutSettings_BankTransfer_AccountHolder = {
  id: 'PayoutSettings.BankTransfer.AccountHolder',
  defaultMessage: 'Account holder name'
}

export const PayoutSettings_BankTransfer_AccountHolder_Invalid = {
  id: 'PayoutSettings.BankTransfer.AccountHolder.Invalid',
  defaultMessage: 'Account holder name is invalid.'
}

export const PayoutSettings_BankTransfer_BsbNumber = {
  id: 'PayoutSettings.BankTransfer.BsbNumber',
  defaultMessage: 'BSB number (6 digits)'
}

export const PayoutSettings_BankTransfer_BsbNumber_Invalid = {
  id: 'PayoutSettings.BankTransfer.BsbNumber.Invalid',
  defaultMessage: 'BSB number is invalid. Should be 6 digits.'
}

export const PayoutSettings_BankTransfer_BsbNumber_NotFound = {
  id: 'PayoutSettings.BankTransfer.BsbNumber.NotFound',
  defaultMessage: 'We were unable to find your bank. Please check your BSB number and try again.'
}

export const PayoutSettings_BankTransfer_AccountNumber = {
  id: 'PayoutSettings.BankTransfer.AccountNumber',
  defaultMessage: 'Account number (5-9 digits)'
}

export const PayoutSettings_BankTransfer_AccountNumber_Invalid = {
  id: 'PayoutSettings.BankTransfer.AccountNumber.Invalid',
  defaultMessage: 'Account number is invalid. Should be between 5-9 digits.'
}

export const PayoutSettings_DeletePayout = {
  id: 'PayoutSettings.DeletePayout',
  defaultMessage: 'Delete payout setting?'
}

export const Common_Account = {
  id: 'Common.Account',
  defaultMessage: 'Account'
}

export const Common_Institution = {
  id: 'Common.Institution',
  defaultMessage: 'Institution'
}

export const Common_Branch = {
  id: 'Common.Branch',
  defaultMessage: 'Branch'
}

export const Common_Validate = {
  id: 'Common.Validate',
  defaultMessage: 'Validate'
}

export const Common_Yes = {
  id: 'Common.Yes',
  defaultMessage: 'Yes'
}

export const Common_No = {
  id: 'Common.No',
  defaultMessage: 'No'
}

export const Common_DeleteSaved = {
  id: 'Common.DeleteSaved',
  defaultMessage: 'Delete saved account'
}

export const Common_CheckEmail = {
  id: 'Common.CheckEmail',
  defaultMessage: 'Check your email'
}

export const Common_SubscribeToNewsletter = {
  id: 'Common.SubscribeToNewsletter',
  defaultMessage: 'Subscribe to our newsletter'
}

export const VerifyEmail_FirstDescription = {
  id: 'VerifyEmail.FirstDescription',
  defaultMessage: 'A link has been sent to:'
}

export const VerifyEmail_SecondDescription = {
  id: 'VerifyEmail.SecondDescription',
  defaultMessage: 'Click it, and your email will be updated!'
}

export const UpdateEmail_CheckEmailUpdate = {
  id: 'UpdateEmail.CheckEmailUpdate',
  defaultMessage: "Please confirm your new email address in the email we've just sent you."
}

export const DeleteAccount_RemainingRefundsWarning = {
  id: 'DeleteAccount.RemainingRefundsWarning',
  defaultMessage:
    'You have {outstandingBalance} of unredeemed funds. If you choose to delete your account, it will take 30 days before it is permanently deleted.'
}

export const DeleteAccount_ListOne_Title = {
  id: 'DeleteAccount.ListOne.Title',
  defaultMessage: 'Deleting your account WILL:'
}

export const DeleteAccount_ListOne_ItemOne = {
  id: 'DeleteAccount.ListOne.ItemOne',
  defaultMessage: 'Delete your personal information'
}

export const DeleteAccount_ListOne_ItemTwo = {
  id: 'DeleteAccount.ListOne.ItemTwo',
  defaultMessage: 'Delete your achievements progress'
}

export const DeleteAccount_ListOne_ItemThree = {
  id: 'DeleteAccount.ListOne.ItemThree',
  defaultMessage: 'Delete any tax deductible receipts'
}

export const DeleteAccount_ListTwo_Title = {
  id: 'DeleteAccount.ListTwo.Title',
  defaultMessage: 'Deleting your account will NOT:'
}

export const DeleteAccount_ListTwo_ItemOne = {
  id: 'DeleteAccount.ListTwo.ItemOne',
  defaultMessage:
    'Delete your transaction history. We are required by law to keep this data, but we will anonymize any personal information. After anonymizing the data it will no longer be possible to connect your profile again.'
}

export const DeleteAccount_RefundsDisclaimerLabel = {
  id: 'DeleteAccount.RefundsDisclaimerLabel',
  defaultMessage:
    'I understand that by deleting my account I also forfeit any unclaimed refund connected to this account.'
}

export const DeleteAccount_PermanenceDisclaimerLabel = {
  id: 'DeleteAccount.PermanenceDisclaimerLabel',
  defaultMessage: 'I understand deleting my account is permanent and can not be undone.'
}

export const ConfirmDelete_Confirm = {
  id: 'ConfirmDelete.Confirm',
  defaultMessage: 'Are you sure you want to delete your account?'
}

export const ConfirmDelete_ConfirmDelete = {
  id: 'ConfirmDelete.ConfirmDelete',
  defaultMessage: 'Yes, delete'
}

export const ScheduledDeletePage_Warning = {
  id: 'ScheduledDeletePage.Warning',
  defaultMessage: 'This account has been deactivated and will be permanently deleted in {daysUntilDelete} days.'
}

export const ScheduledDeletePage_ContactInfo = {
  id: 'ScheduledDeletePage.ContactInfo',
  defaultMessage:
    'If you want to reactivate this account, please contact support. The account can not be reactivated after it is permanently deleted.'
}

export const BagdropButton_EnterManually = {
  id: 'BagdropButton.EnterManually',
  defaultMessage: 'Enter bag tag manually..'
}

export const InstallAppDrawer_Title = {
  id: 'InstallAppDrawer.Title',
  defaultMessage: 'Install app?'
}

export const InstallAppDrawer_Description = {
  id: 'InstallAppDrawer.Description',
  defaultMessage: '{appName} can be installed on your device as an app. It will only take a couple of seconds.'
}

export const InstallAppDrawer_Success = {
  id: 'InstallAppDrawer.Success',
  defaultMessage: 'Great! Installing...'
}

export const NotificationPromptDrawer_Title = {
  id: 'NotificationPromptDrawer.Title',
  defaultMessage: 'Receive notifications?'
}

export const NotificationPromptDrawer_Description = {
  id: 'NotificationPromptDrawer.Description',
  defaultMessage: 'We can notify you upon payout of refunds et cetera. Would you like to receive notifications?'
}

export const Common_Email = {
  id: 'Common.Email',
  defaultMessage: 'Email'
}

export const Common_Password = {
  id: 'Common.Password',
  defaultMessage: 'Password'
}

export const Common_ChangePassword = {
  id: 'Common.ChangePassword ',
  defaultMessage: 'Change password'
}

export const Camera_Help_Generic = {
  id: 'CameraHelp.Generic',
  defaultMessage: 'Please check camera permissions on your device by opening your browser and navigating to '
}

export const Camera_Help_IOS = {
  id: 'CameraHelp.IOS',
  defaultMessage: 'Please check camera permissions on your device by opening '
}

export const Camera_Help_Android = {
  id: 'CameraHelp.Android',
  defaultMessage: 'Please check camera permissions on your device by opening Chrome and navigating to '
}

export const Camera_Permission_Instructions_Generic = {
  id: 'Camera.Permission.Instructions.Generic',
  defaultMessage: 'Settings -> Site settings -> enable Camera for {appDomain}'
}

export const Camera_Permission_Instructions_Android = {
  id: 'Camera.Permission.Instructions.Android',
  defaultMessage: 'Settings -> Site settings -> All sites -> {appDomain} -> Toggle on Camera permission'
}

export const Camera_Permission_Instructions_IOS = {
  id: 'Camera.Permission.Instructions.IOS',
  defaultMessage: 'Settings -> {appName} -> Turn on Camera'
}
