import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { feedbackMessages } from './feedbackMessages'
import { Button, TextField, Loading, Alert, BackButton } from '../shared'
import { LockIcon, ErrorIcon } from '../icons'
import { FIREBASE, intl, getItemFromWebStorage, removeItemFromWebStorage } from '../../lib'
import { updateUser, updateMarketingsubscription } from '../../services'
import {
  Auth_AlmostThere,
  Auth_ContinueWithPassword,
  Auth_PasswordRequirements,
  Common_Continue,
  Common_Password
} from '../../translations/messages'

const Container = styled.div`
  display: grid;
  grid: auto auto auto auto auto / 1fr;
  align-items: center;
  min-height: 85vh;
`

export const PasswordLoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [passwordValue, setPasswordValue] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const performCredentialSignIn = async evt => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      setFeedbackMessage('')

      const { email: emailState, name: nameState, acceptedNewsletter: acceptedNewsletterState } = location.state || {}
      const email = emailState || (await getItemFromWebStorage('emailForSignIn')) || ''
      const name = nameState || (await getItemFromWebStorage('nameForSignIn')) || ''
      const acceptedNewsletter = acceptedNewsletterState || (await getItemFromWebStorage('newsletterForSignIn')) || ''

      if (!email) {
        navigate('/login/confirm-email' + window.location.search, { replace: true })
        return
      }

      const { user } = await signInWithEmailAndPassword(FIREBASE.auth, location.state?.email, passwordValue)

      if (name) {
        await updateUser({ firstName: name })
          .run()
          .catch(error => logError(new Error('Failed to set name on login'), error))

        // updateUser() above also does this, but FIREBASE.currentUser wont update until next page load, so we force it here
        await updateProfile(user, { displayName: name }).catch(error =>
          logError(new Error('Failed to set name on login'), error)
        )
      }

      if (acceptedNewsletter) {
        updateMarketingsubscription('SUBSCRIBED')
          .run()
          .catch(error => logError(new Error('Failed to set newsletter subscription on login'), error))
      }

      removeItemFromWebStorage('nameForSignIn')
      removeItemFromWebStorage('newsletterForSignIn')

      // Just to clear state from url
      navigate('/', { replace: true })
    } catch (error: any) {
      setRequestStatus('failed')
      setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
      logError(new Error('Failed to log in with credentials'), error)
    }
  }

  if (!location.state?.email) {
    return (
      <>
        <BackButton to="/login" />

        <Container className="animated fadeInRight">
          <ErrorIcon className="centerMargin" size="5rem" />
          <Alert className="bounceIn">{feedbackMessages['missing-email']}</Alert>
        </Container>
      </>
    )
  }

  return (
    <>
      <BackButton to="/login" />

      <Container className="animated fadeInRight">
        <LockIcon className="centerMargin" size="5rem" />

        <h1>{intl.formatMessage(Auth_AlmostThere)}</h1>

        <p>{intl.formatMessage(Auth_ContinueWithPassword)}</p>

        <form onSubmit={performCredentialSignIn}>
          <TextField
            onChange={(value, valid) => {
              setPasswordValue(value)
              setPasswordValid(valid)
            }}
            value={passwordValue}
            valid={passwordValid}
            label={intl.formatMessage(Common_Password)}
            ariaLabel={intl.formatMessage(Common_Password)}
            pattern=".{8,}"
            errorMsg={intl.formatMessage(Auth_PasswordRequirements)}
            type="password"
            autoComplete="current-password"
            required
            ignoreWhitespace
            style={{ marginBottom: 'var(--spacing-lg)' }}
          />

          {feedbackMessage && (
            <Alert
              backgroundColor={requestStatus === 'failed' && 'var(--colors-red)'}
              color={requestStatus === 'failed' && 'white'}
              className="bounceIn"
              style={{ marginBottom: 'var(--spacing-lg)' }}
            >
              {feedbackMessage}
            </Alert>
          )}

          <Button type="submit" disabled={!passwordValue || !passwordValid}>
            {requestStatus === 'loading' ? <Loading className="centerAbsolute" /> : intl.formatMessage(Common_Continue)}
          </Button>
        </form>
      </Container>
    </>
  )
}
