import React from 'react'
import styled from 'styled-components'
import { default as ReactBarcode } from 'react-barcode'
import { Card, Currency } from '../shared'
import { intl } from '../../lib'
import { storeChainLogoMap } from './storeChainLogoMap'
import {
  VoucherCard_AmountDisclaimer,
  VoucherCard_Created,
  VoucherCard_RedeemAt,
  VoucherCard_ValidUntil
} from '../../translations/messages'

const Container = styled.div`
  flex: 0 0 100%;
  :not(:last-child) {
    margin-right: var(--spacing-md);
  }
`

const BarcodeContainer = styled.div`
  rect {
    fill: #7f7f7f;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-lg);
  cursor: pointer;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const WhiteOverlay = styled.div`
  background-color: white;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: var(--radius-sm);
`

const SmallBoldText = styled.small`
  font-weight: bold;
`

const Title = styled.b`
  margin-bottom: var(--spacing-lg);
`

type Props = {
  voucher: VoucherType
}

export const VoucherCard = (props: Props) => {
  const { voucher } = props
  const { redeemed, expired, barcode, storeChain, createdDate, value, currency, expireDate } = voucher

  const storeChainLogo = storeChainLogoMap[storeChain.toLowerCase()] || storeChainLogoMap.default

  return (
    <Container>
      <StyledCard>
        {(redeemed || expired) && <WhiteOverlay />}

        <div>
          <Title>{intl.formatMessage(VoucherCard_RedeemAt)}</Title>
          {storeChainLogo ? <img src={storeChainLogo} alt={storeChain} width="130px" /> : storeChain}
        </div>

        <div>
          <h1>
            <Currency amount={value} currency={currency} />
          </h1>
          <SmallBoldText>{intl.formatMessage(VoucherCard_AmountDisclaimer)}</SmallBoldText>
        </div>

        <BarcodeContainer data-dd-privacy="hidden">
          <ReactBarcode value={barcode} height={70} width={1.2} fontSize={10} />
        </BarcodeContainer>

        <div>
          <SmallBoldText>
            {intl.formatMessage(VoucherCard_Created, { date: intl.formatDate(createdDate) })}
          </SmallBoldText>

          <SmallBoldText>
            {intl.formatMessage(VoucherCard_ValidUntil, { date: intl.formatDate(expireDate) })}
          </SmallBoldText>
        </div>
      </StyledCard>
    </Container>
  )
}
