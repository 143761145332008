import React, { useState } from 'react'
import styled from 'styled-components'
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import { Button, Loading, TextField, Alert } from '../shared'
import { intl, FIREBASE } from '../../lib'
import { pushMessage } from '../AppMessages'
import { feedbackMessages } from '../login/feedbackMessages'
import {
  Common_ChangePassword,
  Common_Update,
  Auth_OldPassword,
  Auth_NewPassword,
  Auth_NewPasswordVerify,
  Auth_PasswordRequirements,
  Auth_PasswordsMustMatch,
  Auth_PasswordWasUpdated
} from '../../translations/messages'

const Container = styled.div``

const Form = styled.form`
  margin: var(--spacing-xl) 0;
  width: 100%;
`

export const ChangePassword = ({ email, closeDrawer }: { email: string; closeDrawer: () => void }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordValid, setNewPasswordValid] = useState(true)
  const [verifyNewPassword, setVerifyNewPassword] = useState('')
  const [verifyNewPasswordValid, setVerifyNewPasswordValid] = useState(true)
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const onSubmit = async (evt: React.SyntheticEvent) => {
    evt.preventDefault()
    setFeedbackMessage('')

    if (newPassword !== verifyNewPassword) {
      setFeedbackMessage(intl.formatMessage(Auth_PasswordsMustMatch))
      return
    }

    try {
      setRequestStatus('loading')
      const { user } = await signInWithEmailAndPassword(FIREBASE.auth, email, oldPassword)
      updatePassword(user, newPassword)
      setRequestStatus('success')
      pushMessage({
        formattedMessage: intl.formatMessage(Auth_PasswordWasUpdated),
        type: 'success',
        ttl: 5000
      })
      closeDrawer()
    } catch (error: any) {
      setRequestStatus('failed')
      setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
    }
  }

  return (
    <Container>
      <h1>{intl.formatMessage(Common_ChangePassword)}</h1>

      <Form onSubmit={onSubmit}>
        <TextField
          onChange={value => setOldPassword(value)}
          value={oldPassword}
          valid
          label={intl.formatMessage(Auth_OldPassword)}
          ariaLabel={intl.formatMessage(Auth_OldPassword)}
          type="password"
          required
        ></TextField>

        <TextField
          onChange={(value, valid) => {
            setNewPassword(value)
            setNewPasswordValid(valid)
          }}
          value={newPassword}
          valid={newPasswordValid}
          label={intl.formatMessage(Auth_NewPassword)}
          errorMsg={intl.formatMessage(Auth_PasswordRequirements)}
          type="password"
          pattern=".{8,}"
          required
          ignoreWhitespace
        />

        <TextField
          onChange={(value, valid) => {
            setVerifyNewPassword(value)
            setVerifyNewPasswordValid(valid)
          }}
          value={verifyNewPassword}
          valid={verifyNewPasswordValid}
          label={intl.formatMessage(Auth_NewPasswordVerify)}
          errorMsg={intl.formatMessage(Auth_PasswordRequirements)}
          type="password"
          pattern=".{8,}"
          required
          ignoreWhitespace
        />

        {feedbackMessage && (
          <Alert
            backgroundColor="var(--colors-red)"
            color="white"
            className="bounceIn"
            style={{ marginTop: 'var(--spacing-lg)' }}
          >
            {feedbackMessage}
          </Alert>
        )}

        <Button
          type="submit"
          disabled={!(oldPassword || newPassword || verifyNewPassword) || requestStatus === 'loading'}
          style={{ marginTop: 'var(--spacing-lg)' }}
        >
          {requestStatus === 'loading' ? (
            <span data-testid="loading">
              <Loading />
            </span>
          ) : (
            intl.formatMessage(Common_Update)
          )}
        </Button>
      </Form>
    </Container>
  )
}
