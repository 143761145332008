import React from 'react'
import { usePayoutMethodMutation } from '../../../hooks'
import { Button, Loading } from '../../shared'
import { intl } from '../../../lib'
import { authenticatePayPalUser } from '../../../services'
import {
  Common_Continue,
  PayoutSettings_PayPal_Description,
  PayoutSettings_VoucherDisclaimer,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
}

export const PayPal = ({ savedConfiguration, active }: Props) => {
  const updateQuery = usePayoutMethodMutation()

  return (
    <>
      <h1>{intl.formatMessage(Common_EditPayout)}</h1>

      <span>{intl.formatMessage(PayoutSettings_PayPal_Description)}</span>

      <span>{intl.formatMessage(PayoutSettings_VoucherDisclaimer, { payoutMethod: 'PayPal' })}</span>

      {savedConfiguration ? (
        <Button
          aria-disabled={updateQuery.isPending}
          disabled={updateQuery.isPending}
          onClick={() => updateQuery.mutate({ type: 'PAYPAL', method: active ? 'DELETE' : 'PUT' })}
          border
        >
          {updateQuery.isPending ? (
            <Loading />
          ) : active ? (
            intl.formatMessage(Common_DeleteSaved)
          ) : (
            <span>
              {intl.formatMessage(Common_UseSavedAccount)}
              <p>
                <small>({savedConfiguration.email})</small>
              </p>
            </span>
          )}
        </Button>
      ) : (
        <Button onClick={authenticatePayPalUser} background="colored">
          {intl.formatMessage(Common_Continue)}
        </Button>
      )}
    </>
  )
}
