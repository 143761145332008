import React from 'react'
import { useLocation } from 'react-router-dom'
import { PayPalBrokerPage } from './PayPalBroker'
import { redirect } from './redirect'

export const PayoutBrokerPage = () => {
  const { pathname } = useLocation()

  return <>{pathname === '/broker/paypal' ? <PayPalBrokerPage /> : redirect({})}</>
}
