import { benefitToTranslationMap, orderToTranslationMap } from './charityTranslationMap'

export const benefitOptions: FilterOptionsType[] = [
  { value: 'ANIMAL_WELFARE', key: benefitToTranslationMap['ANIMAL_WELFARE'].defaultMessage },
  { value: 'ARTS_AND_CULTURE', key: benefitToTranslationMap['ARTS_AND_CULTURE'].defaultMessage },
  { value: 'COMMUNITY_DEVELOPMENT', key: benefitToTranslationMap['COMMUNITY_DEVELOPMENT'].defaultMessage },
  { value: 'DISABILITY_SERVICES', key: benefitToTranslationMap['DISABILITY_SERVICES'].defaultMessage },
  { value: 'DOMESTIC_AND_FAMILY', key: benefitToTranslationMap['DOMESTIC_AND_FAMILY'].defaultMessage },
  { value: 'DROUGHT_AND_BUSHFIRE_RELIEF', key: benefitToTranslationMap['DROUGHT_AND_BUSHFIRE_RELIEF'].defaultMessage },
  { value: 'EDUCATION', key: benefitToTranslationMap['EDUCATION'].defaultMessage },
  { value: 'ENVIRONMENT', key: benefitToTranslationMap['ENVIRONMENT'].defaultMessage },
  { value: 'HEALTH', key: benefitToTranslationMap['HEALTH'].defaultMessage },
  { value: 'HUMAN_AND_CIVIL_RIGHTS', key: benefitToTranslationMap['HUMAN_AND_CIVIL_RIGHTS'].defaultMessage },
  { value: 'HUMAN_SERVICES', key: benefitToTranslationMap['HUMAN_SERVICES'].defaultMessage },
  { value: 'INTERNATIONAL_AID', key: benefitToTranslationMap['INTERNATIONAL_AID'].defaultMessage },
  { value: 'RELIGION', key: benefitToTranslationMap['RELIGION'].defaultMessage },
  { value: 'RESEARCH_AND_PUBLIC_POLICY', key: benefitToTranslationMap['RESEARCH_AND_PUBLIC_POLICY'].defaultMessage },
  { value: 'SPORTS', key: benefitToTranslationMap['SPORTS'].defaultMessage },
  { value: 'VETERANS_SERVICES', key: benefitToTranslationMap['VETERANS_SERVICES'].defaultMessage }
]

export const orderOptions: FilterOptionsType[] = [
  { value: 'LATEST', key: orderToTranslationMap['LATEST'].defaultMessage },
  { value: 'OLDEST', key: orderToTranslationMap['OLDEST'].defaultMessage },
  { value: 'TRENDING', key: orderToTranslationMap['TRENDING'].defaultMessage },
  { value: 'MOST_POPULAR', key: orderToTranslationMap['MOST_POPULAR'].defaultMessage },
  { value: 'ALPHABETICAL', key: orderToTranslationMap['ALPHABETICAL'].defaultMessage },
  { value: 'MOST_MONEY_RAISED', key: orderToTranslationMap['MOST_MONEY_RAISED'].defaultMessage }
]
