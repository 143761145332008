import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ArrowDownIcon } from '../../icons'

const SelectContainer = styled.div`
  position: relative;
  flex: 1;

  &:first-child {
    margin-right: var(--spacing-xs);
  }

  &:last-child {
    margin-left: var(--spacing-xs);
  }

  > svg {
    position: absolute;
    top: 50%;
    right: var(--spacing-md);
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
  }
`

const SelectStyled = styled.select`
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-lg);
  border: 2px solid black;
  text-indent: var(--spacing-sm);
  -moz-appearance: none;
  -webkit-appearance: none;
  background: white;
  font-weight: bold;
  appearance: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: black;

  &:focus {
    outline: none;
  }
`

type Props = {
  label: string
  options: FilterOptionsType[]
  defaultValue: string
  onChange: (value: string) => void
}

export const Filter: FunctionComponent<Props> = ({ label, options, defaultValue, onChange }) => {
  return (
    <SelectContainer>
      <SelectStyled aria-label={label} value={defaultValue} onChange={({ target: { value } }) => onChange(value)}>
        <option value="">{label}</option>
        {options.map((option, i) => (
          <option key={`opt-${i}`} value={option.value}>
            {option.key}
          </option>
        ))}
      </SelectStyled>

      <ArrowDownIcon />
    </SelectContainer>
  )
}
