import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePayoutMethodMutation } from '../../../hooks'
import { Button, Loading } from '../../shared'
import { intl } from '../../../lib'
import {
  Common_BrowseCharities,
  PayoutSettings_Donation_Description,
  Common_EditPayout,
  Common_UsePrevious,
  Common_CharityCampaign_PayoutDisclaimer
} from '../../../translations/messages'

type Props = {
  active: PayoutMethodType['active']
  savedConfiguration: PayoutMethodType['savedConfiguration']
}

export const Donation = ({ active, savedConfiguration }: Props) => {
  const navigate = useNavigate()
  const updateQuery = usePayoutMethodMutation()

  return (
    <>
      <h1>{intl.formatMessage(Common_EditPayout)}</h1>

      <span>{intl.formatMessage(PayoutSettings_Donation_Description)}</span>

      <span>{intl.formatMessage(Common_CharityCampaign_PayoutDisclaimer)}</span>

      {savedConfiguration && !active && (
        <Button
          background="colored"
          aria-disabled={updateQuery.isPending}
          disabled={updateQuery.isPending}
          onClick={() => updateQuery.mutate({ type: 'DONATION', method: 'PUT' })}
        >
          {updateQuery.isPending ? (
            <Loading />
          ) : (
            <span>
              {intl.formatMessage(Common_UsePrevious)}
              <p>
                <small>{`(${savedConfiguration.charityName} - ${savedConfiguration.campaignName})`}</small>
              </p>
            </span>
          )}
        </Button>
      )}

      <Button onClick={() => navigate('/charities')} border>
        {intl.formatMessage(Common_BrowseCharities)}
      </Button>
    </>
  )
}
