import { theme } from '../../../lib'
import {
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT,
  Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT,
  Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT,
  Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT,
  Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT
} from '../../../translations/messages'

export const achievementMap: {
  [key: string]: AchievementLevelType[]
} = {
  ACHIEVEMENT_CUMULATIVE_ITEM_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_ITEM_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_ITEM_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_METAL_ITEMS_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_PLASTIC_ITEMS_COUNT
      }
    }
  ],
  ACHIEVEMENT_UNIQUE_LOCATION_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_UNIQUE_LOCATION_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_UNIQUE_LOCATION_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_SESSION_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_SESSION_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_SESSION_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_BAG_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_BAG_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_BAG_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_PAYOUT_AMOUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DIGITAL_VOUCHER_AMOUNT
      }
    }
  ],
  ACHIEVEMENT_HIGHEST_ITEM_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_HIGHEST_ITEM_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_HIGHEST_ITEM_COUNT
      }
    }
  ],
  ACHIEVEMENT_SESSIONS_WITHOUT_REJECT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-1'],
        description: Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-2'],
        description: Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-3'],
        description: Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_SESSIONS_WITHOUT_REJECT-4'],
        description: Achievements_Description_ACHIEVEMENT_SESSIONS_WITHOUT_REJECT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_DONATION_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_COUNT
      }
    }
  ],
  ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT: [
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-1'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-1'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-2'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-2'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-3'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-3'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT
      }
    },
    {
      badge: theme.images.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-4'],
      messages: {
        title: theme.achievements['ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT-4'],
        description: Achievements_Description_ACHIEVEMENT_CUMULATIVE_DONATION_ITEM_COUNT
      }
    }
  ]
}
