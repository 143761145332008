import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { AppVersion } from './AppVersion'
import { openBrowserWindow } from '../../../services'
import { theme, intl, removeItemFromWebStorage, FIREBASE } from '../../../lib'
import { ListButton, ListItem, Loading } from '../../shared'
import {
  EditIcon,
  PiggyBankIcon,
  HelpIcon,
  InfoIcon,
  LockIcon,
  EmailIcon,
  LogoutIcon,
  ArrowRightIcon
} from '../../icons'
import {
  Common_ContactUs,
  Common_EditProfile,
  Common_PrivacyPolicy,
  Common_EditPayout,
  Settings_Faq,
  Common_Logout,
  Settings_Title,
  Settings_Tos
} from '../../../translations/messages'

const Container = styled.div`
  height: 100%;
  padding: var(--spacing-md);

  > *:not(:last-child) {
    margin: calc(var(--spacing-lg) * 2) 0;
  }
`

export const Settings = () => {
  const navigate = useNavigate()
  const [loadingLogout, setLoadingLogout] = useState(false)

  const _logout = async () => {
    setLoadingLogout(true)
    await removeItemFromWebStorage('lastAuthTimestamp')
    await FIREBASE.auth.signOut()
    navigate('/')
  }

  const openDocLink = (docName: 'termsOfService' | 'privacyPolicy' | 'faq') => {
    openBrowserWindow(theme[docName])
  }

  return (
    <Container>
      <h1>{intl.formatMessage(Settings_Title)}</h1>

      <Link to="/account">
        <ListItem borderBottom>
          <div>
            <EditIcon />
            <span>{intl.formatMessage(Common_EditProfile)}</span>
          </div>
          <ArrowRightIcon />
        </ListItem>
      </Link>

      <div>
        <Link to="/payoutsettings">
          <ListItem>
            <div>
              <PiggyBankIcon />
              <span>{intl.formatMessage(Common_EditPayout)}</span>
            </div>
            <ArrowRightIcon />
          </ListItem>
        </Link>

        <ListButton onClick={() => openDocLink('faq')}>
          <div>
            <HelpIcon />
            <span>{intl.formatMessage(Settings_Faq)}</span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <ListButton onClick={() => openDocLink('termsOfService')}>
          <div>
            <InfoIcon />
            <span>{intl.formatMessage(Settings_Tos)}</span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <ListButton onClick={() => openDocLink('privacyPolicy')}>
          <div>
            <LockIcon />
            <span>{intl.formatMessage(Common_PrivacyPolicy)}</span>
          </div>
          <ArrowRightIcon />
        </ListButton>

        <Link to="/contact">
          <ListItem borderBottom>
            <div>
              <EmailIcon />
              <span>{intl.formatMessage(Common_ContactUs)}</span>
            </div>
            <ArrowRightIcon />
          </ListItem>
        </Link>
      </div>

      <ListButton onClick={_logout} borderBottom>
        {loadingLogout ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div>
              <LogoutIcon />

              <span>{intl.formatMessage(Common_Logout)}</span>
            </div>
            <ArrowRightIcon />
          </React.Fragment>
        )}
      </ListButton>

      <AppVersion />
    </Container>
  )
}
