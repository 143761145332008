import React from 'react'
import styled from 'styled-components'
import { PayoutOptionCard } from './PayoutOptionCard'
import { usePayoutMethodsQuery } from '../../hooks'
import { BackButton, FeedbackMessage, Loading } from '../shared'
import { intl } from '../../lib'
import {
  PayoutSettings_NoPayoutOptions,
  Common_EditPayout,
  PayoutSettings_AddOrEdit
} from '../../translations/messages'

const Container = styled.div`
  padding: var(--spacing-md);
  max-width: var(--max-page-width);
  margin: 0 auto;

  > button {
    margin-top: var(--spacing-md);
  }
`

const Header = styled.div`
  margin: 3rem 0 var(--spacing-md) var(--spacing-md);

  > h1 {
    margin-bottom: var(--spacing-lg);
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-bottom: var(--spacing-md);
  }
`

export const PayoutSettingsPage = () => {
  const { data } = usePayoutMethodsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  return (
    <Container className="fadeIn">
      <BackButton />

      {data.length === 0 ? (
        <FeedbackMessage className="centerAbsolute fadeIn">
          {intl.formatMessage(PayoutSettings_NoPayoutOptions)}
        </FeedbackMessage>
      ) : (
        <>
          <Header>
            <h1>{intl.formatMessage(Common_EditPayout)}</h1>

            <b>{intl.formatMessage(PayoutSettings_AddOrEdit)}</b>
          </Header>

          <InnerContainer>
            {data.map((payoutMethod, index) => (
              <PayoutOptionCard {...payoutMethod} key={index} />
            ))}
          </InnerContainer>
        </>
      )}
    </Container>
  )
}
