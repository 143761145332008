import { logError } from '@tomra/datadog-browser-logging'

let _previousLastModifiedOrEtag: string | null = null
let _isCheckingForUpdate = false

const _requestAndCompareIndexHtml = () => {
  // Don't want to reload if user is in the middle of somthing (i.e has query params)
  if (_isCheckingForUpdate || new URLSearchParams(location.search).size > 0) return

  _isCheckingForUpdate = true

  fetch('/index.html', { cache: 'no-store' })
    .then(
      response => {
        const lastModifiedOrEtag = response.headers.get('last-modified') || response.headers.get('etag')

        if (!lastModifiedOrEtag) {
          logError(new Error(`Unable to check for app updates, lastModifiedOrEtag is ${lastModifiedOrEtag}`))
        } else if (_previousLastModifiedOrEtag === null) {
          _previousLastModifiedOrEtag = lastModifiedOrEtag
        } else if (_previousLastModifiedOrEtag !== lastModifiedOrEtag) {
          window.location.reload()
        }
      },
      () => {
        // don't care
      }
    )
    .finally(() => {
      _isCheckingForUpdate = false
    })
}

export const listenForAppUpdates = () => {
  window.addEventListener('visibilitychange', _requestAndCompareIndexHtml)
  document.addEventListener('visibilitychange', _requestAndCompareIndexHtml)
  window.addEventListener('pageshow', _requestAndCompareIndexHtml)
  document.addEventListener('pageshow', _requestAndCompareIndexHtml)
  window.addEventListener('focus', _requestAndCompareIndexHtml)
  document.addEventListener('focus', _requestAndCompareIndexHtml)
}

export const stopListenForAppUpdates = () => {
  window.removeEventListener('visibilitychange', _requestAndCompareIndexHtml)
  document.removeEventListener('visibilitychange', _requestAndCompareIndexHtml)
  window.removeEventListener('pageshow', _requestAndCompareIndexHtml)
  document.removeEventListener('pageshow', _requestAndCompareIndexHtml)
  window.removeEventListener('focus', _requestAndCompareIndexHtml)
  document.removeEventListener('focus', _requestAndCompareIndexHtml)
}
