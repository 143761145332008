import React from 'react'
import styled from 'styled-components'
import { openBrowserWindow } from '../../services'
import { intl, theme } from '../../lib'
import { Checkbox } from '../shared'
import { Auth_ReadAndAgreeToThe, Common_TermsAndConditions, Common_PrivacyPolicy } from '../../translations/messages'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: var(--spacing-md);

  > label {
    margin-left: var(--spacing-md);
  }
`

const DocLink = styled.span`
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  vertical-align: baseline;
`

type Props = {
  checked: boolean
  onChange: () => void
}

export const TermsCheckbox = ({ checked, onChange }: Props) => {
  return (
    <Container>
      <Checkbox id="terms" aria-label="Accept terms" checked={checked} onChange={onChange} />

      <label htmlFor="terms">
        {`${intl.formatMessage(Auth_ReadAndAgreeToThe)} `}
        <DocLink onClick={() => openBrowserWindow(theme.privacyPolicy)}>
          {intl.formatMessage(Common_PrivacyPolicy)}
        </DocLink>
        {` & `}
        <DocLink onClick={() => openBrowserWindow(theme.termsOfService)}>
          {intl.formatMessage(Common_TermsAndConditions)}
        </DocLink>
      </label>
    </Container>
  )
}
