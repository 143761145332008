import React from 'react'
import styled from 'styled-components'
import { APP_VERSION, theme } from '../../../lib'
import { getNativeAppVersion } from '../../../services'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--colors-grey);
  margin-top: var(--spacing-lg);

  > img {
    height: 100%;
    max-height: 1rem;
    width: auto;
    margin-top: var(--spacing-xl);
  }
`

export const AppVersion = () => {
  const nativeAppVersion = getNativeAppVersion()
  const completeAppVersion = nativeAppVersion ? `v/${nativeAppVersion} (${APP_VERSION})` : `v/${APP_VERSION}`
  const isTest = !!window.location.host.match(/(test)|(:\d{4})/gi)

  return (
    <Container>
      <img src={theme.images.poweredByTomraLogo} alt="Powered by TOMRA" />
      <small>
        {completeAppVersion}
        {isTest && ' (test)'}
      </small>
    </Container>
  )
}
