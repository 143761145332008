import React from 'react'
import styled from 'styled-components'
import { Outlet, useLocation, Link } from 'react-router-dom'
import { Tab, TabContainer, BackButton } from '../shared'
import { intl } from '../../lib'
import {
  AchievementsPage_AllAchievements,
  Common_Achievements,
  AchievementsPage_YourAchievements
} from '../../translations/messages'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--colors-secondary);
`

const Header = styled.div`
  max-width: var(--max-page-width);
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-lg);

  > h1 {
    margin-top: 45px;
    margin-bottom: var(--spacing-lg);
  }

  > div {
    justify-content: space-around;
  }
`

export const AchievementsPage = () => {
  const { pathname } = useLocation()

  return (
    <>
      <BackButton to={pathname.match(/achievements\/.{4,}/gi) ? '.' : '..'} />

      <HeaderContainer>
        <Header className="fadeIn">
          <h1>{intl.formatMessage(Common_Achievements)}</h1>

          <TabContainer>
            <Link to=".">
              <Tab active={pathname === '/achievements'}>{intl.formatMessage(AchievementsPage_YourAchievements)}</Tab>
            </Link>
            <Link to="all">
              <Tab active={pathname === '/achievements/all'}>
                {intl.formatMessage(AchievementsPage_AllAchievements)}
              </Tab>
            </Link>
          </TabContainer>
        </Header>
      </HeaderContainer>

      <Outlet />
    </>
  )
}
