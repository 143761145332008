import React from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import * as mytomraService from '../../services/mytomra-service'
import { intl } from '../../lib'
import { Button, Currency, Loading } from '../shared'
import payoutErrorMessagesMap from './payoutErrorMessagesMap'
import {
  AcivityHistory_PendingTransferToPayPal,
  ActivityHistory_AddedFromBag,
  ActivityHistory_AttemptedBankTransfer,
  ActivityHistory_AttemptedDonation,
  ActivityHistory_AttemptedTransferToPayPal,
  Common_BagRegistered,
  ActivityHistory_BankTransfer,
  ActivityHistory_CreatedEVoucher,
  ActivityHistory_DigitalVoucherRedeemed,
  ActivityHistory_DonateToCharityCampaign,
  ActivityHistory_ExpiredVoucher,
  ActivityHistory_FailedToCreateDigitalVoucher,
  ActivityHistory_PendingBankTransfer,
  ActivityHistory_PendingDonation,
  ActivityHistory_RecycledFor,
  ActivityHistory_RetryFailed,
  ActivityHistory_RetryPayment,
  ActivityHistory_TransferToPayPal
} from '../../translations/messages'

type ContainerProps = {
  bagtag: string
  shouldBeYellow: boolean
  containerPaddingTop: string
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => props.containerPaddingTop} var(--spacing-md) var(--spacing-md) var(--spacing-md);
  background-color: ${props => (props.shouldBeYellow ? 'var(--colors-yellow)' : 'white')};
  box-shadow: var(--shadow-sm);
  bottom: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  :after {
    display: ${(props: ContainerProps) => (props.bagtag ? 'initial' : 'none')};
    content: 'Bagtag: ${(props: ContainerProps) => props.bagtag}';
    position: absolute;
    color: var(--colors-black);
    top: 45px;
    left: var(--spacing-md);
    font-size: var(--font-size-sm);
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: var(--spacing-lg);

  :not(:first-child):before {
    content: '';
    position: absolute;
    width: 1px;
    height: var(--spacing-md);
    bottom: 130%;
    left: 7px;
    border-left: 1px dashed;
  }
`

const RecyclingMessageContainer = styled.span`
  > span {
    margin-right: var(--spacing-xs);
  }
`

const Date = styled.div`
  margin-right: var(--spacing-md);
  white-space: nowrap;

  > *:first-child {
    margin-right: 3px;
  }
`

const PayoutErrorContainer = styled.div`
  > button {
    margin-top: var(--spacing-md);
  }
`

PayoutErrorContainer.displayName = 'PayoutErrorContainer'

const ErrorMessage = styled.b`
  display: block;
  margin-top: var(--spacing-md);
  color: var(--colors-red);
`

ErrorMessage.displayName = 'ErrorMessage'

const payoutMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_DigitalVoucherRedeemed)
    case 'PAYPAL':
      return intl.formatMessage(ActivityHistory_TransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_DonateToCharityCampaign, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_BankTransfer)
  }
}

const pendingPayoutMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_CreatedEVoucher)
    case 'PAYPAL':
      return intl.formatMessage(AcivityHistory_PendingTransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_PendingDonation, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_PendingBankTransfer)
  }
}

const payoutWithErrorMessageMap = (paymentMethod: PayoutMethodType['type'], recipient?: string) => {
  switch (paymentMethod) {
    case 'E_VOUCHER':
      return intl.formatMessage(ActivityHistory_FailedToCreateDigitalVoucher)
    case 'PAYPAL':
      return intl.formatMessage(ActivityHistory_AttemptedTransferToPayPal)
    case 'DONATION':
      return intl.formatMessage(ActivityHistory_AttemptedDonation, {
        recipient: recipient ? recipient : 'charity campaign'
      })
    case 'BANK_TRANSFER':
      return intl.formatMessage(ActivityHistory_AttemptedBankTransfer)
  }
}

function renderActivityDescription(activityHistory: RefundHistoryType, status: RefundType['status']) {
  const { recyclingMethod, activityType, details, amount, currency } = activityHistory

  if (activityType === 'BAG_SCANNED') {
    return intl.formatMessage(Common_BagRegistered)
  }

  if (activityType === 'PAYOUT' && details) {
    switch (status) {
      case 'SUCCESSFUL':
        return payoutMessageMap(details.paymentMethod, details.recipient)
      case 'PENDING':
        return pendingPayoutMessageMap(details.paymentMethod, details.recipient)
      case 'EXPIRED':
        return intl.formatMessage(ActivityHistory_ExpiredVoucher)
      default:
        return payoutWithErrorMessageMap(details.paymentMethod, details.recipient)
    }
  }

  if (activityType === 'RECYCLING' && recyclingMethod === 'BAG') {
    return (
      <React.Fragment>
        <Currency amount={amount} currency={currency} />

        {intl.formatMessage(ActivityHistory_AddedFromBag)}
      </React.Fragment>
    )
  }

  if (activityType === 'RECYCLING' && recyclingMethod === 'RVM') {
    return (
      <React.Fragment>
        <span>{intl.formatMessage(ActivityHistory_RecycledFor)}</span>

        <Currency amount={amount || 0} currency={currency || 'AUD'} />
      </React.Fragment>
    )
  }
}

function getPayoutErrorMessage(activities: RefundHistoryType[]) {
  // @ts-ignore
  const { details } = activities.find(({ details }) => !!details && details.errorCategory) || {}

  if (details) {
    const { paymentMethod, errorCategory } = details

    return intl.formatMessage(
      payoutErrorMessagesMap[paymentMethod][errorCategory] || payoutErrorMessagesMap[paymentMethod].DEFAULT
    )
  }
}

type Props = {
  bagtag: string
  activities: RefundHistoryType[]
  status: RefundType['status']
  shouldBeYellow: boolean
}

type State = {
  requestStatus: RequestStatusType
}

export class ActivityHistory extends React.Component<Props, State> {
  _abortRetryPayment = () => {}

  state: State = {
    requestStatus: 'initial'
  }

  _retryPayment = async () => {
    try {
      this.setState({ requestStatus: 'loading' })
      const { run, abort } = mytomraService.retryPayment()
      this._abortRetryPayment = abort
      await run()
      window.location.reload()
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        this.setState({ requestStatus: 'failed' })
        logError(new Error('Failed to retry payout'), error)
      }
    }
  }

  componentWillUnmount() {
    this._abortRetryPayment()
  }

  render() {
    const { bagtag, activities, status, shouldBeYellow } = this.props
    const { requestStatus } = this.state
    const errorMessage = getPayoutErrorMessage(activities)
    const containerPaddingTop =
      !!bagtag && !!errorMessage ? '80px' : !!bagtag || !!errorMessage ? '50px' : 'var(--spacing-lg)'

    return (
      <Container bagtag={bagtag} shouldBeYellow={shouldBeYellow} containerPaddingTop={containerPaddingTop}>
        {errorMessage && (
          <PayoutErrorContainer>
            {errorMessage}

            {requestStatus === 'failed' && (
              <ErrorMessage>
                <span>{intl.formatMessage(ActivityHistory_RetryFailed)}</span>
              </ErrorMessage>
            )}

            {status === 'USER_ERROR' && (
              <Button onClick={this._retryPayment}>
                {requestStatus === 'loading' ? <Loading /> : intl.formatMessage(ActivityHistory_RetryPayment)}
              </Button>
            )}
          </PayoutErrorContainer>
        )}

        {activities.map((activity: RefundHistoryType) => (
          <Row key={activity.id + activity.activityType}>
            <Date>
              <span>{intl.formatDate(activity.timestamp, { month: 'short', day: '2-digit' })}</span>
              <span>{intl.formatTime(activity.timestamp)}</span>
            </Date>

            <RecyclingMessageContainer>{renderActivityDescription(activity, status)}</RecyclingMessageContainer>
          </Row>
        ))}
      </Container>
    )
  }
}

export default ActivityHistory
