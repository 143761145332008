import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const EarthIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <circle cx="12" cy="12" r="11.25" />
    <path d="M7.079,15.75a1.5,1.5,0,0,0,1.455-1.864l-.75-3A1.5,1.5,0,0,0,6.329,9.75H.976A11.246,11.246,0,0,0,4.992,20.792L6,15.75Z" />
    <path d="M20.985,5.25H16.921a1.5,1.5,0,0,0-1.455,1.136l-.75,3a1.5,1.5,0,0,0,1.455,1.864H17.75L18.541,16a1.5,1.5,0,0,0,1.48,1.253h1.925a11.2,11.2,0,0,0-.961-12Z" />
  </svg>
)
