import React from 'react'

type Props = {
  color?: string
  size?: string
}

export const BarcodeScannerIcon = (props: Props) => (
  <svg
    height={props.size || '1.6rem'}
    viewBox="0 0 24 24"
    width={props.size || '1.6rem'}
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: props.color || 'black',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5px'
    }}
  >
    <line x1="3.75" y1="5.25" x2="3.75" y2="12.75" />
    <line x1="6.75" y1="5.25" x2="6.75" y2="9.75" />
    <line x1="12.75" y1="5.25" x2="12.75" y2="8.25" />
    <line x1="15.75" y1="5.25" x2="15.75" y2="7.5" />
    <line x1="18.75" y1="5.25" x2="18.75" y2="8.25" />
    <line x1="9.75" y1="5.25" x2="9.75" y2="9.75" />
    <line x1="6.75" y1="12.75" x2="8.25" y2="12.75" />
    <path d="M3.75.75H2.287A1.537,1.537,0,0,0,.75,2.287V3.75" />
    <path d="M21.75,3.75V2.287A1.537,1.537,0,0,0,20.213.75H18.75" />
    <path d="M.75,14.25v1.463A1.537,1.537,0,0,0,2.287,17.25H3.75" />
    <circle cx="15.75" cy="15.75" r="5.25" />
    <line x1="23.25" y1="23.25" x2="19.462" y2="19.462" />
  </svg>
)
